import React from "react";

import styles from "./TermsAndConditions.module.scss";

const TermsAndConditions = ({ ...rest }) => {
  return (
    <span {...rest} className={styles.text}>
      I agree to the{" "}
      <a href="/terms-and-conditions" target="_blank" className={styles.link}>
        Terms &amp; Conditions
      </a>{" "}
      and{" "}
      <a href="/privacy-policy" target="_blank" className={styles.link}>
        Privacy Policy
      </a>
    </span>
  );
};
export default TermsAndConditions;
