import React, { memo } from "react";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document } from "@contentful/rich-text-types";

import ConditionalWrapper from "@app/components/atoms/ConditionalWrapper/ConditionalWrapper";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";

import {
  Heading,
  Paragraph,
  Subtitle,
} from "../../atoms/Typography/Typography";
import styles from "./RichText.module.scss";
import {ThemeEnum} from '@app/constants/theme.constants';
import Section from '@app/components/layouts/Section/Section';
import {getTheme} from '@app/helpers/util.helpers';
import cx from 'classnames';

export interface RichTextProps {
  align?: string;
  theme?: ThemeEnum;
  text: Document;
  classname?: string;
  wrapContent?: boolean;
}

const RichText = memo(
  ({ text, theme = ThemeEnum.KHAKI, align='left', classname, wrapContent = true }: RichTextProps) => {
    const options: Options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <Paragraph level={2} className={classname}>
            {children}
          </Paragraph>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
          <Heading level={1} className={classname}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading level={2} className={classname}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Heading level={3} className={classname}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
          <Subtitle level={1} className={classname}>
            {children}
          </Subtitle>
        ),
      },
    };

    let alignClass: string;
    if (align === 'left') {
      alignClass = 'text-align-left';
    } else if (align === 'right') {
      alignClass = 'text-align-right';
    } else {
      alignClass = 'text-align-center';
    }

    return (
      <div className={cx(styles.container, getTheme(theme), alignClass)}>
        <ConditionalWrapper
          condition={wrapContent}
          wrapper={children => <ContentWrapper>{children}</ContentWrapper>}
        >
          {documentToReactComponents(text, options)}
        </ConditionalWrapper>
      </div>
    );
  }
);

export default RichText;
