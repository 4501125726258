import React, { useEffect } from "react";

import { addMinutes, isAfter } from "date-fns";
import { FiTrash } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { LineItem } from "@app/features/basket/Types/backend-basket.types";
import { removeFromCart } from "@app/features/basket/redux/basket.slice";
import { currencyFormat } from "@app/helpers/currencyFormat";
import { formatDate } from "@app/helpers/formatDate";

import styles from "./BasketCard.module.scss";
import Image from "./Image";

interface BasketCardProps {
  card: LineItem;
}

const BasketCard: React.FC<BasketCardProps> = ({ card }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isAfter(
        addMinutes(new Date(), 5),
        new Date(card?.payload?.netzp_event?.from)
      )
    ) {
      dispatch(removeFromCart(card));
    }
  }, [dispatch, card]);

  const handleDeleteItem = () => {
    dispatch(removeFromCart(card));
  };

  const getCoverImage = () => {
    if (card.type.includes("workshop")) {
      return <Image path={card.cover?.url} />;
    }
    return <Image path={card.cover.thumbnails[1]?.url} />;
  };

  const getDateInfo = () => {
    if (card.type.includes("workshop")) {
      const children = card.children.map(item => item?.payload?.netzp_event?.from).filter(item => !!(item));
      const childDate = children.sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
      })[0]

      const date = formatDate(
        childDate,
        "EEEE d LLL 'at' h:mmaaa"
      );
      return `First session: ${date}`;
    }
    const date = formatDate(
      card?.payload?.netzp_event?.from,
      "EEEE d LLL 'at' h:mmaaa"
    );
    const duration = `(${card.payload.duration} min)`;

    return `${date} ${duration}`;
  };

  return (
    <div className={styles.card}>
      <div className={styles.image}>{getCoverImage()}</div>
      <div className={styles.info}>
        <p className={styles.title}>{card.label}</p>
        {card?.payload?.manufacturer?.firstName && (
          <p className={styles.author}>
            With {card?.payload?.manufacturer?.firstName}{" "}
            {card?.payload?.manufacturer?.lastName}
          </p>
        )}
        <p className={styles.duration}>{getDateInfo()}</p>
      </div>
      <div className={styles.controls}>
        <div className={styles.price}>
          {currencyFormat(card.price.totalPrice)}
        </div>
        <div className={styles.remove} onClick={handleDeleteItem}>
          <FiTrash />
        </div>
      </div>
    </div>
  );
};

export default BasketCard;
