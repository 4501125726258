import React, { ComponentType } from "react";

import { FieldProps } from "formik";

import styles from "./TextError.module.scss";

interface TextErrorProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: ComponentType<FieldProps> | string;
}

const TextError: React.FC<TextErrorProps> = ({ children }) => {
  return <div className={styles.error}>{children}</div>;
};

export default TextError;
