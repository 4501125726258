import React from "react";

import { useMount } from "react-use";

import Loading from "@app/components/atoms/Loading/Loading";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import TestimonialsCarousel from "@app/features/workshops/components/TestimonialsCarousel";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import { WorkshopTestimonialsInterface } from "@app/features/workshops/types/testimonials.interface";
import { useRequest } from "@app/hooks/useRequest";

interface WorkshopTestimonials {
  sessionId: string;
}

const WorkshopTestimonials: React.FC<WorkshopTestimonials> = ({
  sessionId,
}) => {
  const { data, loading, fetchData } = useRequest(
    `${WorkshopEndpoints.SUMMARY}/${sessionId}/testimonials`
  );
  const testimonialsData: WorkshopTestimonialsInterface[] = data as never;
  useMount(() => {
    fetchData();
  });

  return (
    <Section theme={ThemeEnum.KHAKI}>
      <Loading isLoading={loading}>
        <TestimonialsCarousel
          cards={testimonialsData}
          title="Here's what our community has to say"
          subtitle="Testimonials"
        />
      </Loading>
    </Section>
  );
};

export default React.memo(WorkshopTestimonials);
