import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import { AuthPathsEnum } from "@app/features/auth/auth";

import styles from "./NavigationButtons.module.scss";

const NavigationButtons = memo(() => {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        className={styles.signUpButton}
        to={AuthPathsEnum.SIGN_IN}
        label={t("signIn.title")}
        buttonType={ButtonTypeEnum.PRIMARY}
        size={ButtonSizeEnum.SMALL}
      />
    </div>
  );
});

export default NavigationButtons;
