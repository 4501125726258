import React from "react";
import { Field } from "formik";
import "./radio.scss";

interface FormRadioProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  id: string;
  name: string;
  label: string;
  value: string;
}

const FormRadio: React.FC<FormRadioProps> = ({ value, id, name, label }) => {
  return (
    <div className="radio">
      <Field id={id} type="radio" name={name} value={value} />
      <label htmlFor={id} className="radio-label">
        {label}
      </label>
    </div>
  );
};

export default FormRadio;
