import React, { memo } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import { ThemeEnum } from "@app/constants/theme.constants";
import { AuthPathsEnum } from "@app/features/auth/auth";
import { getTheme } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";

import NavigationItems, {
  NavigationItemsProps,
} from "../../../NavigationItems/NavigationItems";
import NavigationProfile from "../../../NavigationProfile/NavigationProfile";
import styles from "./MobileMenu.module.scss";

interface MobileMenuProps extends NavigationItemsProps {
  isVisible: boolean;
  toggleMenu: () => void;
}

const MobileMenu = memo(
  ({ navItems, isVisible, toggleMenu }: MobileMenuProps) => {
    const { t } = useTranslation();
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    const onBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement;
      if (element.getAttribute("role") === "presentation") {
        toggleMenu();
      }
    };

    return (
      <div
        role="presentation"
        className={cx(styles.wrapper, getTheme(ThemeEnum.KHAKI), {
          [styles.showWrapper]: isVisible,
        })}
        onClick={onBackdropClick}
      >
        <div className={cx(styles.menu, { [styles.menuOpened]: isVisible })}>
          <div className={styles.container}>
            <NavigationItems navItems={navItems} />
          </div>

          {isAuthenticated ? (
            <>
              <div className={styles.divider} />
              <div className={styles.container}>
                <NavigationProfile />
              </div>
            </>
          ) : (
            <div className={styles.buttons}>
              <Button
                to={AuthPathsEnum.SIGN_IN}
                label={t("signIn.title")}
                buttonType={ButtonTypeEnum.PRIMARY}
                size={ButtonSizeEnum.MEDIUM}
                fullWidth
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default MobileMenu;
