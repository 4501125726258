import React from "react";
import styles from "./CustomerSupport.module.scss";

const CustomerSupport = () => {
  return (
    <p className={styles.contact}>
      If you have questions, please contact support on{" "}
      <a href="tel:support@kaylolife.com" className={styles.link}>
        +44 330 808 6792
      </a>{" "}
      or email{" "}
      <a href="mailto:+443308086792" className={styles.link}>
        support@kaylolife.com
      </a>
    </p>
  );
};

export default CustomerSupport;
