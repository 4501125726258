import React, { useState } from "react";
import styles from "./PaymentMethod.module.scss";
import PaymentRadio from "@app/features/payment/components/payment-method/PaymentRadio";
import { PaymentMethods } from "@app/features/payment/types/payment-method";
import { Link } from "react-router-dom";
import CardList from "@app/features/payment/components/payment-method/CardList";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

const PaymentMethod = () => {
  const paymentMethods = [
    { name: "payment", label: "Credit Card", value: PaymentMethods.CARD },
    { name: "payment", label: "PayPal", value: PaymentMethods.PAYPAL },
    { name: "payment", label: "Apple Pay", value: PaymentMethods.APPLE },
    { name: "payment", label: "Google Pay", value: PaymentMethods.GOOGLE },
  ];
  const [selectedPayment, setSelectedPayment] = useState("card");
  const handlePaymentSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPayment(e.target.value);
  };

  const displaySelectedMethod = () => {
    switch (selectedPayment) {
      case PaymentMethods.CARD:
        return <CardList />;
      case PaymentMethods.PAYPAL:
        return "PAYPAL";
      case PaymentMethods.APPLE:
        return "APPLE";
      case PaymentMethods.GOOGLE:
        return "GOOGLE";
      default:
        return "CARD";
    }
  };
  return (
    <div className={styles.paymentWrapper}>
      <p className={styles.title}>Payment method</p>
      <CardList />
      <div>
        <Link to={PaymentPathEnum.CHECKOUT_ADD_CARD} className={styles.link}>
          Add new card
        </Link>
      </div>
    </div>
  );
};

export default PaymentMethod;
