import { addWeeks, isAfter } from "date-fns";
import cookie from "react-cookies";

import {
  SNIP_CART,
  SNIP_CART_JWT_TOKEN,
} from "@app/features/ecommerce/ecommerce";

import {AUTH_ACCESS_TOKEN, SW_CONTEXT_TOKEN} from "../constants/auth.keys";
import { CookieTokenDef, TokenDef } from "../types/auth-login.types";

const EXPIRE_TIME_WEEK = 1;

export const saveTokens = (token: TokenDef) => {
  const { accessToken, tokenType } = token;

  // TODO: update the logic to use expiresIn from user
  const cookieToken: CookieTokenDef = {
    accessToken,
    tokenType,
    expiresAt: addWeeks(new Date(), EXPIRE_TIME_WEEK),
  };
  cookie.save(AUTH_ACCESS_TOKEN, cookieToken, { path: "/" });
};

/**
 * Loads token from session cookie
 */
export const getTokens = () => {
  const value: CookieTokenDef = cookie.load(AUTH_ACCESS_TOKEN);

  const current = new Date();
  const expiry = new Date(value?.expiresAt);

  return {
    accessToken: value?.accessToken,
    isTokenExpired: isAfter(current, expiry),
    tokenType: value?.tokenType,
  };
};

/**
 * Clear snipcart token from session cookie
 */
const clearSnipCart = () => {
  cookie.remove(SNIP_CART, { path: "/" });
  cookie.remove(SNIP_CART_JWT_TOKEN, { path: "/" });
};

export const clearTokens = () => {
  clearSnipCart();
  cookie.remove(SW_CONTEXT_TOKEN, { path: "/" })
  return cookie.remove(AUTH_ACCESS_TOKEN, { path: "/" });
};

export const getCookie = () => {
  return cookie.load("sw-context-token");
};
