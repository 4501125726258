import { api } from "@app/api/api";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import {createAsyncThunk} from '@reduxjs/toolkit';
import {CancelClassDef} from '@app/features/classes/classes';

export const addToCart = (workshopId: string) => {
  return api.post(WorkshopEndpoints.CART, { workshopId });
};
export const cancelWorkshopBooking = (workshopOrderId: string) => {
  return api.post(WorkshopEndpoints.CANCEL_BOOKING, { workshopOrderId });
};