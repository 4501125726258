import React, { ReactNode } from "react";

import styles from "./Title.module.scss";

interface TitleProps {
  children: ReactNode;
}

const Title: React.FC<TitleProps> = ({ children }) => {
  return <h1 className={styles.heading}>{children}</h1>;
};

export default Title;
