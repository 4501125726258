import React, { memo, useEffect } from "react";

import { useSelector } from "react-redux";

import Loading from "@app/components/atoms/Loading/Loading";
import { ClassTypesEnum } from "@app/constants/classes";
import { BookedEnum } from "@app/features/classes/classes";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { getRecommendedClasses } from "@app/features/users/users";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import Sessions from "./Sessions";

interface SessionsContentfulProps {
  entryID: string;
}

interface SessionsDataModel {
  title: string;
  subtitle: string;
}

const SessionsContentful = memo(({ entryID }: SessionsContentfulProps) => {
  const { entry, loading: contentfulLoading } = useSingleEntry<
    SessionsDataModel
  >(entryID);
  const { recommendedClasses: classes, loading } = useSelector(
    (state: RootState) => state.classes
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getRecommendedClasses({
        limit: 10,
        booked: BookedEnum.FALSE,
        excludeTypes: [ClassTypesEnum.PRIVATE_SESSION],
      })
    );
  }, [dispatch]);

  return (
    <Loading isLoading={loading || contentfulLoading}>
      {entry && classes?.data?.length > 0 && (
        <Sessions
          title={entry.title}
          sessions={classes.data}
          subtitle={entry.subtitle}
        />
      )}
    </Loading>
  );
});

export default SessionsContentful;
