import React, { memo } from "react";

import { Col, Container, Row } from "react-grid-system";

import Image from "@app/components/atoms/Image/Image";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ClassesDef, getFirstExperience } from "@app/features/classes/classes";
import { getImageUrl } from "@app/helpers/image.helpers";
import { getExperienceTags } from "@app/helpers/practitioners.helpers";

import styles from "./ProductDetails.module.scss";
import PractitionerInfo from "./components/PractitionerInfo/PractitionerInfo";
import ProductExtras from "./components/ProductExtras/ProductExtras";
import ProductInfo from "./components/ProductInfo/ProductInfo";
import ProductText from "./components/ProductText/ProductText";

export interface ProductDetailsProps {
  product: ClassesDef;
}

const ProductDetails = memo(({ product }: ProductDetailsProps) => {
  return (
    <Section theme={ThemeEnum.KHAKI} noBackgroundOverlap>
      {!!product.image && (
        <div className={styles.imageWrapper}>
          <Image
            url={getImageUrl(product.image, IMAGE_TYPES.BANNER)}
            className={styles.image}
          />
        </div>
      )}
      <ContentWrapper>
        <Container fluid>
          <Row className={styles.contentWrapper} justify="between">
            <Col xs={12} lg={7}>
              <ProductText
                category={getFirstExperience(product)?.name}
                tags={getExperienceTags(product.experiences)}
                name={product.name}
                shortDescription={product.shortDescription}
                description={product.description}
              />
              <PractitionerInfo practitioner={product.practitioner} />
            </Col>
            <Col xs={12} lg={4}>
              <ProductInfo product={product} />
              <ProductExtras
                preparationTips={product.preparationTips}
                whatToBring={product.whatToBring}
                pdf={product.pdf}
              />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    </Section>
  );
});

export default ProductDetails;
