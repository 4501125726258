import React from "react";
import styles from "./Image.module.scss";
import cx from "classnames";

interface ImageProps {
  path: string | null;
  round?: boolean;
}

const Image: React.FC<ImageProps> = ({ path, round }) => {
  return (
    <>
      {path ? (
        <img
          src={path}
          alt="cart item"
          className={cx(styles.image, {
            [styles.round]: round,
          })}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Image;
