import React from "react";
import styles from "./RequestResetPassword.module.scss";
import CreateAccountLayout from "@app/features/auth/screens/CreateAccount/components/CreateAccountLayout";

import RequestResetPasswordComponent from "@app/features/auth/screens/ResetPasswordPage/components/RequestResetPasswordComponent";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";

const RequestResetPasswordPage = () => {
  return (
    <div className={styles.wrapper}>
      <CreateAccountLayout>
        <RequestResetPasswordComponent
          pathToInfo={AuthPathsEnum.RESET_RESET_PASSWORD_INFO}
        />
      </CreateAccountLayout>
    </div>
  );
};

export default RequestResetPasswordPage;
