import React, { useEffect, useState } from "react";

import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { useMount } from "react-use";

import Routes from "@app/routes/Routes";

import CookieBanner from "./components/atoms/CookieBanner/CookieBanner";
import LoadingSpinner from "./components/atoms/LoadingSpinner/LoadingSpinner";
import PaymentErrorModal from "./components/atoms/PaymentErrorModal/PaymentErrorModal";
import Section from "./components/layouts/Section/Section";
import { ENV } from "./constants/config";
import { ThemeEnum } from "./constants/theme.constants";
import { getTokens, getUser } from "./features/auth/auth";
import { SnipcartEvents } from "./features/ecommerce/constants/ecommerce.snipcart";
import { useInitLocalization } from "./features/localization/localization";
import { RootState } from "./redux/root-reducer";
import Basket from "@app/features/basket/Basket";
import Subscription from "@app/features/subscribe/Subscription";

const App = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const [snipcartLoading, setSnipcartLoading] = useState(true);

  const dispatch = useDispatch();
  const { loading: localizationLoading } = useInitLocalization();
  const { accessToken } = getTokens();

  useEffect(() => {
    if (accessToken) {
      dispatch(getUser());
    }
    ReactGA.initialize("{ ENV.GTM_ID}");
    TagManager.initialize({ gtmId: ENV.GTM_ID });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    document.addEventListener(SnipcartEvents.SNIPCART_READY, () => {
      setSnipcartLoading(false);
    });
  });

  // Show loading while getting user or while localization is loading
  if (
    (accessToken && !isAuthenticated) ||
    localizationLoading
    // snipcartLoading
  ) {
    return (
      <Section theme={ThemeEnum.KHAKI} fullHeight>
        <LoadingSpinner />
      </Section>
    );
  }

  return (
    <Router>
      <Routes />
      <Basket />
      <Subscription />
      <CookieBanner />
      <PaymentErrorModal />
    </Router>
  );
};

export default App;
