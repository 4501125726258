import React from "react";

import styles from "./SeeMoreCard.module.scss";
import { useHistory } from "react-router-dom";
import { SearchEndpointsEnum } from "@app/features/search/constants/search.endpoints";

interface SeeMoreCardInterface
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  linkId?: string;
}

const SeeMoreCard: React.FC<SeeMoreCardInterface> = ({ linkId }) => {
  const history = useHistory();
  const handleClick = (linkedId?: string) => {
    if (linkedId) {
      history.push(`${SearchEndpointsEnum.FILTERS}?categories[]=${linkedId}`);
    }
    return null;
  };

  return (
    <div className={styles.wrapper} onClick={() => handleClick(linkId)}>
      <p className={styles.text}>Show more</p>
    </div>
  );
};

export default SeeMoreCard;
