import React, {memo} from "react";

import {useSingleEntry} from "@app/features/contentful/contentful";
import {ContentfulMedia} from "@app/types/contentful.types";
import FullWidthVideo from '@app/components/renderings/FullWidthVideo/FullWidthVideo';
import {ThemeEnum} from '@app/constants/theme.constants';


interface FullWidthVideoContentfulProps {
  entryID: string;
}

interface TitleModel {
  sys: {
    id: string;
  };
  fields?: {
    text: string;
  };
}

interface FullWidthVideoDataModel {
  title: string;
  subtitle?: string;
  description?: string;
  video: ContentfulMedia;
  theme: ThemeEnum;
}

const FullWidthVideoContentful = memo(({entryID}: FullWidthVideoContentfulProps) => {
  const {entry} = useSingleEntry<FullWidthVideoDataModel>(entryID);

  if (!entry) return null;

  return (
    <FullWidthVideo
      theme={entry.theme}
      video={entry.video?.fields.file.url}
      description={entry.description}
      title={entry.title}
      subtitle={entry.subtitle}
    />
  );
});

export default FullWidthVideoContentful;
