import React from "react";
import styles from "./CheckoutRegisterPage.module.scss";
import Summary from "@app/features/payment/components/Summary";
import CreateAccountForm from "@app/features/auth/screens/CreateAccount/components/CreateAccountForm";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

const CheckoutRegisterPage = () => {
  return (
    <div className={styles.wrapper}>
      <Summary className={styles.left} />
      <div className={styles.right}>
        <CreateAccountForm
          pathOnSuccess={PaymentPathEnum.CHECKOUT_NEW_PAYMENT}
          pathToSignIn={PaymentPathEnum.CHECKOUT_SIGN_IN}
          title="Check out"
          backArrow={false}
          redirect="checkout"
          verifyEmail={false}
        />
      </div>
    </div>
  );
};

export default CheckoutRegisterPage;
