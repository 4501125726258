import React, { ReactElement, ReactNode } from "react";

import cx from "classnames";
import RCModal from "react-modal";

import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import { IconClose } from "../Icon/Icon";
import { Title } from "../Typography/Typography";
import styles from "./Modal.module.scss";
import "./ModalOverwrites.scss";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

// Exporting sub modals
export { default as ConfirmationModal } from "./components/ConfirmationModal/ConfirmationModal";
export { default as FormModal } from "./components/FormModal/FormModal";
export { default as ErrorModal } from "./components/ErrorModal/ErrorModal";

export interface ModalProps {
  title?: string;
  subtitle?: string;
  icon?: ReactElement;
  headerCenter?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  className?: string;
  headerClassName?: string;
  visible: boolean;
  maskClosable?: boolean;
  showSpinner?: boolean;
  children?: ReactNode;
}

/**
 * Base Modal
 * Special case modals are exported below
 *  - FormModal (The modal contains a form with button for submission)
 */
const Modal = ({
  title,
  subtitle,
  icon,
  headerCenter,
  showCloseButton,
  children,
  onClose,
  className,
  headerClassName,
  visible,
  maskClosable,
  showSpinner,
  ...rest
}: ModalProps) => {
  const showHeader = !!title || !!subtitle || React.isValidElement(icon);

  return (
    <RCModal
      isOpen={visible}
      onRequestClose={onClose}
      className={cx(styles.modal, className)}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={maskClosable}
      ariaHideApp={false}
      closeTimeoutMS={200}
      {...rest}
    >
      <Section theme={ThemeEnum.WHITE} fullHeight className={styles.container}>
        {showSpinner && <LoadingSpinner isMedium className={styles.spinner} />}
        {showHeader && (
          <div
            className={cx(styles.header, headerClassName, {
              [styles.headerCenter]: headerCenter,
            })}
          >
            {React.isValidElement(icon) && icon}
            {!!title && (
              <Title className={styles.title} level={2}>
                {title}
              </Title>
            )}
            {!!subtitle && (
              <Title className={styles.subtitle} level={3}>
                {subtitle}
              </Title>
            )}
          </div>
        )}
        {showCloseButton && (
          <button
            className={styles.closeButton}
            type="button"
            aria-label="Close"
            onClick={onClose}
          >
            <IconClose />
          </button>
        )}
        {children}
      </Section>
    </RCModal>
  );
};

export default Modal;
