import { LineItem } from "@app/features/basket/Types/backend-basket.types";
import { WorkshopLineItem } from "@app/features/basket/Types/workshop-basket.types";

export const getRouteToClass = (basketItem: LineItem | WorkshopLineItem) => {
  if (basketItem.type === "kaylo-workshop") {
    const {
      payload: { kayloWorkshopId },
    } = basketItem as WorkshopLineItem;
    return `/workshop/${kayloWorkshopId}`;
  }

  if (basketItem.type === "product") {
    const {
      payload: {
        productNumber,
        netzp_event: { id },
      },
    } = basketItem as LineItem;
    return `/class/${id}/${productNumber}`;
  }
};
