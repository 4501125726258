import React, { useEffect } from "react";
import styles from "@app/features/payment/pages/CheckoutRegisterPage.module.scss";
import Summary from "@app/features/payment/components/Summary";
import NewCard from "@app/features/payment/components/payment-method/NewCard";
import { useSelector } from "react-redux";
import ZeroPayment from "@app/features/payment/components/payment-method/ZeroPayment";
import { zeroBasketSelector } from "@app/features/basket/redux/discount.selectors";
import { RootState } from "@app/redux/root-reducer";
import { useHistory } from "react-router-dom";

const CheckoutNewPayment = () => {
  const zeroBasket = useSelector(zeroBasketSelector);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/dashboard");
    }
  }, [isAuthenticated, history]);

  return (
    <div className={styles.wrapper}>
      <Summary className={styles.left} />
      <div className={styles.right}>
        {!zeroBasket ? <NewCard /> : <ZeroPayment />}
      </div>
    </div>
  );
};

export default CheckoutNewPayment;
