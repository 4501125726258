import React from "react";
import {WorkshopMediaBannerInterface} from "@app/features/workshops/types/summary.interface";

import styles from "./WorkshopMedia.module.scss";

interface WorkshopMediaTypes
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  media: WorkshopMediaBannerInterface;
}

const WorkshopMedia: React.FC<WorkshopMediaTypes> = ({ media, ...rest }) => {
  const getMedia = () => {
    if (media?.video?.url) {
            // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
      return (
        <div className={styles.video}>
          <video
            src={media?.video?.url}
            autoPlay={false}
            controls
            preload="auto"
            muted
            height="100%"
            width="100%"
            controlsList="nofullscreen nodownload"
          >
            Sorry, your browser does not support embedded videos
          </video>
        </div>
      );
    }

    if (media?.image?.url) {
      return (
        <img
          src={media?.image?.url}
          alt={media.image.url}
          className={styles.image}
        />
      );
    }
    return null;
  };

  return (
    <div className={styles.wrapper} {...rest}>
      {getMedia()}
    </div>
  );
};

export default WorkshopMedia;
