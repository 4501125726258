import React, { useEffect, useState } from "react";
import "./select.scss";
import styles from "./PaymentSelect.module.scss";
import { ErrorMessage, Field } from "formik";

import TextError from "@app/features/auth/components/FormControls/TextError";
import { basketApi } from "@app/features/basket/api/basket.api";
import { check500Error } from "@app/helpers/error.helpers";

interface PaymentSelectProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: string;
  label: string;
}

interface CountriesListProps {
  name: string;
  id: string;
}

const PaymentSelect: React.FC<PaymentSelectProps> = ({
  name,
  label,
  ...rest
}) => {
  // type errorProps = {
  //   countryId: string | undefined;
  // };
  // const { errors } = useFormikContext<errorProps>();
  const [countryList, setCountryList] = useState<CountriesListProps[]>([]);
  useEffect(() => {
    const getCountriesList = async () => {
      try {
        const response = await basketApi.getCountries();
        setCountryList(
          response.data.sort((a: any, b: any) => (a.name > b.name && 1) || -1)
        );
      } catch (err) {
        check500Error(err);
      }
    };
    getCountriesList();
  }, []);

  return (
    <div {...rest}>
      <span className={styles.label}>{label}</span>

      <Field
        as="select"
        aria-invalid="false"
        name={name}
        className={styles.select}
      >
        <option disabled value="select">
          Select country
        </option>
        {countryList.map(country => (
          <option value={country.id} key={country.id}>
            {country.name}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default PaymentSelect;
