import React, { memo, useEffect, useState } from "react";

import cx from "classnames";
import { FormikHelpers } from "formik";
import _camelCase from "lodash/camelCase";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import { FormModal } from "@app/components/atoms/Modal/Modal";
import {
  formValuesMapper,
  SearchFacetFiltersEnum,
  SearchFiltersDef,
} from "@app/features/search/search";

import { FiltersDef, getFilterLabel } from "../../SearchFilters";
import DropdownButton from "../DropdownButton/DropdownButton";
import styles from "./FiltersModalMobile.module.scss";
import ModalContent from "./components/ModalContent/ModalContent";

interface FiltersModalMobileProps {
  visible: boolean;
  filters: Partial<FiltersDef>;
  selected: SearchFiltersDef;
  onClose: () => void;
  onApplyFilters: (values: SearchFiltersDef) => void;
}

const FiltersModalMobile = memo(
  ({
    visible,
    onClose,
    selected,
    filters,
    onApplyFilters,
  }: FiltersModalMobileProps) => {
    const { t } = useTranslation();
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [
      activeFilterKey,
      setActiveFilterKey,
    ] = useState<SearchFacetFiltersEnum | null>(null);
    const [selectedFilters, setSelectedFilters] = useState(selected);
    const selectedFilterKeys = Object.keys(
      selectedFilters
    ) as SearchFacetFiltersEnum[];

    const handleSubmit = () => {
      onApplyFilters(selectedFilters);
    };

    const handleClearAll = () => {
      setSelectedFilters({});
    };

    const hasActiveFilters = (filterKey: SearchFacetFiltersEnum) => {
      return (
        selectedFilterKeys.includes(filterKey) &&
        !_isEmpty(selectedFilters[filterKey])
      );
    };

    const getDropdownLabel = (filterKey: SearchFacetFiltersEnum) => {
      const activeFilters = selectedFilters[filterKey];
      if (activeFilters) {
        const othersLabel =
          activeFilters.length > 1 ? `+${activeFilters.length - 1}` : "";
        const filterObj = filters[filterKey]?.find(
          filter => filter.id.toString() === activeFilters[0]
        );
        if (filterObj) {
          return `${getFilterLabel(
            t,
            filterKey,
            filterObj.value
          )} ${othersLabel}`;
        }
      }
      return t(`search.${_camelCase(filterKey)}Label`);
    };

    const handleFilterClick = (filterKey: SearchFacetFiltersEnum) => {
      setActiveFilterKey(filterKey);
      setFilterModalOpen(true);
    };

    const handleFilterModalClose = () => {
      setActiveFilterKey(null);
      setFilterModalOpen(false);
    };

    const handleFilterSubmit = (values: SearchFiltersDef) => {
      if (activeFilterKey) {
        setSelectedFilters({
          ...selectedFilters,
          [activeFilterKey]: values[activeFilterKey],
        });
      }
      setActiveFilterKey(null);
      setFilterModalOpen(false);
    };

    const handleSectionClear = (
      filterKey: string,
      setFieldValue: FormikHelpers<void>["setFieldValue"]
    ) => {
      setFieldValue(filterKey, []);
    };

    const handleModalClose = () => {
      /* Reset state on close */
      setSelectedFilters(selected);
      onClose();
    };

    useEffect(() => {
      setSelectedFilters(selected);
    }, [selected]);

    return (
      <>
        {!activeFilterKey ? (
          <FormModal
            visible={visible}
            submitButtonLabel={t("search.doneButtonLabel")}
            showCloseButton
            className={styles.modal}
            formikProps={{ initialValues: {}, onSubmit: handleSubmit }}
            onClose={handleModalClose}
          >
            <ModalContent
              title={t("search.filtersModalTitle")}
              onClear={() => handleClearAll()}
              clearButtonLabel={t("search.clearAllButtonTitle")}
            >
              {(Object.keys(filters) as SearchFacetFiltersEnum[]).map(
                filterKey => (
                  <DropdownButton
                    label={getDropdownLabel(filterKey)}
                    onClick={() => handleFilterClick(filterKey)}
                    className={cx(styles.item, {
                      [styles.active]: hasActiveFilters(filterKey),
                    })}
                    key={filterKey}
                  />
                )
              )}
            </ModalContent>
          </FormModal>
        ) : (
          <FormModal
            submitButtonLabel={t("search.applyButtonLabel")}
            visible={filterModalOpen}
            onClose={handleFilterModalClose}
            formikProps={{
              enableReinitialize: true,
              initialValues: formValuesMapper(selectedFilters),
              onSubmit: handleFilterSubmit,
            }}
            className={styles.modal}
            showCloseButton
          >
            {({ setFieldValue }) => (
              <ModalContent
                title={t(`search.${_camelCase(activeFilterKey)}Label`)}
                onClear={() =>
                  handleSectionClear(activeFilterKey, setFieldValue)
                }
                clearButtonLabel={t("search.clearButtonTitle")}
              >
                {filters[activeFilterKey]?.map(filter => (
                  <div className={styles.item} key={filter.id}>
                    <FormItem
                      name={activeFilterKey}
                      // label={getFilterLabel(t, activeFilterKey, filter.value)}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                      // @ts-ignore:next-line
                      label={filter.value || filter.name}
                      type="checkbox"
                      hideError
                      value={filter.id.toString()}
                    />
                  </div>
                ))}
              </ModalContent>
            )}
          </FormModal>
        )}
      </>
    );
  }
);

export default FiltersModalMobile;
