import { addHours, addMinutes, isAfter, isBefore } from "date-fns";

import { ClassTypesEnum } from "@app/constants/classes";

import { ClassesDef } from "../types/classes.types";

export const getFirstExperience = (session: ClassesDef) => {
  return session.experiences?.[0];
};

// Allow seeker to enter session some minutes before start time
const ALLOW_EARLY_ENTRANCE_MINUTES = 5;

export const isLiveSession = (session: ClassesDef) => {
  if (session?.slots?.length) {
    return isAfter(
      addMinutes(new Date(), ALLOW_EARLY_ENTRANCE_MINUTES),
      new Date(session?.slots[0]?.start)
    );
  }
  return isAfter(
    addMinutes(new Date(), ALLOW_EARLY_ENTRANCE_MINUTES),
    new Date(session.startAt)
  );
};

export const isPrivateSession = (session: ClassesDef) => {
  return session.type === ClassTypesEnum.PRIVATE_SESSION;
};

// Hours before cancellation can occur
const PRIVATE_SESSION_CANCELLATION_HOURS = 72;
const SESSION_CANCELLATION_HOURS = 24;

export const canCancel = (session: ClassesDef) => {
  const sessionStartTime = new Date(session.startAt);
  return isPrivateSession(session)
    ? isBefore(
        addHours(new Date(), PRIVATE_SESSION_CANCELLATION_HOURS),
        sessionStartTime
      )
    : isBefore(
        addHours(new Date(), SESSION_CANCELLATION_HOURS),
        sessionStartTime
      );
};
export const canCancelWorkshop = (session: ClassesDef) => {
  const sessionStartTime = new Date(session.workshopStartAt || 0);
  return isBefore(
    addHours(new Date(), PRIVATE_SESSION_CANCELLATION_HOURS),
    sessionStartTime
  )
};
