import { GlobalMediaQueriesDef } from "@app/types/breakpoint.types";

export const GLOBAL_MEDIA_QUERIES: GlobalMediaQueriesDef = {
  mobile: "(max-width: 575px)",
  mobileLandscape: "(min-width: 576px) and (max-width: 767px)",
  tabletPortrait: "(min-width: 768px)",
  tabletLandscape: "(min-width: 1024px)",
  desktopSmall: "(min-width: 1200px)",
  desktopMedium: "(min-width: 1440px)",
  desktopLarge: "(min-width: 1920px)",
};

export enum ScreenSizes {
  XSMALL = 320,
  SMALL = 576,
  MEDIUM = 768,
  LARGE = 1024,
  XLARGE = 1200,
}
