export enum PaymentMethodLabels {
  CARD = "Card",
  PAYPAL = "PayPal",
  GOOGLE = "Google Pay",
  APPLE = "Apple Pay",
}
export enum PaymentMethods {
  CARD = "card",
  PAYPAL = "paypal",
  GOOGLE = "google",
  APPLE = "apple",
}
