import React, { memo, useEffect } from "react";

import { format } from "date-fns";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import Modal from "@app/components/atoms/Modal/Modal";
import {
  Caption,
  Paragraph,
  Subtitle,
} from "@app/components/atoms/Typography/Typography";
import { DateFormats } from "@app/constants/date.constants";
import { IMAGE_TYPES } from "@app/constants/image";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import { WorkshopSummaryInterface } from "@app/features/workshops/types/summary.interface";
import { getImageUrl } from "@app/helpers/image.helpers";
import { removeUnderline } from "@app/helpers/underline.helpers";
import { useRequest } from "@app/hooks/useRequest";
import { RootState } from "@app/redux/root-reducer";

import styles from "./CancelWorkshopModal.module.scss";

interface CancelWorkshopModalProps {
  visible: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  workshopId?: string;
}

const CancelWorkshopModal = memo(
  ({ visible, onConfirm, onDismiss, workshopId }: CancelWorkshopModalProps) => {
    const { t } = useTranslation();
    const loading = useSelector(
      (state: RootState) => state.classes.cancelLoading
    );
    let workshop: WorkshopSummaryInterface | null = null;
    if (workshopId) {
      const {
        data: workshopResponse,
        fetchData: fetchWorkshopData,
      } = useRequest(`${WorkshopEndpoints.SUMMARY}/${workshopId}`);

      workshop = (workshopResponse as never) as WorkshopSummaryInterface;
      useEffect(() => {
        fetchWorkshopData();
      }, [fetchWorkshopData]);
    }
    const handleOnClick = (
      event: React.SyntheticEvent<Element, Event>,
      callback: () => void
    ) => {
      event.preventDefault();
      event.stopPropagation();
      callback();
    };

    return (
      <Modal visible={visible} className={styles.modal} onClose={onDismiss}>
        <div className={styles.content}>
          <Subtitle level={2} className={styles.title}>
            Are you sure you want to cancel your booking?
          </Subtitle>
          <Paragraph level={2}>
            This class is a part of a workshop. If you cancel this class, you
            will also cancel the entire workshop. Are you sure you want to
            cancel your booking?
          </Paragraph>
          <div className={styles.sessionInfo}>
            <Image
              className={styles.image}
              url={getImageUrl(
                workshop?.banner?.image?.url ?? "",
                IMAGE_TYPES.SESSION
              )}
            />
            <div>
              <Subtitle level={2}>{workshop?.title}</Subtitle>
              <Caption className={styles.date}>
                {`${format(
                  new Date(workshop?.startAt || 0),
                  DateFormats.LONG_MODAL
                )} - ${removeUnderline(
                  workshop ? workshop.workshop_duration : "unknown length"
                )} course`}
              </Caption>
            </div>
          </div>
          <Row gutterWidth={10}>
            <Col xs={12} md={6}>
              <Button
                label={t("sessionCard.cancelSessionConfirmButton")}
                buttonType={ButtonTypeEnum.SECONDARY}
                onClick={e => handleOnClick(e, onConfirm)}
                fullWidth
                className={styles.cancelButton}
                loading={loading}
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                label={t("sessionCard.cancelSessionCancelButton")}
                buttonType={ButtonTypeEnum.PRIMARY}
                onClick={e => handleOnClick(e, onDismiss)}
                fullWidth
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
);

export default CancelWorkshopModal;
