import React from "react";
import { Form, Formik } from "formik";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import styles from "./CardInfo.module.scss";
import Checkbox from "@app/features/auth/components/FormControls/Checkbox";
import FormRadio from "@app/features/payment/components/payment-method/FormRadio";
import NewAddress from "@app/features/payment/components/payment-method/NewAddress";
import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import MaskedFormInput from "@app/features/auth/components/FormControls/MaskedFormInput";
import { Container, Row, Col } from "react-grid-system";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

const CardInfo = () => {
  const history = useHistory();
  const initialValues = {
    cardholderName: "",
    cardNumber: "",
    expirationDate: "",
    cvvCode: "",
    saveCardDetails: false,
    address: "previous",
    addressNew: "",
    town: "",
    region: "",
  };
  const validationSchema = Yup.object({
    cardholderName: Yup.string().required("Name is required"),
    cardNumber: Yup.string().required("Number is required"),
    expirationDate: Yup.string().required("Date is required"),
    cvvCode: Yup.string().required("CVV is required"),
    addressNew: Yup.string().when("address", {
      is: "new",
      then: Yup.string().required("Address is required"),
    }),
    town: Yup.string().when("address", {
      is: "new",
      then: Yup.string().required("Town is required"),
    }),
    region: Yup.string().when("address", {
      is: "new",
      then: Yup.string().required("Region is required"),
    }),
    post: Yup.string().when("address", {
      is: "new",
      then: Yup.string().required("Required"),
    }),
  });
  const onSubmit = (formValues: any) => {
    console.log(formValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <Form className={styles.formWrapper}>
          <Container fluid style={{ padding: "0", margin: 0 }}>
            <Row nogutter>
              <Col>
                <FormInput
                  className={styles.item}
                  label="Cardholder Name"
                  name="cardholderName"
                  type="input"
                  placeholder="Type here"
                  errors={
                    formik.touched.cardholderName
                      ? formik.errors.cardholderName
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row align="end">
              <Col xs={12} md={6}>
                <MaskedFormInput
                  className={styles.item}
                  label="Card Number"
                  name="cardNumber"
                  type="input"
                  placeholder="1234 5678 1234 5678"
                  mask="#### #### #### ####"
                />
              </Col>
              <Col xs={8} md={4}>
                <MaskedFormInput
                  className={styles.item}
                  label="Expiration Date"
                  name="expirationDate"
                  type="input"
                  placeholder="MM/YY"
                  mask="##/##"
                />
              </Col>
              <Col xs={4} md={2}>
                <MaskedFormInput
                  className={styles.item}
                  label="CVV code"
                  name="cvvCode"
                  type="input"
                  placeholder="XXXX"
                  mask="####"
                />
              </Col>
            </Row>
            <Col>
              <Row nogutter>
                <Checkbox
                  name="saveCardDetails"
                  label="Save card details"
                  type="checkbox"
                />
              </Row>
            </Col>

            <p className={styles.addressTitle}>Billing address</p>
            <FormRadio
              id="id1"
              name="address"
              label="Use my previous address"
              value="previous"
              className={styles.radio}
            />
            {formik.values.address === "previous" && (
              <p className={styles.prevAddress}>
                Unit 7, Powergate Business Park, Volt Avenue, London, NW10
              </p>
            )}
            <FormRadio
              id="id2"
              name="address"
              label="Use a new address"
              value="new"
            />
            {formik.values.address === "new" && <NewAddress />}
          </Container>
          <div className={styles.controls}>
            <Button
              label="cancel"
              buttonType={ButtonTypeEnum.SECONDARY}
              onClick={() => history.push(PaymentPathEnum.CHECKOUT_PAYMENT)}
            />
            <Button label="Add card" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CardInfo;
