import React from "react";
import { IconArrowLeft } from "@app/components/atoms/Icon/Icon";
import styles from "./BackButton.module.scss";

interface BackButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title: string;
}

const BackButton: React.FC<BackButtonProps> = ({ title, ...rest }) => {
  return (
    <div {...rest} className={styles.wrapper}>
      <IconArrowLeft className={styles.label} />
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default BackButton;
