import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ClassesDef } from "@app/features/classes/classes";
import { BasketStateProps, BulkInterface } from "../Types/basket.types";

import { basketApi } from "@app/features/basket/api/basket.api";
import { PaymentInterface } from "@app/features/basket/Types/payment.types";
import {
  BackendBasketItem,
  LineItem,
} from "@app/features/basket/Types/backend-basket.types";
import {
  addDiscountCode,
  clearError,
} from "@app/features/basket/redux/discount.slice";
import { check500Error } from "@app/helpers/error.helpers";

const initialState: BasketStateProps = {
  isDisplayed: false,
  basket: {} as BackendBasketItem,
  loading: false,
  error: "",
  paymentCard: undefined,
};

export const requestGetCart = createAsyncThunk(
  "basket/getCart",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearError());
      const cart = await basketApi.getCart();
      const promotionItem = cart.data?.lineItems.find(
        (item: any) => item.type === "promotion"
      );
      if (promotionItem) {
        dispatch(addDiscountCode(promotionItem.referencedId));
      }

      cart.data.price.totalPrice = parseFloat(cart.data.price.totalPrice.toString().replace(',', ''));

      return cart.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const addToCart = createAsyncThunk(
  "basket/addToCart",
  async (product: LineItem | ClassesDef, { rejectWithValue, getState }) => {
    // const { basket } = getState() as RootState;
    //
    // const items = basket?.basket?.lineItems;
    // const alreadyInBasket = items.find(
    //   item => item.payload.netzp_event.id === product.id
    // );
    // if (alreadyInBasket) {
    //   return null;
    // }

    try {
      return await basketApi.addProductToCart(product);
    } catch (err) {
      check500Error(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "basket/removeFromCart",
  async (product: LineItem, { rejectWithValue, dispatch }) => {
    try {
      return await basketApi.removeProductFromCart(product.id as string);
    } catch (err) {
      return rejectWithValue(err.response.data);
    } finally {
      dispatch(requestGetCart());
    }
  }
);
export const clearCart = createAsyncThunk(
  "basket/clearCart",
  async (_, { rejectWithValue }) => {
    try {
      const cart = await basketApi.clearCart();
      return cart.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bulkAddToCart = createAsyncThunk(
  "basket/bulkAddToCatt",
  async (options: BulkInterface, { rejectWithValue }) => {
    try {
      return await basketApi.bulkAddProducts(options);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    openBasket: (state: BasketStateProps) => {
      state.isDisplayed = true;
    },
    closeBasket: (state: BasketStateProps) => {
      state.isDisplayed = false;
    },
    addPaymentCard(state, action: PayloadAction<PaymentInterface>) {
      state.paymentCard = action.payload;
    },
    removePaymentCard(state) {
      state.paymentCard = undefined;
    },
    clearBasket(state) {
      state.basket = {} as BackendBasketItem;
      state.loading = false;
      state.error = "false";
    },
  },
  extraReducers: builder => {
    builder.addCase(requestGetCart.pending, state => {
      state.loading = true;
    });
    builder.addCase(requestGetCart.fulfilled, (state, action) => {
      state.loading = false;
      state.basket = action.payload;
    });
    builder.addCase(requestGetCart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(addToCart.pending, state => {
      state.loading = true;
    });
    builder.addCase(addToCart.fulfilled, state => {
      state.loading = false;
      state.isDisplayed = true;
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(removeFromCart.pending, state => {
      state.loading = true;
    });
    builder.addCase(removeFromCart.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(removeFromCart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(clearCart.pending, state => {
      state.loading = true;
    });
    builder.addCase(clearCart.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(clearCart.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});

export const { openBasket, closeBasket, clearBasket } = basketSlice.actions;
export default basketSlice.reducer;
