import { ARTICLES_ROUTES } from "@app/features/articles/articles";
import { AUTH_ROUTES } from "@app/features/auth/auth";
import { CLASSES_ROUTES } from "@app/features/classes/classes";
import {
  PRODUCT_DETAILS_ROUTES,
  CONFIRMATION_ROUTES,
  PAYMENT_ROUTES,
} from "@app/features/ecommerce/ecommerce";
import { EXPERIENCES_ROUTES } from "@app/features/experiences/experiences";
import { PRACTITIONERS_ROUTES } from "@app/features/practitioners/practitioners";
import { PREVIEW_ROUTES } from "@app/features/preview/preview";
import { SEARCH_ROUTES } from "@app/features/search/search";
import { SESSION_ROUTES } from "@app/features/session/session";
import { USER_ROUTES } from "@app/features/users/users";
import { RouteItemDef } from "@app/types/route.types";
import { CHECKOUT_ROUTE } from "@app/features/payment/routes/payment.routes";
import { ERROR_ROUTES } from "@app/routes/components/Error/error.routes";

import StaticPage from "./components/StaticPage/StaticPage";

const STATIC_PAGE: RouteItemDef = {
  id: "default-page",
  path: "*",
  component: StaticPage,
};

export const PUBLIC_LIST = [
  ...AUTH_ROUTES,
  ...USER_ROUTES,
  ...PRODUCT_DETAILS_ROUTES,
  ...PRACTITIONERS_ROUTES,
  ...EXPERIENCES_ROUTES,
  ...ARTICLES_ROUTES,
  ...SEARCH_ROUTES,
  ...CLASSES_ROUTES,
  ...PREVIEW_ROUTES,
  ...SESSION_ROUTES,
  ...CHECKOUT_ROUTE,
  ...ERROR_ROUTES,
  STATIC_PAGE,
];

export const PRIVATE_LIST = [
  ...AUTH_ROUTES,
  ...USER_ROUTES,
  ...SESSION_ROUTES,
  ...PRODUCT_DETAILS_ROUTES,
  ...CONFIRMATION_ROUTES,
  ...PRACTITIONERS_ROUTES,
  ...EXPERIENCES_ROUTES,
  ...ARTICLES_ROUTES,
  ...SEARCH_ROUTES,
  ...CLASSES_ROUTES,
  ...PREVIEW_ROUTES,
  ...PAYMENT_ROUTES,
  ...CHECKOUT_ROUTE,
  ...ERROR_ROUTES,
  STATIC_PAGE,
];
