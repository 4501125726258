import React, { useState } from "react";

import cx from "classnames";
import { Field, ErrorMessage } from "formik";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

import Tooltip from "../../screens/CreateAccount/components/Tooltip";
import styles from "./FormInput.module.scss";
import TextError from "./TextError";
import NumberInput from "@app/features/auth/components/FormControls/NumberInput";

interface FormInputProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: string;
  label: string;
  type: string;
  secondLabel?: JSX.Element;
  secondLabelDescription?: string;
  isPassword?: boolean | undefined;
  placeholder?: string;
  errors?: string;
  showEye?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  type,
  secondLabel,
  isPassword,
  errors,
  placeholder,
  showEye = true,
  ...rest
}) => {
  const [password, setPassword] = useState(isPassword);

  return (
    <div className={styles.formControl} {...rest}>
      <label htmlFor={name} className={cx(styles.label)}>
        {label}{" "}
        {secondLabel && (
          <Tooltip text="Your password should be at least 8 characters long, containing at least one uppercase letter, one lowercase letter and one number">
            {secondLabel}
          </Tooltip>
        )}
      </label>

      <Field
        type={password ? type : "text"}
        id={name}
        name={name}
        autoComplete="off"
        className={styles.input}
        placeholder={placeholder}
      />
      {isPassword &&
        showEye &&
        (!password ? (
          <AiOutlineEye
            className={styles.passwordIcon}
            onClick={() => setPassword(prev => !prev)}
          />
        ) : (
          <AiOutlineEyeInvisible
            className={styles.passwordIcon}
            onClick={() => setPassword(prev => !prev)}
          />
        ))}
      <ErrorMessage name={name} component={TextError} />
      {errors && <TextError>{errors}</TextError>}
    </div>
  );
};

export default FormInput;
