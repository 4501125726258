import React from "react";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import styles from "./NewAddress.module.scss";
import PaymentSelect from "@app/features/payment/components/payment-method/PaymentSelect";
import { Row, Col } from "react-grid-system";

const NewAddress = () => {
  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          <FormInput
            className={styles.item}
            name="address"
            label="Address"
            type="input"
            placeholder="Type here street name, apartment, suite, unit"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <FormInput
            className={styles.item}
            name="city"
            label="Town / City"
            type="input"
            placeholder="Type here"
          />
        </Col>
        <Col xs={12} md={6}>
          <FormInput
            className={styles.item}
            name="region"
            label="Region / State"
            type="input"
            placeholder="Type here"
          />
        </Col>
      </Row>

      <Row align="end">
        <Col xs={12} md={8}>
          <PaymentSelect
            label="Country"
            name="countryId"
            className={styles.item}
          />
        </Col>
        <Col xs={7} md={4}>
          <FormInput
            className={styles.item}
            name="zip"
            label="Post code/ZIP code"
            type="input"
            placeholder="Type here"
          />
        </Col>
      </Row>
    </div>
  );
};

export default NewAddress;
