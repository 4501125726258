import { addMinutes, isBefore } from "date-fns";

import { ClassesDef } from "@app/features/classes/types/classes.types";

const ALLOW_TO_BOOK = 5;
export const isAllowedToBook = (product: ClassesDef) => {
  if (product.slots) {
    const after = isBefore(
      addMinutes(new Date(), ALLOW_TO_BOOK),
      new Date(product?.slots[0]?.start)
    );
    return after;
  }
  return true;
};
