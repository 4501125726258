import React from "react";

import DynamicSection from "@app/components/layouts/DynamicSection/DynamicSection";
import { ContentModel } from "@app/constants/contentful.constants";
import { validateEnum } from "@app/helpers/util.helpers";

import { StaticPageComponentsDef } from "../StaticPageLoader/StaticPageLoader";

type StaticPageComponentsProps = {
  components: StaticPageComponentsDef[];
};

const StaticPageComponents = ({ components }: StaticPageComponentsProps) => {
  return (
    <>
      {components?.map(section => {
        const contentType = section?.sys?.contentType?.sys?.id;
        const sectionName = validateEnum(ContentModel, contentType)
          ? (contentType as ContentModel)
          : null;
        if (!sectionName) return null;
        const SectionComponent = DynamicSection[sectionName];
        if (!SectionComponent) return null;

        return (
          <SectionComponent key={section.sys.id} entryID={section.sys.id} />
        );
      })}
    </>
  );
};

export default StaticPageComponents;
