import React, { memo } from "react";

import { ThemeEnum, AlignmentEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { ContentfulMedia } from "@app/types/contentful.types";

import ThreeUSP, { ThreeUSPItemsProps } from "./ThreeUSP";

interface ThreeUSPContentfulProps {
  entryID: string;
}

interface ThreeUspItemsModel {
  fields?: {
    description: string;
    title: string;
    image: ContentfulMedia;
    link: string;
    CTAText: string;
  };
}

interface USPDataModel {
  theme: string;
  heading: string;
  alignment: string;
  threeUspItems: ThreeUspItemsModel[];
}

const ThreeUSPContentful = memo(({ entryID }: ThreeUSPContentfulProps) => {
  const { entry: threeUSPData } = useSingleEntry<USPDataModel>(entryID);

  const mapItems = (threeUspItems: ThreeUspItemsModel[]) => {
    const items = threeUspItems
      // Only published items will have fields
      .filter(item => !!item.fields)
      .map((item: ThreeUspItemsModel) => ({
        image: item.fields?.image.fields.file.url,
        description: item.fields?.description,
        title: item.fields?.title,
        link: item.fields?.link,
        CTAText: item.fields?.CTAText,
      }));

    return items as ThreeUSPItemsProps[];
  };

  if (!threeUSPData) return null;

  const { theme, heading, threeUspItems, alignment } = threeUSPData;

  return (
    <ThreeUSP
      theme={theme as ThemeEnum}
      heading={heading}
      items={mapItems(threeUspItems)}
      alignment={alignment as AlignmentEnum}
    />
  );
});

export default ThreeUSPContentful;
