import React from "react";
import styles from "@app/features/payment/pages/CheckoutRegisterPage.module.scss";
import Summary from "@app/features/payment/components/Summary";
import CheckoutTitle from "@app/features/payment/components/CheckoutTitle";
import CardInfo from "@app/features/payment/components/payment-method/CardInfo";

const CheckoutAddCardPage = () => {
  return (
    <div className={styles.wrapper}>
      <Summary className={styles.left} />
      <div className={styles.right}>
        <CheckoutTitle title="Add new card" />
        <CardInfo />
      </div>
    </div>
  );
};

export default CheckoutAddCardPage;
