import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  memo,
} from "react";

import cx from "classnames";
import { Link, useLocation } from "react-router-dom";

import { Title } from "@app/components/atoms/Typography/Typography";
import { isURL } from "@app/helpers/util.helpers";

import ExternalLink from "../ExternalLink/ExternalLink";
import styles from "./Button.module.scss";

export enum ButtonTypeEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum ButtonSizeEnum {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  TEXT = "text",
}

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  /**
   * The label in the button
   */
  label?: string;
  /**
   * The type of the button
   */
  buttonType?: ButtonTypeEnum;
  /**
   * The size of the button
   */
  size?: ButtonSizeEnum;
  /**
   * Set to true, if the button will submit a form
   */
  submit?: boolean;
  /**
   * Set to true, to show loading state in button
   */
  loading?: boolean;
  /**
   * Whether the button should take up the full width of container (optional)
   */
  fullWidth?: boolean;
  /**
   * Turn button into link (optional)
   */
  to?: string;
  /**
   * Icon shown before label (optional)
   */
  startIcon?: JSX.Element;
  /**
   * Icon shown after label (optional)
   */
  endIcon?: JSX.Element;
  /**
   * Whether the button is disabled or not (optional)
   */
  isDisabled?: boolean;
  /**
   * Whether the label should be hidden on mobile (optional)
   */
  hideLabelOnMobile?: boolean;
  buttonContentClassName?: string;
  isGold?: boolean;
}

/**
 * Custom button
 */
const Button: FC<ButtonProps> = memo(
  ({
    label,
    buttonType = ButtonTypeEnum.PRIMARY,
    size = ButtonSizeEnum.LARGE,
    submit,
    loading,
    fullWidth,
    to,
    startIcon,
    endIcon,
    isDisabled,
    hideLabelOnMobile,
    className,
    buttonContentClassName,
    isGold,
    ...rest
  }) => {
    const location = useLocation();
    const button = (
      <button
        type={submit ? "submit" : "button"}
        className={cx(styles.button, className, {
          [styles.fullWidth]: fullWidth,
          [styles.loading]: loading,
          [styles.isDisabled]: isDisabled,
          [styles.hideLabelOnMobile]: hideLabelOnMobile,
          [styles.isGold]: isGold,
        })}
        {...rest}
      >
        <div
          className={cx(
            styles.buttonContent,
            buttonContentClassName,
            styles[buttonType],
            styles[size]
          )}
          tabIndex={-1}
        >
          {startIcon && startIcon}
          {/* TODO: implement correct loading state when design is done */}

          {!!label && (
            <Title
              level={3}
              className={cx(styles.label, {
                [styles.startIconLabel]: startIcon,
                [styles.endIconLabel]: endIcon,
              })}
            >
              {loading ? "loading" : label}
            </Title>
          )}
          {endIcon && endIcon}
        </div>
      </button>
    );

    // Check if link
    if (to) {
      // Check if external
      return isURL(to) ? (
        <ExternalLink
          url={to}
          className={cx(styles.link, { [styles.linkDisabled]: isDisabled })}
        >
          {button}
        </ExternalLink>
      ) : (
        // Check if internal
        <Link
          className={cx(styles.link, { [styles.linkDisabled]: isDisabled })}
          to={{
            pathname: to,
            state: { prevPath: location.pathname },
          }}
        >
          {button}
        </Link>
      );
    }

    return button;
  }
);

export default Button;
