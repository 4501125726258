import React from "react";
import styles from "./BasketTitle.module.scss";
import { IconArrowLeft } from "@app/components/atoms/Icon/Icon";
import { useDispatch } from "react-redux";
import { closeBasket } from "@app/features/basket/redux/basket.slice";

const BasketTitle = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.titleWrapper}>
      <div className={styles.controls} onClick={() => dispatch(closeBasket())}>
        <IconArrowLeft className={styles.label} />
        <span>Continue shopping</span>
      </div>
      <div>Your Bag</div>
    </div>
  );
};

export default BasketTitle;
