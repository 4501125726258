import React, { memo } from "react";

import Image from "@app/components/atoms/Image/Image";
import { Heading } from "@app/components/atoms/Typography/Typography";
import Section from "@app/components/layouts/Section/Section";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getImageUrl } from "@app/helpers/image.helpers";

import styles from "./ExperienceHero.module.scss";

export interface ExperienceHeroProps {
  name: string;
  image: string;
}

const ExperienceHero = memo(({ image, name }: ExperienceHeroProps) => {
  return (
    <Section theme={ThemeEnum.KHAKI}>
      <Image
        url={getImageUrl(image, IMAGE_TYPES.BANNER)}
        alt={name}
        className={styles.imageWrapper}
      >
        <div className={styles.content}>
          <Heading level={1} className={styles.heading}>
            {name}
          </Heading>
        </div>
      </Image>
    </Section>
  );
});

export default ExperienceHero;
