import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@app/redux/root-reducer";

const selectSelf = (state: RootState) => state.basket;
export const isBasketOpenSelector = createSelector(
  selectSelf,
  state => state.isDisplayed
);

export const basketItemsSelector = createSelector(
  selectSelf,
  state => state.basket || {}
);

export const basketItemsListSelector = createSelector(
  selectSelf,
  state => state.basket?.lineItems || []
);
export const basketItemsLisWithoutPromoSelector = createSelector(
  selectSelf,
  state =>
    state.basket?.lineItems?.filter(item => item.type !== "promotion") || []
);

export const basketQuantitySelector = createSelector(
  basketItemsLisWithoutPromoSelector,
  state => state?.length || 0
);
export const totalAmountSelector = createSelector(
  selectSelf,
  state => state.basket?.price?.totalPrice || 0
);

export const basketPromotionLineItem = createSelector(
  selectSelf,
  state =>
    state.basket?.lineItems?.find(item => item.type === "promotion") ?? null
);

export const basketLoadingSelector = createSelector(
  selectSelf,
  state => state.loading
);
