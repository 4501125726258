import React, { memo } from "react";

import cx from "classnames";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { RootState } from "@app/redux/root-reducer";
import { NavigationItemType } from "@app/types/navigation.types";

import styles from "./NavigationItems.module.scss";
import NavigationItem from "./components/NavigationItem/NavigationItem";
import stylesi from "./components/NavigationItem/NavigationItem.module.scss";
import substyles from "./components/SubNavigation/SubNavigation.module.scss";

export interface NavigationItemsProps {
  navItems: NavigationItemType[];
}

const NavigationItems = memo(({ navItems }: NavigationItemsProps) => {
  const location = useLocation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const pathMatches = (link: string, activeFor?: string[]) => {
    return (
      link === location.pathname ||
      activeFor?.some(otherLink => location.pathname.includes(otherLink))
    );
  };

  const isActiveMenuItem = (menuItem: NavigationItemType) => {
    return (
      pathMatches(menuItem.path, menuItem.activeFor) ??
      menuItem.subMenu?.some(item => pathMatches(item.path, item.activeFor))
    );
  };

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return (
    <nav className={styles.navWrapper}>
      <ul className={styles.navLinks}>
        {navItems.length > 0 &&
          navItems.map(item => (
            <NavigationItem
              id={item.id}
              key={item.id}
              label={item.label}
              path={item.path}
              subMenu={item.subMenu}
              isActive={isActiveMenuItem(item)}
            />
          ))}
        <li className={cx(stylesi.navItem)}>
          <a
            className={cx(stylesi.navItemLink)}
            href="https://kaylolife.com/private-sessions"
            rel="noopener noreferrer"
          >
            {matches.tabletLandscape ? (
              <Title level={3} className={stylesi.linkText} isMobile>
                Private Sessions
              </Title>
            ) : (
              <Subtitle level={3} className={stylesi.linkText}>
                Privates
              </Subtitle>
            )}
          </a>
        </li>

        <ul className={substyles.wrapper} style={{ padding: "0px" }}>
          <li className={cx(stylesi.navItem)}>
            <Link
              className={cx(stylesi.navItemLink, { [stylesi.active]: false })}
              to={isAuthenticated ? "/dashboard" : "/"}
            >
              {matches.tabletLandscape ? (
                <Title level={3} className={styles.linkText} isMobile>
                  {isAuthenticated ? "For you" : "Welcome"}
                </Title>
              ) : (
                <Subtitle level={3} className={styles.linkText}>
                  {isAuthenticated ? "For you" : "Welcome"}
                </Subtitle>
              )}
            </Link>
          </li>
          <li className={cx(stylesi.navItem)}>
            <Link
              className={cx(stylesi.navItemLink, { [stylesi.active]: false })}
              to="/experiences"
            >
              {matches.tabletLandscape ? (
                <Title level={3} className={styles.linkText} isMobile>
                  Experiences
                </Title>
              ) : (
                <Subtitle level={3} className={styles.linkText}>
                  Experiences
                </Subtitle>
              )}
            </Link>
          </li>
          <li className={cx(stylesi.navItem)}>
            <Link
              className={cx(stylesi.navItemLink, { [stylesi.active]: false })}
              to="/practitioners"
            >
              {matches.tabletLandscape ? (
                <Title level={3} className={styles.linkText} isMobile>
                  Practitioners
                </Title>
              ) : (
                <Subtitle level={3} className={styles.linkText}>
                  Practitioners
                </Subtitle>
              )}
            </Link>
          </li>
          <li className={cx(stylesi.navItem)}>
            <Link
              className={cx(stylesi.navItemLink, { [stylesi.active]: false })}
              to="/schedule"
            >
              {matches.tabletLandscape ? (
                <Title level={3} className={styles.linkText} isMobile>
                  Schedule
                </Title>
              ) : (
                <Subtitle level={3} className={styles.linkText}>
                  Schedule
                </Subtitle>
              )}
            </Link>
          </li>
        </ul>
      </ul>
    </nav>
  );
});

export default NavigationItems;
