import CheckoutRegisterPage from "@app/features/payment/pages/CheckoutRegisterPage";
import { RouteItemDef } from "@app/types/route.types";
import CheckoutSignInPage from "@app/features/payment/pages/CheckoutSignInPage";
import CheckoutPaymentPage from "@app/features/payment/pages/CheckoutPaymentPage";
import CheckoutAddCardPage from "@app/features/payment/pages/CheckoutAddCardPage";
import CheckoutOrderConfirmation from "@app/features/payment/pages/CheckoutOrderConfirmation";
import CheckoutNewPayment from "@app/features/payment/pages/CheckoutNewPayment";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";
import CheckoutRequestResetPasswordPage from "@app/features/payment/pages/CheckoutRequestResetPasswordPage";
import CheckoutResetInfoPage from "@app/features/payment/pages/CheckoutResetInfoPage";
import CheckoutError from "@app/features/payment/pages/CheckoutError";

const CHECKOUT_REGISTER_ROUTE: RouteItemDef = {
  id: "checkout",
  path: PaymentPathEnum.CHECKOUT_REGISTER,
  component: CheckoutRegisterPage,
};
const CHECKOUT_SIGN_IN_ROUTE: RouteItemDef = {
  id: "checkout",
  path: PaymentPathEnum.CHECKOUT_SIGN_IN,
  component: CheckoutSignInPage,
};
const CHECKOUT_PAYMENT_ROUTE: RouteItemDef = {
  id: "checkout",
  path: PaymentPathEnum.CHECKOUT_PAYMENT,
  component: CheckoutPaymentPage,
};
const CHECKOUT_ADD_CARD_ROUTE: RouteItemDef = {
  id: "checkout",
  path: PaymentPathEnum.CHECKOUT_ADD_CARD,
  component: CheckoutAddCardPage,
};
const CHECKOUT_ORDER_CONFIRMATION: RouteItemDef = {
  id: "checkout",
  path: PaymentPathEnum.CHECKOUT_ORDER_CONFIRMATION,
  component: CheckoutOrderConfirmation,
};
const CHECKOUT_NEW_PAYMENT: RouteItemDef = {
  id: "checkout-new-payment",
  path: PaymentPathEnum.CHECKOUT_NEW_PAYMENT,
  component: CheckoutNewPayment,
};
const CHECKOUT_REQUEST_RESET_PASSWORD: RouteItemDef = {
  id: "checkout-reset",
  path: PaymentPathEnum.CHECKOUT_REQUEST_PASSWORD,
  component: CheckoutRequestResetPasswordPage,
};
const CHECKOUT_RESET_INFO: RouteItemDef = {
  id: "checkout-reset-info",
  path: PaymentPathEnum.CHECKOUT_RESET_INFO,
  component: CheckoutResetInfoPage,
};
const CHECKOUT_ERROR: RouteItemDef = {
  id: "checkout-reset-info",
  path: PaymentPathEnum.CHECKOUT_ERROR,
  component: CheckoutError,
};

export const CHECKOUT_ROUTE = [
  CHECKOUT_REGISTER_ROUTE,
  CHECKOUT_SIGN_IN_ROUTE,
  CHECKOUT_PAYMENT_ROUTE,
  CHECKOUT_ADD_CARD_ROUTE,
  CHECKOUT_ORDER_CONFIRMATION,
  CHECKOUT_NEW_PAYMENT,
  CHECKOUT_REQUEST_RESET_PASSWORD,
  CHECKOUT_RESET_INFO,
  CHECKOUT_ERROR,
];
