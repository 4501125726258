import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import errorimage from "@app/assets/images/img_payment_failed.png";
import Button from "@app/components/atoms/Button/Button";
import { basketItemsLisWithoutPromoSelector } from "@app/features/basket/redux/BasketSelectors";
import {
  bulkAddToCart,
  clearBasket,
  clearCart,
} from "@app/features/basket/redux/basket.slice";
import { discountCodeSelector } from "@app/features/basket/redux/discount.selectors";

import styles from "./CheckoutError.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CheckoutError = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lineItems = useSelector(basketItemsLisWithoutPromoSelector);
  const discountCode = useSelector(discountCodeSelector);
  let localErr = "";
  let localShowButton = false;
  let localRedirect = "";
  let fresh = true;

  try {
    const {
      location: {
        state: { err, showButton, redirect },
      },
    } = props;
    localErr = err;
    localShowButton = showButton;
    localRedirect = redirect;
    fresh = false;
  } catch {
    localErr =
      "Your payment authentication failed. Please return to the home page and try again. If the issue persists please contact us!";
    localShowButton = false;
    localRedirect = "";
  }
  const handleButtonClick = () => {
    Promise.resolve(
      dispatch(bulkAddToCart({ products: lineItems, discountCode }))
    ).then(() => {
      dispatch(clearBasket());
      history.go(-1);
    });
  };

  const handle3DSecure = () => {
    const link = document.createElement("a");
    link.href = localRedirect;
    link.click();
  };

  useEffect(() => {
    dispatch(clearCart());
  }, [dispatch]);
  return (
    <div>
      <div className={styles.wrapperGreen}>
        <div className={styles.right}>
          {!localShowButton && (
            <img src={errorimage} alt="error" className={styles.error} />
          )}
          {localShowButton && <div className={styles.error} />}
          {!localShowButton && (
            <p className={styles.title}>SORRY, SOMETHING&apos;S NOT RIGHT</p>
          )}
          {localShowButton && (
            <p className={styles.title}>
              ONE MORE STEP, YOUR BANK NEEDS YOU TO APPROVE YOUR PAYMENT
            </p>
          )}
          <p className={styles.subtitle}>
            <br />
            {localErr}
          </p>
          {!localShowButton && !fresh && (
            <Button
              fullWidth
              onClick={handleButtonClick}
              label="Check payment details"
              className={styles.button}
            />
          )}
          {!localShowButton && fresh && (
            <Button
              fullWidth
              to="/"
              label="Return to home page"
              className={styles.button}
            />
          )}
          {localShowButton && (
            <Button
              fullWidth
              onClick={handle3DSecure}
              label="Approve payment"
              className={styles.button}
            />
          )}
          <p className={styles.contact}>
            Having trouble? Please contact us on +44 330 808 6792 or email{" "}
            <a href="mailto:support@kaylolife.com" className={styles.link}>
              support@kaylolife.com
            </a>{" "}
            and we&apos;ll be happy to help.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutError;
