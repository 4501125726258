import React from "react";

import styles from "./Socials.module.scss";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";

interface SocialsProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  type: "facebook" | "google";
  label: string;
}

const Socials: React.FC<SocialsProps> = ({ type, label, ...rest }) => {
  if (type === "google")
    return (
      <button {...rest} className={styles.google}>
        <FcGoogle className={styles.icon} />
        <span className={styles.label}>{label}</span>
      </button>
    );
  return (
    <button {...rest} className={styles.facebook}>
      <FaFacebook className={styles.icon} />
      <span className={styles.label}>Sign up with Facebook</span>
    </button>
  );
};

export default Socials;
