import React, {memo} from "react";

import cx from "classnames";
import {Link} from "react-router-dom";

import ConditionalWrapper from "../ConditionalWrapper/ConditionalWrapper";
import {Subtitle} from "../Typography/Typography";
import styles from "./Tags.module.scss";

export type TagColorsDef =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13;

export interface TagProps {
  color?: TagColorsDef;
  colorHash?: string;
  label: string;
  link?: string;
}

export interface TagsProps extends TagProps {
  className?: string;
}

const Tags = memo(({label, color = 0, colorHash = undefined, className, link}: TagsProps) => {
  return (
    <ConditionalWrapper
      condition={!!link}
      wrapper={children => <Link className={styles.link} to={link}>{children}</Link>}
    >
      <div className={styles.linkWrapper}>
        <span
          style={{backgroundColor: colorHash || 'none'}}
          className={cx(styles.tag, className, {
            [styles.pink]: color === 0,
            [styles.orange]: color === 1,
            [styles.green]: color === 2,
            [styles.orangeYang]: color === 3,
            [styles.yellow]: color === 4,
            [styles.blue]: color === 5,
            [styles.violet]: color === 6,
            [styles.blueYang]: color === 7,
            [styles.red]: color === 8,
            [styles.pinkYin]: color === 9,
            [styles.blueYin]: color === 10,
            [styles.greenYin]: color === 11,
            [styles.darkGreen]: color === 12,
            [styles.yellowYin]: color === 13,
          })}
        ></span>
        <Subtitle level={3} className={styles.tagLabel}>
          {label}
        </Subtitle>
      </div>
    </ConditionalWrapper>
  );
});

export default Tags;
