import React from "react";
import styels from "./CheckoutTitle.module.scss";
interface CheckoutTitleProps {
  title: string;
}
const CheckoutTitle: React.FC<CheckoutTitleProps> = ({ title }) => {
  return <p className={styels.title}>{title}</p>;
};

export default CheckoutTitle;
