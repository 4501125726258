export enum AuthEndpointsEnum {
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER_SEEKER = "register",
  PROFILE = "profile",
  REQUEST_RESET_PASSWORD = "init-reset-password",
  RESET_PASSWORD = "reset-password",
  REFRESH_TOKEN = "refresh-token",
  REDIRECT_FACEBOOK = "/redirect/facebook",
  REDIRECT_GOOGLE = "/redirect/google",
  RESEND_VERIFICATION = "/email/resend",
}
