import React from "react";
import { Field } from "formik";
import styles from "./radioCard.module.scss";
import amex from "@app/assets/images/amex.png";
import master from "@app/assets/images/master.png";
import visa from "@app/assets/images/visa.png";

interface CardItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  card: any;
}
const CardItem: React.FC<CardItemProps> = ({ card }) => {
  return (
    <div className={styles.radioItem}>
      <div>
        <Field
          type="radio"
          name="card"
          value={card.cardNumber}
          id={card.cardNumber}
          className={styles.input}
        />
      </div>
      <label className={styles.label} htmlFor={card.cardNumber}>
        <div className={styles.cardProvider}>
          <img src={master} alt="" className={styles.cardImage} />
        </div>
      </label>
      <div className={styles.cardInfo}>
        <p className={styles.cardNumber}>
          **** {card.cardNumber.substring(card.cardNumber.length - 4)}
        </p>
        <p className={styles.expiresAt}>{card.expiresAt}</p>
      </div>
    </div>
  );
};

export default CardItem;
