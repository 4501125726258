import React, { useEffect } from "react";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import { useRequest } from "@app/hooks/useRequest";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import Loading from "@app/components/atoms/Loading/Loading";
import styles from "./HeroBanner.module.scss";
import { WorkshopSummaryInterface } from "@app/features/workshops/types/summary.interface";
import WorkshopSummary from "@app/features/workshops/components/WorkshopSummary";
import WorkshopMedia from "@app/features/workshops/components/WorkshopMedia";
import cx from "classnames";

interface HeroBannerTypes {
  heroData: WorkshopSummaryInterface;
  loading: boolean;
}

const HeroBanner: React.FC<HeroBannerTypes> = ({ heroData, loading }) => {
  return (
    <Section theme={ThemeEnum.KHAKI}>
      <Loading isLoading={loading}>
        <ContentWrapper>
          <WorkshopSummary info={heroData} />
          <div
            className={cx(styles.wrapper, {
              [styles.fullWidth]: heroData?.banner?.width === 100,
              [styles.reverse]: heroData?.banner?.text_position === "left",
            })}
          >
            <WorkshopMedia
              media={heroData.banner}
              className={cx(styles.media, {
                [styles.wide]: heroData?.banner?.width === 100,
              })}
            />
            <div
              className={cx(styles.description, {
                [styles.wide]: heroData?.banner?.width === 100,
              })}
            >
              <p className={styles.title}>{heroData.title}</p>
              <p className={styles.subtitle}>Is this journey for you?</p>
              <p
                className={styles.subtitle_description}
                dangerouslySetInnerHTML={{
                  __html: heroData.suitable_description,
                }}
              />
            </div>
          </div>
        </ContentWrapper>
      </Loading>
    </Section>
  );
};

export default React.memo(HeroBanner);
