import React, { useEffect } from "react";

import cx from "classnames";
import { Form, Formik, FormikHelpers } from "formik";

import { IconArrowLeft } from "@app/components/atoms/Icon/Icon";
import Title from "@app/features/auth/screens/CreateAccount/components/Title";

import { Col, Container, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import styles from "@app/features/auth/screens/SignInPage/SignInPage.module.scss";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";
import { login, resetVerify } from "@app/features/auth/redux/auth.slice";
import AlreadyRegistered from "@app/features/auth/screens/CreateAccount/components/AlreadyRegistered";
import Header from "@app/features/auth/screens/CreateAccount/components/Header";

import signUpWithSocials from "@app/features/auth/helpers/socials.helpers";
import { RootState } from "@app/redux/root-reducer";
import { LoginDataDef } from "@app/features/auth/types/auth-login.types";
import SubmitButton from "@app/features/auth/screens/CreateAccount/components/SubmitButton";
import Socials from "@app/features/auth/screens/CreateAccount/components/Socials";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";
import Unverified from "@app/features/auth/screens/SignInPage/components/Unverified";
import { ENV } from "@app/constants/config";
import { bulkAddToCart } from "@app/features/basket/redux/basket.slice";
import { basketItemsLisWithoutPromoSelector } from "@app/features/basket/redux/BasketSelectors";
import { discountCodeSelector } from "@app/features/basket/redux/discount.selectors";
import { BsQuestionCircle } from "react-icons/bs";

interface SignInFormInterface {
  pathToRegister: AuthPathsEnum | PaymentPathEnum;
  pathOnSuccess: AuthPathsEnum | PaymentPathEnum;
  redirect: "checkout" | "dashboard";
  title: string;
  backArrow?: boolean;
  forgetPasswordLink: AuthPathsEnum | PaymentPathEnum;
}

const SignInForm: React.FC<SignInFormInterface> = ({
  pathToRegister,
  pathOnSuccess,
  title,
  backArrow = true,
  redirect,
  forgetPasswordLink,
}) => {
  const history = useHistory();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { isVerified } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const lineItems = useSelector(basketItemsLisWithoutPromoSelector);
  const discountCode = useSelector(discountCodeSelector);
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Email doesn't match requirements")
      .required("Email is required"),
    password: Yup.string().required("Password confirmation is required"),
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (lineItems.length > 0) {
        Promise.resolve(
          dispatch(bulkAddToCart({ products: lineItems, discountCode }))
        ).finally(() => history.push(pathOnSuccess));
      } else {
        history.push(pathOnSuccess);
      }
    }
  }, [dispatch, history, isAuthenticated, pathOnSuccess]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(resetVerify());
    };
  }, [dispatch]);

  const onSubmit = (
    formValues: LoginDataDef,
    actions: FormikHelpers<LoginDataDef>
  ) => {
    dispatch(
      login(formValues, errors => {
        if (errors) {
          actions.setFieldError("password", errors);
        }
      })
    );
  };

  return (
    <>
      {backArrow && <Header />}
      <Container
        fluid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          className={cx(styles.header, {
            [styles.paddingTop]: backArrow,
          })}
        >
          {backArrow && (
            <IconArrowLeft
              className={styles.back}
              onClick={() => history.go(-1)}
            />
          )}
          <Title>{title}</Title>
        </div>
        {ENV.DISPLAY_SOCIAL_LOGIN && (
          <Row justify="between" nogutter className={styles.row}>
            <Col xs={12} md={6}>
              <div className={styles.first}>
                <Socials
                  type="google"
                  label="Login with Google"
                  onClick={() => {
                    signUpWithSocials("google", redirect);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className={styles.second}>
                <Socials
                  type="facebook"
                  label="Login with Facebook"
                  onClick={() => {
                    signUpWithSocials("facebook", redirect);
                  }}
                />
              </div>
            </Col>
          </Row>
        )}

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form>
              <Row nogutter className={styles.row}>
                <Col>
                  <FormInput
                    type="input"
                    name="email"
                    label="Email"
                    errors={formik.touched.email ? formik.errors.email : ""}
                  />
                  {!isVerified &&
                    formik.touched.email &&
                    !formik.errors.email && (
                      <Unverified email={formik.values.email} />
                    )}
                </Col>
              </Row>
              <Row nogutter className={styles.rowTwo}>
                <Col>
                  <FormInput
                    type="password"
                    name="password"
                    label="Password"
                    secondLabel={<BsQuestionCircle className={styles.label} />}
                    isPassword
                    errors={
                      formik.touched.password ? formik.errors.password : ""
                    }
                  />
                </Col>
              </Row>

              <Row className={styles.button}>
                <Col>
                  <SubmitButton label="Sign In" />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <div className={styles.wrapperForgot}>
          <AlreadyRegistered
            text="Don't have an account?"
            linkText="Register here"
            link={pathToRegister}
            className={styles.registered}
          />
          <p
            className={styles.forgot}
            onClick={() => {
              history.push(forgetPasswordLink);
            }}
          >
            Forgot password?
          </p>
        </div>
      </Container>
    </>
  );
};

export default SignInForm;
