import styles from "./BannerInner.module.scss";

import React from "react";
import { BannerTypes } from "@app/components/atoms/RotatingBanner/types/banner.types";
import Button from "@app/components/atoms/Button/Button";
import cx from "classnames";

interface BannerInnerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  content: BannerTypes;
}

const BannerInner: React.FC<BannerInnerProps> = ({ content }) => {
  return (
    <div
      className={cx(styles.wrapper, `${content.theme}-theme`, {
        [styles.imagery]: content.boxTheme === "imagery",
        [styles.white]: content.boxTheme === "white",
        [styles.khaki]: content.boxTheme === "khaki",
        [styles.orange]: content.boxTheme === "pink-on-orange",
        [styles.green]: content.boxTheme === "violet-on-green",
        [styles.yellow]: content.boxTheme === "violet-on-yellow",
      })}
    >
      {content?.subtitle && (
        <p className={styles.subtitle}>{content.subtitle}</p>
      )}
      {content?.title && <p className={styles.title}>{content.title}</p>}
      {content?.description && (
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: content.description }}
        />
      )}
      {content?.ctaLabel && (
        <Button
          type="button"
          label={content.ctaLabel}
          className={styles.button}
          to={content.ctaLink}
        />
      )}
    </div>
  );
};

export default BannerInner;
