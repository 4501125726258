import React from "react";

import { addMinutes, format, isToday } from "date-fns";
import _groupBy from "lodash/groupBy";
import { Col, Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { generatePath, Link } from "react-router-dom";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "@app/components/atoms/Button/Button";
import {
  Caption,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ClassTypesEnum } from "@app/constants/classes";
import { TimeFormats } from "@app/constants/date.constants";
import { addToCart } from "@app/features/basket/redux/basket.slice";
import { ClassesDef } from "@app/features/classes/classes";
import { EcommercePathsEnum } from "@app/features/ecommerce/ecommerce";
import { getPractitionerName } from "@app/helpers/util.helpers";
import { useAppDispatch } from "@app/redux/store";

import styles from "./ClassesSchedule.module.scss";

export interface ClassesScheduleProps {
  sessions: ClassesDef[];
}

const LIMITED_SPACE_THRESHOLD = 5;
const HEADER_DATE_FORMAT = "EEE dd MMM yyyy";

const ClassesSchedule = ({ sessions }: ClassesScheduleProps) => {
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const showPricesValue = t("default.showPrices").toLowerCase();
  const showPrices = showPricesValue === 'true' ||
    showPricesValue === 'on' ||
    showPricesValue === '1' ||
    showPricesValue === 'yes';

  const getTimeLabel = (session: ClassesDef) => {
    const startAt = new Date(
      session.slots && session.slots.length > 0
        ? session.slots[0].start
        : session.startAt
    );
    if (session.type === "workshop") {
      return `${format(startAt, TimeFormats.LONG)}`;
    }
    const endAt = addMinutes(startAt, session.duration as number);
    try {
      return `${format(startAt, TimeFormats.LONG)} - ${format(
        endAt,
        TimeFormats.LONG
      )}`;
    } catch (e) {
      return null;
    }
  };

  const getFirstExperienceName = (session: ClassesDef) => {
    if (session.type === "workshop") {
      return "Workshop";
    }
    return session.experiences?.[0]?.name;
  };

  const getSessionLink = (session: ClassesDef) => {
    if (session.type === "workshop") {
      return generatePath(EcommercePathsEnum.WORKSHOP_DETAILS, {
        id: session.id,
        slug: session.slug !== "" ? session.slug : undefined,
      });
    }
    return generatePath(EcommercePathsEnum.PRODUCT_DETAILS, {
      id: session.id,
      slug: session.slug !== "" ? session.slug : undefined,
    });
  };

  const getCtaButton = (session: ClassesDef) => {
    const sessionLink = getSessionLink(session);

    if (session.booked) {
      return (
        <Button
          to={sessionLink}
          buttonType={ButtonTypeEnum.SECONDARY}
          label={t("schedule.booked")}
          size={ButtonSizeEnum.MEDIUM}
        />
      );
    }

    if (
      session.attendeesNo &&
      session.maxAttendees &&
      session.attendeesNo >= session.maxAttendees
    ) {
      return (
        <Button
          to={sessionLink}
          buttonType={ButtonTypeEnum.SECONDARY}
          label={t("schedule.soldOut")}
          size={ButtonSizeEnum.MEDIUM}
        />
      );
    }

    if (
      session.maxAttendees &&
      session.maxAttendees <= LIMITED_SPACE_THRESHOLD &&
      session.type !== ClassTypesEnum.PRIVATE_SESSION
    ) {
      return (
        <Button
          to={sessionLink}
          buttonType={ButtonTypeEnum.PRIMARY}
          label={t("schedule.limitedSpaces")}
          size={ButtonSizeEnum.MEDIUM}
        />
      );
    }

    return (
      <Button
        to={sessionLink}
        buttonType={ButtonTypeEnum.PRIMARY}
        label={t("schedule.bookNow")}
        size={ButtonSizeEnum.MEDIUM}
        // onClick={() => dispatch(addToCart(session))}
      />
    );
  };

  const getGroupedSessions = () => {
    return _groupBy(sessions, session => {
      const sessionDate = new Date(
        session.slots && session.slots.length > 0
          ? session.slots[0].start
          : session.startAt
      );
      const formattedDate = format(sessionDate, HEADER_DATE_FORMAT);
      return isToday(sessionDate)
        ? t("schedule.todaysDate", { date: formattedDate })
        : formattedDate;
    });
  };

  return (
    <>
      {Object.keys(getGroupedSessions()).map(group => (
        <div className={styles.group} key={group}>
          <Title level={2} isGold className={styles.groupTitle}>
            {group}
          </Title>
          {matches.tabletLandscape ? (
            <Container className={styles.sessions} fluid>
              <Row className={styles.row}>
                <Col md={2.5} lg={2}>
                  <Title level={3}>{t("schedule.timeTitle")}</Title>
                </Col>
                <Col md={2} lg={3}>
                  <Title level={3}>{t("schedule.sessionTitle")}</Title>
                </Col>
                <Col md={2}>
                  <Title level={3}>{t("default.practitioner")}</Title>
                </Col>
                <Col md={2} lg={2}>
                  <Title level={3}>{t("default.experience")}</Title>
                </Col>
                {showPrices && (
                  <Col md={2} lg={2}>
                    <Title level={3}>{t("default.priceLabel")}</Title>
                  </Col>
                )}
                <Col md={2.5} lg={2} />
              </Row>
              {getGroupedSessions()[group].map(session => (
                <Row key={session.id} className={styles.row}>
                  <Col md={2.5} lg={2}>
                    <Subtitle level={3}>{getTimeLabel(session)}</Subtitle>
                  </Col>
                  <Col md={2} lg={3}>
                    <Link to={getSessionLink(session)}>
                      <Subtitle level={3} className={styles.name}>
                        {session.name}
                      </Subtitle>
                    </Link>
                  </Col>
                  <Col md={2}>
                    <Subtitle level={3}>
                      {getPractitionerName(session)}
                    </Subtitle>
                  </Col>
                  <Col md={1.5} lg={2}>
                    <Subtitle level={3}>
                      {getFirstExperienceName(session)}
                    </Subtitle>
                  </Col>
                  {showPrices && (
                    <Col md={1.5} lg={1} >
                      <Subtitle level={3}>
                        {session.price === 0 ? t('default.priceFree') : t("product.currency") + session.price}
                      </Subtitle>
                    </Col>
                  )}
                  <Col md={2.5} lg={2} className={styles.action}>
                    {getCtaButton(session)}
                  </Col>
                </Row>
              ))}
            </Container>
          ) : (
            <div>
              {getGroupedSessions()[group].map(session => (
                <div className={styles.sessionCardMobile} key={session.id}>
                  <Row>
                    <Col xs={6}>
                      <Title level={3}>{getTimeLabel(session)}</Title>
                      <Link to={getSessionLink(session)}>
                        <Subtitle level={3} className={styles.name}>
                          {session.name}
                        </Subtitle>
                      </Link>
                      <Caption className={styles.caption}>
                        {getPractitionerName(session)}
                      </Caption>
                      <Caption className={styles.caption}>
                        {getFirstExperienceName(session)}
                      </Caption>
                    </Col>
                    <Col xs={6} className={styles.col2}>
                      {getCtaButton(session)}
                      <Caption>{session.type}</Caption>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ClassesSchedule;
