import React from "react";

import { useMedia } from "react-media";

import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { LineItem } from "@app/features/basket/Types/backend-basket.types";
import Image from "@app/features/basket/components/Image";
import { formatDate } from "@app/helpers/formatDate";

import styles from "./CheckoutConfirmationItem.module.scss";

interface CheckoutConfirmationItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  card: LineItem;
}

const CheckoutConfirmationItem: React.FC<CheckoutConfirmationItemProps> = ({
  card,
}) => {
  const { mobile, mobileLandscape } = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });
  const getCoverImage = () => {
    if (card.type.includes("workshop")) {
      return <Image path={card.cover?.url} round />;
    }
    return <Image path={card.cover.thumbnails[1]?.url} round />;
  };
  const getDateInfo = () => {
    if (card.type.includes("workshop")) {
      const children = card.children.map(item => item?.payload?.netzp_event?.from).filter(item => !!(item));
      const childDate = children.sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
      })[0]

      const date = formatDate(
        childDate,
        "EEEE d LLL 'at' h:mmaaa"
      );
      return `First session: ${date}`;
    }
    const date = formatDate(
      card?.payload?.netzp_event?.from,
      "EEEE d LLL 'at' h:mmaaa"
    );
    const duration = `(${card.payload.duration} min)`;

    return `${date} ${duration}`;
  };
  return (
    <div className={styles.card}>
      {!mobile && !mobileLandscape && (
        <div className={styles.image}>{getCoverImage()}</div>
      )}
      <div className={styles.info}>
        <p className={styles.title}>{card.label}</p>
        {card?.payload?.manufacturer?.firstName && (
          <p className={styles.author}>
            With {card.payload.manufacturer.firstName}{" "}
            {card.payload.manufacturer.lastName}
          </p>
        )}
        <p className={styles.duration}>{getDateInfo()}</p>
      </div>
    </div>
  );
};

export default CheckoutConfirmationItem;
