import React, { useEffect, useState } from "react";

import cx from "classnames";
import { Link } from "react-router-dom";

import { PRACTITIONER_PATH } from "@app/features/practitioners/practitioners";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import { WorkshopPractitionersInterface } from "@app/features/workshops/types/practitioners.iterface";
import { useRequest } from "@app/hooks/useRequest";

import styles from "./WorkshopPractitionersList.module.scss";

interface WorkshopPractitionersListTypes {
  sessionId: string;
}

const WorkshopPractitionersList: React.FC<WorkshopPractitionersListTypes> = ({
  sessionId,
}) => {
  const { data, loading, fetchData } = useRequest(
    `${WorkshopEndpoints.SUMMARY}/${sessionId}/practitioners`
  );
  const practitionersData: WorkshopPractitionersInterface[] = data as never;

  const [display, setDisplay] = useState("none");
  const handleMouseOver = (state: boolean) => {
    if (state) {
      setDisplay("block");
    } else {
      setDisplay("none");
    }
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getImage = (
    practitioner: WorkshopPractitionersInterface,
    idx?: number
  ) => {
    return (
      <img
        src={practitioner.image}
        className={cx(styles.icon, styles[`item-${idx}`])}
      />
    );
  };

  return (
    <div className={styles.container}>
      <span className={styles.subtitle}>Guided by</span>
      <div
        className={styles.icons}
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
      >
        {practitionersData.map((practitioner, idx) => {
          return (
            <Link to={`${PRACTITIONER_PATH}/${practitioner.id}`} key={idx} target="_blank" rel="noopener noreferrer">
              {getImage(practitioner, idx)}
            </Link>
          );
        })}
      </div>
      <div
        className={cx(styles.dropdown, {
          [styles.dropdownVisible]: display === "block",
          [styles.dropdownLeft]: practitionersData.length > 3,
          [styles.dropdownRight]: practitionersData.length <= 3,
        })}
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
      >
        <ul className={styles.menuList}>
          {practitionersData.map((practitioner, idx) => {
            return (
              <Link
                to={`${PRACTITIONER_PATH}/${practitioner.id}`}
                className={styles.link}
                key={idx}
                target="_blank" rel="noopener noreferrer"
              >
                <li className={styles.listItem}>
                  {practitioner.image && getImage(practitioner)}
                  <span className={styles.listItemText}>
                    {practitioner.pseudonym}
                  </span>
                </li>
              </Link>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default WorkshopPractitionersList;
