import { combineReducers } from "@reduxjs/toolkit";

import { articlesReducer } from "@app/features/articles/articles";
import { authReducer } from "@app/features/auth/auth";
import basketReducer from "@app/features/basket/redux/basket.slice";
import discountReducer from "@app/features/basket/redux/discount.slice";
import {
  paymentReducer,
  productDetailReducer,
} from "@app/features/ecommerce/ecommerce";
import { experiencesReducer } from "@app/features/experiences/experiences";
import { practitionersReducer } from "@app/features/practitioners/practitioners";
import { searchReducer } from "@app/features/search/search";
import {
  sessionClassReducer,
  sessionTokenReducer,
} from "@app/features/session/session";
import { classesReducer } from "@app/features/users/users";
import { videoReducer, videoSettingsReducer } from "@app/features/video/video";

const rootReducer = combineReducers({
  auth: authReducer,
  sessionClass: sessionClassReducer,
  sessionToken: sessionTokenReducer,
  video: videoReducer,
  videoSettings: videoSettingsReducer,
  classes: classesReducer,
  practitioners: practitionersReducer,
  experiences: experiencesReducer,
  search: searchReducer,
  productDetail: productDetailReducer,
  articles: articlesReducer,
  payment: paymentReducer,
  basket: basketReducer,
  discount: discountReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
