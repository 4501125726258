import { RouteItemDef } from "@app/types/route.types";

import { EcommercePathsEnum } from "../constants/ecommerce.path";
import ProductDetailsScreen from "../screens/ProductDetailsScreen/ProductDetailsScreen";
import WorkshopDetailsScreen from "@app/features/workshops/WorkshopDetailsScreen";

const PRODUCT_DETAILS_SCREEN: RouteItemDef = {
  id: "productDetails",
  path: EcommercePathsEnum.PRODUCT_DETAILS,
  component: ProductDetailsScreen,
};

const WORKSHOP_DETAILS_SCREEN: RouteItemDef = {
  id: "workshopDetails",
  path: EcommercePathsEnum.WORKSHOP_DETAILS,
  component: WorkshopDetailsScreen,
};

export const PRODUCT_DETAILS_ROUTES = [
  PRODUCT_DETAILS_SCREEN,
  WORKSHOP_DETAILS_SCREEN,
];
