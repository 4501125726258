import { useCallback, useState } from "react";
import { api } from "@app/api/api";

export const useRequest = (path: string) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(prevState => !prevState);
      const response = await api.get(path, {});
      setData(response?.data);
    } catch (err) {
      setError(err?.response?.data);
    } finally {
      setLoading(prevState => !prevState);
    }
  }, [path]);

  return { data, error, loading, fetchData };
};
