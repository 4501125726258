export function getYouTubeVideoInfo(url: string) {
  let api = "https://www.youtube.com/oembed?url=" + url + "&format=json";
  return fetch(api).then((response) => { return response.json()})
}

export function getYouTubeVideoAspectRatio(url: string): Promise<number> {
  return new Promise((resolve, reject) => {
    getYouTubeVideoInfo(url).then((response) => {
      resolve(response.height / response.width * 100);
    }).catch(() => {
      reject()
    });
  });
}