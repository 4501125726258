import React, { FC, memo } from "react";

import cx from "classnames";

import { GoldProps } from "@app/types/theme.types";

import styles from "./Typography.module.scss";

interface HTagProps {
  /**
   * control which html h tag should be used for heading and titles
   */
  hTagLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

type HTagElement = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export interface HeadingProps extends GoldProps, HTagProps, HTagElement {
  /**
   * Heading level
   */
  level?: 1 | 2 | 3;
}

/**
 * Display: Heading
 */
export const Heading: FC<HeadingProps> = memo(
  ({
    level = 1,
    hTagLevel,
    className,
    isGold,
    isGoldGradient,
    children,
    ...rest
  }) => {
    // tell TS that the element is a valid html element
    const H = `h${hTagLevel ?? level}` as React.ElementType<HeadingProps>;
    return (
      <H
        className={cx(
          styles.heading,
          styles[`heading${level}`],
          { [styles.gold]: isGold },
          { [styles.goldGradient]: isGoldGradient },
          className
        )}
        {...rest}
      >
        {children}
      </H>
    );
  }
);

export interface TitleProps extends GoldProps, HTagProps, HTagElement {
  level?: 1 | 2 | 3;
  /**
   * Giving the ability to allow Mobile font styles for Titles on All devices
   */
  isMobile?: boolean;
}

/**
 * General: Title
 */
export const Title: FC<TitleProps> = memo(
  ({
    level = 1,
    hTagLevel,
    className,
    children,
    isGold,
    isGoldGradient,
    isMobile,
    ...rest
  }) => {
    // tell TS that the element is a valid html element
    const H = `h${hTagLevel ?? level + 3}` as React.ElementType<TitleProps>;
    return (
      <H
        className={cx(
          styles.title,
          styles[`title${level}`],
          { [styles.gold]: isGold },
          { [styles.goldGradient]: isGoldGradient },
          { [styles.isMobile]: isMobile },
          className
        )}
        {...rest}
      >
        {children}
      </H>
    );
  }
);

export interface SubtitleProps extends GoldProps, HTagProps, HTagElement {
  level?: 1 | 2 | 3;
}

/**
 * For Interface: Subtitle
 */
export const Subtitle: FC<SubtitleProps> = memo(
  ({
    level = 1,
    hTagLevel,
    className,
    isGold,
    isGoldGradient,
    children,
    ...rest
  }) => {
    // tell TS that the element is a valid html element
    const H = `h${hTagLevel ?? level + 3}` as React.ElementType<SubtitleProps>;
    return (
      <H
        className={cx(
          styles.subtitle,
          styles[`subtitle${level}`],
          { [styles.gold]: isGold },
          { [styles.goldGradient]: isGoldGradient },
          className
        )}
        {...rest}
      >
        {children}
      </H>
    );
  }
);

export interface CaptionProps extends GoldProps, HTagElement {}

/**
 * For Interface: Caption
 */
export const Caption: FC<CaptionProps> = memo(
  ({ className, isGold, isGoldGradient, children, ...rest }) => {
    return (
      <h6
        className={cx(
          styles.caption,
          { [styles.gold]: isGold },
          { [styles.goldGradient]: isGoldGradient },
          className
        )}
        {...rest}
      >
        {children}
      </h6>
    );
  }
);

export interface ParagraphProps
  extends GoldProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    > {
  level?: 1 | 2;
}

/**
 * For Reading: Paragraph
 */
export const Paragraph: FC<ParagraphProps> = memo(
  ({ level = 1, className, isGold, isGoldGradient, children, ...rest }) => {
    return (
      <p
        className={cx(
          styles.paragraph,
          styles[`paragraph${level}`],
          { [styles.gold]: isGold },
          { [styles.goldGradient]: isGoldGradient },
          className
        )}
        {...rest}
      >
        {children}
      </p>
    );
  }
);

interface HTMLTextProps
  extends GoldProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    > {}

export const HTMLText: React.FC<HTMLTextProps> = ({ children, ...rest }) => {
  return (
    <p
      {...rest}
      className={cx(styles.paragraph)}
      dangerouslySetInnerHTML={{ __html: children as string }}
    />
  );
};
