import React, { useEffect, useState } from "react";
import SubscribeBanner from "@app/features/subscribe/components/SubscribeBanner";
import SubscribeButton from "@app/features/subscribe/components/SubscribeButton";
import { subscribeToNewsletter } from "@app/features/subscribe/api/subscribe.api";
import { SubscribeTypesInterface } from "@app/features/subscribe/types/subscribe.types";
import { FormikHelpers } from "formik";
import cookie from "react-cookies";
import { useLocation } from "react-router-dom";
import SubscriptionMessage from "@app/features/subscribe/components/SubscriptionMessage";
import { useMessageHook } from "@app/features/subscribe/hooks/useMessage.hook";
import { allowedPaths } from "@app/features/subscribe/constants/paths.endpoints";

const Subscription = () => {
  const [isDisplayed, setIsDisplayed] = useState(true);
  const [minimized, setMinimized] = useState(false);
  const NEWSLETTER = "news-subscribed";
  const location = useLocation();
  const handleClick = () => {
    setMinimized(prevState => !prevState);
  };
  const { isMessageDisplayed, displayMessage } = useMessageHook(4000);
  const handleSubscribe = async (
    values: SubscribeTypesInterface,
    actions: FormikHelpers<SubscribeTypesInterface>
  ) => {
    try {
      await subscribeToNewsletter(values);
      setIsDisplayed(prevState => !prevState);
      cookie.save(NEWSLETTER, "true", {});
      actions.resetForm();
      await displayMessage();
    } catch (err) {
      actions.setFieldError("email", err?.response?.data?.error);
      cookie.save(NEWSLETTER, "false", {});
    }
  };

  useEffect(() => {
    if (!allowedPaths.includes(location.pathname)) {
      return setIsDisplayed(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const hasCookie = cookie.load(NEWSLETTER);
    if (hasCookie === "false") {
      return cookie.save(NEWSLETTER, "true", {});
    }
    if (hasCookie === "true") {
      return setIsDisplayed(false);
    }
    return cookie.save(NEWSLETTER, "false", {});
  }, []);

  return (
    <>
      {isDisplayed && (
        <>
          {minimized ? (
            <SubscribeButton onClick={handleClick} />
          ) : (
            <SubscribeBanner
              onClose={handleClick}
              subscribe={handleSubscribe}
            />
          )}
        </>
      )}
      <SubscriptionMessage
        isDisplayed={isMessageDisplayed}
        displayMessage={displayMessage}
      />
    </>
  );
};
export default Subscription;
