import React from "react";
import styles from "@app/features/payment/pages/CheckoutRegisterPage.module.scss";
import ResetPasswordInfoComponent from "@app/features/auth/screens/ResetPasswordPage/components/ResetPasswordInfoComponent";

const CheckoutResetInfoPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left} />
      <div className={styles.right}>
        <ResetPasswordInfoComponent />
      </div>
    </div>
  );
};

export default CheckoutResetInfoPage;
