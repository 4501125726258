import React, { useState } from "react";

import * as Sentry from "@sentry/react";
import { Form, Formik } from "formik";
import { Col, Container, Row } from "react-grid-system";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import Button from "@app/components/atoms/Button/Button";
import Modal from "@app/components/atoms/Modal/Modal";
import Checkbox from "@app/features/auth/components/FormControls/Checkbox";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import MaskedFormInput from "@app/features/auth/components/FormControls/MaskedFormInput";
import { PaymentInterface } from "@app/features/basket/Types/payment.types";
import { basketApi } from "@app/features/basket/api/basket.api";
import PromoCode from "@app/features/basket/components/PromoCode";
import CheckoutTitle from "@app/features/payment/components/CheckoutTitle";
import styles from "@app/features/payment/components/payment-method/CardInfo.module.scss";
import NewAddress from "@app/features/payment/components/payment-method/NewAddress";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

const NewCard = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValues = {
    cardholder: "",
    cardNumber: "",
    expiration: "",
    cvc: "",
    saveCard: false,
    address: "",
    city: "",
    region: "",
    zip: "",
    countryId: "select",
    waiverCheckout: false,
  };

  const validationSchema = Yup.object({
    cardholder: Yup.string().required("Name is required"),
    cardNumber: Yup.string().required("Number is required"),
    expiration: Yup.string().required("Date is required"),
    cvc: Yup.string().trim().required("CVV is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("Town is required"),
    region: Yup.string().required("Region is required"),
    zip: Yup.string().required("Required"),
    countryId: Yup.string().matches(/^((?!select).)*$/, "Required"),
    saveCard: Yup.boolean(),
    waiverCheckout: Yup.boolean().required("You must accept the Kaylo disclaimer before purchasing")
      .oneOf([true], "You must accept the Kaylo disclaimer before purchasing"),
  });

  const onSubmit = async (formValues: any) => {
    const {
      cardholder,
      expiration,
      cvc,
      saveCard,
      countryId,
      zip,
      region,
      city,
      address,
    } = formValues;
    const data: PaymentInterface = {
      card: {
        cardholder,
        cardNumber: formValues.cardNumber.replace(/\s+/g, ""),
        expiration,
        cvc,
        saveCard,
      },
      address: {
        countryId,
        zip,
        region,
        city,
        address,
      },
      paymentMethodId: "5ccec534abc84c03b6eb7c2b0466c027",
      // eslint-disable-next-line @typescript-eslint/camelcase
      zero_payment: false,
    };
    setIsLoading(true);
    await basketApi
      .placeOrder(data)
      .then(() => {
        setIsLoading(false);
        history.push(PaymentPathEnum.CHECKOUT_ORDER_CONFIRMATION);
      })
      .catch(err => {
        Sentry.captureException(err);
        setIsLoading(false);
        const error = err.response.data;
        let show = false;
        let redirectUrl = "";
        let errorMsg =
          "Something went wrong, please try again. If the issue persists please contact support.";
        if (error.error === "3d-secure") {
          errorMsg =
            "Your payment requires additional verification, please click below to approve payment.";
          show = true;
          redirectUrl = error.redirect;
        } else if (error.error === "shopware") {
          errorMsg =
            "Please check your card and address details are correct and try again.";
          // eslint-disable-next-line no-console
          console.log(error.errorCode);
        } else if (error.error === "stripe") {
          errorMsg = error.message;
        }
        history.push(
          {
            pathname: PaymentPathEnum.CHECKOUT_ERROR,
          },
          {
            err: errorMsg,
            showButton: show,
            redirect: redirectUrl,
          }
        );
      });
  };

  return (
    <>
      <Modal visible={isLoading} title="Processing..." headerCenter showSpinner>
        <p className={styles.modalText}>
          Please don&apos;t close or refresh your browser while we are
          processing your payment. We&apos;ll let you know when it&apos;s done.
        </p>
      </Modal>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form className={styles.formWrapper}>
            <Container fluid style={{ padding: "0", margin: 0 }}>
              <CheckoutTitle title="Payment method" />
              <Row nogutter>
                <Col>
                  <FormInput
                    className={styles.item}
                    label="Cardholder Name"
                    name="cardholder"
                    type="input"
                    placeholder="Type here"
                    errors={
                      formik.touched.cardholder ? formik.errors.cardholder : ""
                    }
                  />
                </Col>
              </Row>
              <Row align="end">
                <Col xs={12} md={6}>
                  <MaskedFormInput
                    className={styles.item}
                    label="Card Number"
                    name="cardNumber"
                    type="input"
                    placeholder="1234 5678 1234 5678"
                    mask="#### #### #### ####"
                  />
                </Col>
                <Col xs={8} md={4}>
                  <MaskedFormInput
                    className={styles.item}
                    label="Expiration Date"
                    name="expiration"
                    type="input"
                    placeholder="MM/YY"
                    mask="##/##"
                  />
                </Col>
                <Col xs={4} md={2}>
                  <MaskedFormInput
                    className={styles.item}
                    label="CVV code"
                    name="cvc"
                    type="input"
                    placeholder="XXXX"
                    mask="####"
                  />
                </Col>
              </Row>
               <Col>
                <Row nogutter>
                  <Checkbox
                    name="saveCard"
                    label="Save card details"
                    type="checkbox"
                  />
                </Row>
               </Col>

              <p className={styles.addressTitle}>Billing address</p>
              <NewAddress />
              <PromoCode />
            </Container>
            <div className={styles.controls}>
              <Checkbox
                style={{marginBottom: 22}}
                labelHtml={'I have read the Kaylo <a target="_blank" href="/acceptable-usage-policy" id="">disclaimer</a> and understand that I am responsible for my own safety during this class'}
                name="waiverCheckout"
                type="checkbox"
                errors={
                  formik.touched.waiverCheckout ? formik.errors.waiverCheckout : ''
                }
              />
              <Button
                label="Place order"
                type="submit"
                fullWidth
                loading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewCard;
