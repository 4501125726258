import React, { ReactNode } from "react";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  children: ReactNode;
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
  return (
    <span>
      <span className={styles.tooltip}>
        {children}
        <span className={styles.tooltipText}>{text}</span>
      </span>
    </span>
  );
};

export default Tooltip;
