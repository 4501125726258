import packageJson from "../../package.json";

export const ENV = {
  API_HOST: process.env.REACT_APP_API_HOST ?? "",
  API_EMAIL_HOST: process.env.REACT_APP_API_HOST ?? "",
  VERSION: packageJson.version || "",
  CONTENTFUL_LOCALIZATION: process.env.REACT_APP_CONTENTFUL_LOCALIZATION ?? "",
  CONTENTFUL_FOOTER: process.env.REACT_APP_CONTENTFUL_FOOTER ?? "",
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY ?? "",
  STAGE: process.env.REACT_APP_STAGE ?? "",
  GTM_ID: process.env.REACT_APP_GTM_ID ?? "",
  STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY ?? "",
  DISPLAY_SOCIAL_LOGIN:
    process.env.REACT_APP_DISPLAY_SOCIAL_LOGIN === "true" || false,
};
