import React, { memo } from "react";
import { useSingleEntry } from "@app/features/contentful/contentful";
import EmbedVideo from "@app/components/renderings/EmbedVideo/EmbedVideo";
import { Document } from "@contentful/rich-text-types";

interface EmbedVideoContentfulProps {
  entryID: string;
}

interface EmbedDataModel {
  title: string;
  description: Document,
  link: string;
  provider: string;
  showControls: boolean;
  loop: boolean;
  muted: boolean;
  autostart: boolean;
  videoStyle: string;
}

const EmbedVideoContentful = memo(({entryID}: EmbedVideoContentfulProps) => {
  const { entry: videoData } = useSingleEntry<EmbedDataModel>(entryID);

  if(!videoData) return null;

  const {
    title, description, link, provider,
    showControls, loop, muted, autostart, videoStyle
  } = videoData;
  return <EmbedVideo
    title={title} description={description}
    link={link} provider={provider} show_controls={showControls} loop={loop}
    muted={muted} autostart={autostart} video_style={videoStyle}
  />;
});

export default EmbedVideoContentful;