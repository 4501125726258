import React, { memo, useState } from "react";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import * as yup from "yup";

import { signUp } from "@app/api/newsletter.api";
import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import FormItem from "@app/components/atoms/FormItem/FormItem";
import FormMessage, {
  FormMessageType,
} from "@app/components/atoms/FormMessage/FormMessage";
import Image from "@app/components/atoms/Image/Image";
import {
  Caption,
  Heading,
  Paragraph,
  Subtitle,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getImageUrl } from "@app/helpers/image.helpers";
import { RootState } from "@app/redux/root-reducer";

import styles from "./Newsletter.module.scss";

export interface NewsletterProps {
  title?: string;
  subtitle?: string;
  thankYouText?: string;
  backgroundImage?: string;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email("authInputs.emailErrorInvalid")
    .required("authInputs.emailErrorRequired"),
});

const DEFAULT_BG =
  "https://images.ctfassets.net/7ijo9mjhlhrt/4zEnXBHHMSFq2Gubyf3hum/4ae5cb97923f242d28143303d1a1b444/georgiana-avram-gASJ-p0Mblw-unsplash.jpg";
export const NEWSLETTER_TAG = "newsletter";
const Newsletter = memo(
  ({ title, subtitle, backgroundImage, thankYouText }: NewsletterProps) => {
    const { t } = useTranslation();
    const [subscribed, setSubscribed] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const user = useSelector((state: RootState) => state.auth.user);
    const location = useLocation();
    const handleSubmit = async (values: { email: string }) => {
      setError(null);
      const data = {
        email: values.email,
        referral: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        ...(user && { first_name: user.firstName }),
      };
      try {
        let tag = "Newsletter";
        if (location.pathname === "/retreats") {
          tag = "Retreats";
        }
        if (location.pathname !== "/") {
          tag = location.pathname;
        }
        const response = await signUp({
          ...data,
          tags: tag,
        });
        if (response.status === 200) {
          setSubscribed(true);
        }
      } catch (err) {
        setError(err.response?.data?.detail || t("default.errorMsg"));
      }
    };

    return (
      <Section theme={ThemeEnum.KHAKI} className={styles.image}>
        <Image
          className={styles.wrapper}
          url={getImageUrl(backgroundImage ?? DEFAULT_BG, IMAGE_TYPES.BANNER)}
        >
          <ContentWrapper className={styles.content}>
            <Heading level={3} className={styles.title}>
              {title ?? t("default.newsletterTitle")}
            </Heading>
            <Paragraph level={1}>{subtitle}</Paragraph>
            {subscribed ? (
              <Subtitle level={2} className={styles.thankYouText}>
                {thankYouText ?? t("default.newsletterThankYou")}
              </Subtitle>
            ) : (
              <Formik
                enableReinitialize
                initialValues={{ email: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => (
                  <Form noValidate className={styles.form}>
                    <div className={styles.inputWrapper}>
                      <FormItem
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t("default.emailPlaceholder")}
                        autoComplete="off"
                        className={styles.input}
                      />
                    </div>
                    {error && (
                      <FormMessage
                        type={FormMessageType.ERROR}
                        message={error}
                      />
                    )}
                    <Button
                      label={t("default.signUp")}
                      buttonType={ButtonTypeEnum.PRIMARY}
                      className={styles.button}
                      type="submit"
                      loading={isSubmitting}
                      fullWidth
                    />
                  </Form>
                )}
              </Formik>
            )}
            <Caption className={styles.footerText}>
              {t("newsletter.signUpFooter")}
            </Caption>
            <Caption>
              <Link to={t("authTermsAndConditions.termsUrl")}>
                {t("authTermsAndConditions.termsAndConditionsLink")}
              </Link>{" "}
              &{" "}
              <Link to={t("authTermsAndConditions.privacyUrl")}>
                {t("authTermsAndConditions.privacyPolicyLink")}
              </Link>
            </Caption>
          </ContentWrapper>
        </Image>
      </Section>
    );
  }
);

export default Newsletter;
