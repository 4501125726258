import React, { useEffect, useState } from "react";

import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loading from "@app/components/atoms/Loading/Loading";
import {
  Heading,
  Paragraph,
  Title,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import ArticlesByFilter from "@app/components/renderings/ArticlesByFilter/ArticlesByFilter";
import Banner from "@app/components/renderings/Banner/Banner";
import Newsletter from "@app/components/renderings/Newsletter/Newsletter";
import { ThemeEnum } from "@app/constants/theme.constants";
import { isValidId } from "@app/helpers/util.helpers";
import useMetaTitle from "@app/hooks/useMetaTitle";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import NoMatch from "@app/routes/components/NoMatch/NoMatch";

import BackToTop from "@app/components/atoms/Fabs/BackToTop/BackToTop";

import { getArticleById } from "../../redux/articles.slice";
import styles from "./ArticleScreen.module.scss";
import ArticlePageComponents from "./components/ArticlePageComponents/ArticlePageComponents";

import { FacebookShareButton, PinterestShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import FacebookIcon from "@app/features/articles/screens/ArticleScreen/components/Share/Icons/FacebookIcon";
import PinterestIcon from "@app/features/articles/screens/ArticleScreen/components/Share/Icons/PinterestIcon";
import TwitterIcon from "@app/features/articles/screens/ArticleScreen/components/Share/Icons/TwitterIcon";
import LinkedinIcon from "@app/features/articles/screens/ArticleScreen/components/Share/Icons/LinkedinIcon";
import TimeToRead from "@app/features/articles/screens/ArticleScreen/components/TimeToRead/TimeToRead";
import { Helmet } from "react-helmet";


const ArticleMeta = ({title, summary, image}) => {
  const titleText = title.length > 70 ? title.slice(0, 67) + "..." : title;
  const summaryText = summary.length > 200 ? summary.slice(0, 197) + "..." : summary;
  const imageToUse = image ? image : "https://api.kaylolife.com/images/kaylo-logo-gold.png";
  return (
    <Helmet>
      <meta property="og:title" content={titleText} />
      <meta property="og:description" content={summaryText} />
      <meta property="og:image" content={imageToUse} />
      <meta property="og:url" content={document.URL} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={titleText} />
      <meta property="twitter:description" content={summaryText} />
      <meta property="twitter:image" content={imageToUse} />
      <meta property="twitter:site" content="@kaylolife" />
    </Helmet>
  );
}


const SocialBtns = ({title, pinterestMediaUrl, summary}) => {
  const size = 42;
  const round = false;
  const iconColor = '#54685c'
  return (
    <div>
      <FacebookShareButton
        url={document.URL}
        quote={title}
        hashtag={'kaylolife'}
      >
        <FacebookIcon size={size} round={round} iconFillColor={iconColor}/>
      </FacebookShareButton>
      <PinterestShareButton
        url={document.URL}
        media={pinterestMediaUrl}
        description={title}
      >
        <PinterestIcon size={size} round={round} iconFillColor={iconColor}/>
      </PinterestShareButton>
      <TwitterShareButton
        url={document.URL}
        title={title}
        hashtags={['kaylolife']}
      >
        <TwitterIcon size={size} round={round} iconFillColor={iconColor}/>
      </TwitterShareButton>
      <LinkedinShareButton
        url={document.URL}
        title={title}
        summary={summary}
        source={document.URL}
      >
        <LinkedinIcon size={size} round={round} iconFillColor={iconColor}/>
      </LinkedinShareButton>
    </div>
  )
}

const ArticleScreen = () => {
  const { t } = useTranslation();
  const { article, loading, error } = useSelector(
    (state: RootState) => state.articles
  );
  const dispatch = useAppDispatch();
  const { articleId } = useParams<{ articleId: string }>();
  useMetaTitle(article?.title, !!article);
  useEffect(() => {
    if (isValidId(articleId)) {
      dispatch(getArticleById(Number(articleId)));
    }
  }, [dispatch, articleId]);

  if (error) return <NoMatch />;

  return (
    <DefaultLayout>

      <BackToTop/>
      <Section theme={ThemeEnum.WHITE}>
        <Loading isLoading={loading}>
          <Heading className={styles.header}>
            {t("default.articleHeader")}
          </Heading>
          {article && (
            <>
              <Section
                theme={ThemeEnum.WHITE}
                withSeparatorSpacing
                className={styles.articleHeader}
              >
                <ContentWrapper>
                  <Row justify="center">
                    <Col xs={12} md={8}>
                      <Title level={1}>{article.title}</Title>
                      <div className={styles.articleMeta}>
                        {!!article.author && (
                          <Paragraph level={1} className={styles.articleMeta}>
                            {t("articles.author", { author: article.author })}
                          </Paragraph>
                        )}
                        <TimeToRead contentfulId={article.contentfulId}/>
                      </div>
                      <Paragraph className={styles.articleMeta} level={1}>
                        {article.intro}
                      </Paragraph>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <div className={styles.horizontalLine}>
                      <span className={styles.horizontalLineText}>Share on social media</span>
                    </div>
                  </Row>
                  <Row justify="center">
                    <Col xs={12} className={styles.topShareBtnContainer}>
                      <SocialBtns title={article.title} pinterestMediaUrl={article.image} summary={article.intro}/>
                    </Col>
                  </Row>
                </ContentWrapper>
              </Section>
              <Banner
                fullwidth={false}
                imageUrl={article.image}
                title={article.title}
              />
            </>
          )}
        </Loading>
        {article && (
          <ArticlePageComponents contentfulId={article.contentfulId}/>
        )}
        {!!article?.tags?.length && (
          <ArticlesByFilter
            title={t("articles.relatedArticles")}
            filter={{ labels: article.tags.map(tag => tag.tag) }}
            theme={ThemeEnum.KHAKI}
          />
        )}
        <Newsletter />
      </Section>
    </DefaultLayout>
  );
};

export default ArticleScreen;
