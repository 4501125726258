import React, { useState } from "react";

import { Col, Row } from "react-grid-system";
import { useMedia } from "react-media";
import { useLocation } from "react-router-dom";
import { Link, Element, scroller } from "react-scroll";
import { useMount } from "react-use";

import Accordian, {
  AccordianItem,
} from "@app/components/atoms/Accordian/Accordian";
import Image from "@app/components/atoms/Image/Image";
import {
  Heading,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getImageUrl } from "@app/helpers/image.helpers";
import { convertToSlug } from "@app/helpers/util.helpers";
import { FaqSectionDef } from "@app/types/faq.types";

import RichText from "../RichText/RichText";
import styles from "./FaqContainer.module.scss";
import FaqSectionModal from "./FaqSectionModal/FaqSectionModal";

export interface FaqContainerProps {
  title: string;
  subtitle: string;
  background: string;
  sections: FaqSectionDef[];
}

export const accordionMapper = (
  entries: FaqSectionDef["entries"]
): AccordianItem[] => {
  return entries?.map(entry => ({
    id: entry.id,
    title: (
      <Subtitle level={2} className={styles.tabTitle}>
        {entry.question}
      </Subtitle>
    ),
    content: <RichText text={entry.answer} wrapContent={false} />,
  }));
};

const FaqContainer = ({
  title,
  background,
  subtitle,
  sections,
}: FaqContainerProps) => {
  const { hash } = useLocation();
  const [activeSection, setActiveSection] = useState<FaqSectionDef>(
    sections[0]
  );
  const [sectionModalVisible, setSectionModalVisible] = useState(false);

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const isActive = (sectionTitle: string) => {
    return hash === `#${convertToSlug(sectionTitle)}`;
  };

  const handleLinkClick = (section: FaqSectionDef) => {
    setActiveSection(section);
    setSectionModalVisible(true);
  };

  useMount(() => {
    const findSection = sections.find(section => isActive(section.title));

    if (findSection) {
      scroller.scrollTo(convertToSlug(findSection.title), { offset: -95 });
      setActiveSection(findSection);
    }
  });

  return (
    <>
      <Section theme={ThemeEnum.KHAKI}>
        <Image
          url={getImageUrl(background, IMAGE_TYPES.BANNER)}
          alt={title}
          className={styles.imageWrapper}
        >
          <div className={styles.content}>
            <Title level={3}>{subtitle} </Title>
            <Heading level={3}>{title}</Heading>
          </div>
        </Image>
      </Section>
      <Section theme={ThemeEnum.KHAKI} className={styles.container}>
        <ContentWrapper>
          <Row>
            <Col xs={12} md={3}>
              {sections.map(section => (
                <Link
                  to={convertToSlug(section.title)}
                  smooth
                  key={section.id}
                  onClick={() => handleLinkClick(section)}
                  offset={-95}
                  hashSpy
                  saveHashHistory
                >
                  <Title
                    level={3}
                    isGold={section.id === activeSection.id}
                    className={styles.sectionLink}
                  >
                    {section.title}
                  </Title>
                </Link>
              ))}
            </Col>
            {matches.tabletLandscape && (
              <Col md={9}>
                {sections.map(section => (
                  <div key={section.id} className={styles.section}>
                    <Element name={convertToSlug(section.title)}>
                      <Title level={1} className={styles.sectionTitle}>
                        {section.title}
                      </Title>
                      <Accordian
                        items={accordionMapper(section.entries)}
                        itemClassName={styles.entry}
                        accordian={false}
                        headerClassName={styles.question}
                        defaultActiveKey={
                          section.id === activeSection.id
                            ? section?.entries?.[0].id
                            : undefined
                        }
                        iconClassName={styles.icon}
                      />
                    </Element>
                  </div>
                ))}
              </Col>
            )}
          </Row>
        </ContentWrapper>
        <FaqSectionModal
          visible={sectionModalVisible && !matches.tabletLandscape}
          activeSection={activeSection}
          onClose={() => setSectionModalVisible(false)}
        />
      </Section>
    </>
  );
};

export default FaqContainer;
