import React from "react";

import { AiOutlineUserAdd } from "react-icons/ai";
import { BsCameraVideo } from "react-icons/bs";

import ExperiencesList from "@app/features/workshops/components/ExperiencesList";
import WorkshopPractitionersList from "@app/features/workshops/components/WorkshopPractitionersList";
import { WorkshopAboutInterface } from "@app/features/workshops/types/about.interface";

import styles from "./WorkshopAboutContent.module.scss";

interface WorkshopAboutContentTypes {
  aboutData: WorkshopAboutInterface;
  sessionId: string;
}

const WorkshopAboutContent: React.FC<WorkshopAboutContentTypes> = ({
  aboutData,
  sessionId,
}) => {
  const getDescription = () =>
    aboutData?.descriptionItems?.map(description => {
      return (
        <div key={description.id}>
          <p className={styles.subtitle}>{description.subtitle}</p>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description.content }}
          />
        </div>
      );
    });

  return (
    <div className={styles.wrapper}>
      <div className={styles.interaction}>
        <div className={styles.interactionIcons}>
          <div className={styles.icons}>
            <BsCameraVideo className={styles.icon} />
            {aboutData.workshop_type === 'ZOOM' ? (<span>Interactive Zoom call</span>) : ''}
            {aboutData.workshop_type === 'LIVE' ? (<span>In-person event</span>) : ''}
          </div>
          <div className={styles.icons}>
            <AiOutlineUserAdd className={styles.icon} />
            <span>
              Participants are limited to {aboutData.max_participants_count}
            </span>
          </div>
        </div>
        <WorkshopPractitionersList sessionId={sessionId} />
      </div>

      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: aboutData.short_description }}
      />
      {getDescription()}
      {aboutData.experiences && (
        <>
          <p className={styles.subtitle}>Workshop experiences include:</p>
          <ExperiencesList experiences={aboutData.experiences} />
        </>
      )}
    </div>
  );
};

export default WorkshopAboutContent;
