export enum ContentModel {
  HERO = "hero",
  TOPIC_COMPONENT = "topicComponent",
  THREE_USP_COMPONENT = "threeUspComponent",
  EXPERIENCES = "experiences",
  PROMOTED_EVENT = "promotedEvent",
  ARTICLES = "articles",
  BANNER = "banner",
  TESTIMONIALS = "testimonials",
  HERO_VIDEO = "heroVideo",
  FULL_WIDTH_VIDEO = "fullWidthVideo",
  STATIC_PAGE = "staticPage",
  STATIC_RICH_TEXT = "staticRichText",
  RECOMMENDED_SESSIONS = "recommendedSessions",
  UPCOMING_SESSIONS = "upcomingSessions",
  NEWSLETTER = "newsletter",
  PRACTITIONER = "practitioner",
  EXPERIENCE = "experience",
  CLASS = "class",
  FAQ_CONTAINER = "faqContainer",
  IMAGE_CAROUSEL = "imageCarousel",
  ROTATING_BANNER = "rotatingBanner",
  HOMEPAGE_CATEGORIES = "homepageCategories",
  EMBED_VIDEO = 'embedVideo',
  FEATURED_IN = 'featuredIn'
}
