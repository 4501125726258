import React, { memo } from "react";

import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import FeaturedIn, { FeaturedInItem } from "@app/components/renderings/FeaturedIn/FeaturedIn";

interface FeaturedInContentfulProps {
  entryID: string;
}

interface FeaturedInDataModel {
  links: [string];
  logos: [string];
  theme: string;
  title: string;
  itemsInRowMobile: number;
  itemsInRowTablet: number;
  itemsInRowDesktop: number;
}

const FeaturedInContentful = memo(({entryID}: FeaturedInContentfulProps) => {
  const { entry: data } = useSingleEntry<FeaturedInDataModel>(entryID);
  const items : FeaturedInItem[] = [];
  if(data) {
    for (let i = 0; i < data?.links.length; i++) {
      if(data.logos.length >= i) {
        items.push({link: data.links[i], image: data.logos[i]})
      }
    }
  }
  return (
    <FeaturedIn
      theme={data?.theme as ThemeEnum} articles={items} title={data?.title}
      itemsInRowDesktop={data?.itemsInRowDesktop}
      itemsInRowTablet={data?.itemsInRowTablet}
      itemsInRowMobile={data?.itemsInRowMobile}
    />
  )
});

export default FeaturedInContentful;