import React from "react";
import styles from "./SubscriptionMessage.module.scss";

import { IoClose } from "react-icons/io5";

interface SubscriptionMessage {
  isDisplayed: boolean;
  displayMessage: (state: boolean) => void;
}

const SubscriptionMessage: React.FC<SubscriptionMessage> = ({
  isDisplayed,
  displayMessage,
}) => {
  const handleCloseMessage = () => {
    displayMessage(false);
  };

  return (
    <>
      {isDisplayed && (
        <div className={styles.wrapper}>
          <span className={styles.message}>Welcome to our community!</span>
          <IoClose className={styles.close} onClick={handleCloseMessage} />
        </div>
      )}
    </>
  );
};

export default SubscriptionMessage;
