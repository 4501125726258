import { UserRole } from "@app/constants/user";
import { SessionTypeEnum } from "@app/constants/video-session.constants";
import { TokenDef } from "@app/features/auth/auth";
import { ClassesDef } from "@app/features/classes/classes";
import { PermissionEnum } from "@app/features/permissions/permissions";

export type CreateUserDef = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export interface CreateUserProps {
  firstName: string;
  lastName: string;
  email: string;
  email_confirmation: string;
  password: string;
  checkedGdpr: boolean;
  receive_marketing_news: boolean;
  checkedTc: boolean;
  verifyEmail?: boolean;
}

type GenderDef = "m" | "f" | "nb";

export type UpdateUserDef = {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  pseudonym?: string;
  receiveMarketingNews?: boolean;
  dateOfBirth?: string;
  mobile?: string;
  gender?: GenderDef;
  language?: string;
  country?: string;
  image?: string;
};

export type UserPermissionDef = {
  id: number;
  name: PermissionEnum;
};

export type UserRoleDef = {
  id: number;
  name: UserRole;
  permissions: UserPermissionDef[];
};

export type BasicUserDef = {
  id: number;
  email?: string;
  firstName: string;
  lastName: string;
  pseudonym?: string;
  image?: string | null;
};

export type UserDef = BasicUserDef & {
  email: string;
  receiveMarketingNews: boolean;
  dateOfBirth: string;
  mobile: string;
  gender: GenderDef;
  language: string;
  country: string;
  refId: number;
  roles: UserRoleDef[];
  token?: TokenDef;
  practitionersReferenceCodes?: string[];
};

export type SessionDef = {
  id: number;
  classTypeId: ClassesDef["id"];
  type: SessionTypeEnum;
  vonageSessionId: string;
  archiveUrls: string[] | null;
  disableChat: boolean;
  isLive: boolean;
  comments?: ClassCommentDef[];
  privateUnreadFromUserIds?: BasicUserDef["id"][];
};

export type ClassCommentDef = {
  id: number;
  sessionId: SessionDef["id"];
  fromUser: BasicUserDef;
  toUser: BasicUserDef | null;
  message: string;
  createdAt: string;
};

export type CreateCommentDef = {
  toUserId?: number | null;
  message: string;
};

export enum ClassFeedbackIncidents {
  INJURIES = "injuries/accidents",
  INAPPROPRIATE = "inappropriate-behaviour",
  OTHER = "other",
}

export type ClassFeedbackDef = {
  like?: boolean | null;
  rateClass?: number | null;
  ratePractitioner?: number | null;
  rateVideo?: number | null;
  comment?: string | null;
  incidentType?: ClassFeedbackIncidents | null;
  incidentDescription?: string | null;
};
export type ClassForceDisconnectDef = {
  connectionIds: string[];
};

export type ApiFormErrorsDef = Record<string, string[]>;
