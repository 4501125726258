import { RouteItemDef } from "@app/types/route.types";
import { ErrorPathEnum } from "@app/routes/components/Error/error.paths";
import ErrorComponent from "@app/routes/components/Error/ErrorComponent";

const ERROR_PAGE: RouteItemDef = {
  id: "sign-in",
  path: ErrorPathEnum.ERROR,
  component: ErrorComponent,
  metaTitle: "signIn.metaTitle",
};

export const ERROR_ROUTES = [ERROR_PAGE];
