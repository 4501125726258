import React from "react";
import { ReactSVG } from "react-svg";
import mail from "@app/assets/images/emails.svg";
import styles from "./SubscribeButton.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/root-reducer";
import cx from "classnames";

interface SubscriptionButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onClick: () => void;
}

const SubscribeButton: React.FC<SubscriptionButtonProps> = ({ onClick }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.noAuth]: !isAuthenticated,
      })}
      onClick={onClick}
    >
      <ReactSVG
        src={mail}
        className={cx(styles.image, {
          [styles.noAuth]: !isAuthenticated,
        })}
      />
    </div>
  );
};

export default SubscribeButton;
