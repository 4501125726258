import React from "react";
import styles from "./FormHeader.module.scss";
import { IconArrowLeft } from "@app/components/atoms/Icon/Icon";
import cx from "classnames";

interface RestoreHeaderProps {
  title: string;
  backArrow: boolean;
  onArrowClick?: () => void;
  isGold?: boolean;
}

const FormHeader: React.FC<RestoreHeaderProps> = ({
  title,
  backArrow,
  onArrowClick,
  isGold = false,
}) => {
  return (
    <div className={styles.wrapper}>
      {backArrow && (
        <IconArrowLeft className={styles.back} onClick={onArrowClick} />
      )}
      <p
        className={cx(styles.title, {
          [styles.gold]: isGold,
        })}
      >
        {title}
      </p>
    </div>
  );
};

export default FormHeader;
