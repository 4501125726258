import React from "react";
import styles from "@app/features/auth/screens/ResetPasswordPage/RequestResetPassword.module.scss";
import CreateAccountLayout from "@app/features/auth/screens/CreateAccount/components/CreateAccountLayout";

import ResetPasswordInfoComponent from "@app/features/auth/screens/ResetPasswordPage/components/ResetPasswordInfoComponent";

const ResetPasswordInfo = () => {
  return (
    <div className={styles.wrapper}>
      <CreateAccountLayout>
        <ResetPasswordInfoComponent />
      </CreateAccountLayout>
    </div>
  );
};

export default ResetPasswordInfo;
