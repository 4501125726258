export enum EcommercePathsEnum {
  PRODUCT_DETAILS = "/class/:id/:slug?",
  CONFIRMATION = "/order-confirmation/:orderNumber",
  PAYMENT = "/payment",
  WORKSHOP_DETAILS = "/workshop/:id/:slug?",
}

export enum SnipcartPathsEnum {
  CART = "/cart",
  CHECKOUT = "/checkout",
}
