import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@app/redux/root-reducer";

const rootSelector = (state: RootState) => state;
const selectSelf = (state: RootState) => state.discount;

export const discountCodeSelector = createSelector(
  selectSelf,
  state => state.discountCode
);

export const discountErrorSelector = createSelector(
  selectSelf,
  state => state.error
);
export const discountLoadingSelector = createSelector(
  selectSelf,
  state => state.loading
);

const zeroPrices = createSelector(rootSelector, state =>
  state.basket?.basket?.lineItems?.reduce(
    (acc, item) => acc + item?.price?.totalPrice,
    0
  )
);

export const zeroBasketSelector = createSelector(
  rootSelector,
  zeroPrices,
  (state, zero) =>
    (!!state.discount?.discountCode &&
      state.basket?.basket?.price?.totalPrice === 0) ||
    zero === 0
);
