import React from "react";
import { ErrorMessage, Field } from "formik";
import cx from "classnames";
import styles from "./MaskedFormInput.module.scss";
import TextError from "@app/features/auth/components/FormControls/TextError";

import NumberInput from "@app/features/auth/components/FormControls/NumberInput";
interface MaskedFormInputProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: string;
  label: string;
  type: string;
  secondLabel?: JSX.Element;
  secondLabelDescription?: string;
  isPassword?: boolean | undefined;
  placeholder?: string;
  mask: string;
  errors?: string;
}
const MaskedFormInput: React.FC<MaskedFormInputProps> = ({
  name,
  label,
  errors,
  placeholder,
  mask,
  ...rest
}) => {
  return (
    <div className={styles.formInput} {...rest}>
      <label htmlFor={name} className={cx(styles.label)}>
        {label}
      </label>
      <NumberInput name={name} numberFormat={mask} placeholder={placeholder} />
      <ErrorMessage name={name} component={TextError} />
      {errors && <TextError>{errors}</TextError>}
    </div>
  );
};

export default MaskedFormInput;
