import { format } from "date-fns";

export const formatDate = (date: string | Date, formatString: string) => {
  if (typeof date === "string") {
    if (date.includes("+00:00")) {
      date = date.replace("+00:00", "");
    }
  }
  return format(new Date(date), formatString);
};

export const formatDuration = (startDate: Date, endDate: Date) => {
  const difference = `${format(
    new Date(startDate),
    "EEE d LLL - h:mm"
  )} - ${format(new Date(endDate), "h:mmb")}`;

  return difference;
};
