import React, {memo} from "react";

import cx from "classnames";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import {
  Heading,
  Paragraph,
  Title,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import {ThemeEnum, AlignmentEnum} from "@app/constants/theme.constants";
import {isEmpty} from "@app/helpers/util.helpers";

import styles from "./PromotedEvent.module.scss";
import {Col, Container, Row} from 'react-grid-system';

export interface PromotedEventProps {
  /**
   * Set the component main theme. Values should be either "khaki" or "white" or "imagery". Default value is "khaki"
   */
  theme: ThemeEnum;
  /**
   * Set the main image of the promoted event component
   */
  image?: string;
  /**
   * Set the video link of the promoted event component
   */
  videoLink?: string;
  /**
   * Set the main heading of the promoted event content
   */
  title: string;
  /**
   * Set the subtitle of the promoted event content
   */
  subtitle: string;
  /**
   * Set description of the promoted event content
   */
  description: string;
  /**
   * Set the inner box main theme. Values should be either "khaki" or "white" or "imagery". Default value is "khaki"
   */
  boxTheme: ThemeEnum;
  /**
   * Set the main call to action label text
   */
  CTALabel: string;
  /**
   * Set the main call to action Type either "primary", "secondary" or "tertiary". Default value is "primary"
   */
  CTAType: ButtonTypeEnum;
  /**
   * Set the main call to action link to a specific page
   */
  CTALink: string;
  /**
   * Set the promoted event box alignment to be "left", "right" or "center". Default value is "left"
   */
  alignment: AlignmentEnum;
  /**
   * Set the background image to take the full screen width
   */
  isFullBackground?: boolean;

  mobileColor?: string;
}

const PromotedEvent = memo(
  ({
     theme,
     image,
     title,
     subtitle,
     description,
     boxTheme,
     CTALabel,
     CTALink,
     CTAType,
     alignment,
     isFullBackground = false,
     videoLink,
     mobileColor
   }: PromotedEventProps) => {
    const backgroundImage = {backgroundImage: `url(${image})`};

    const hasContent =
      !isEmpty(title) ||
      !isEmpty(subtitle) ||
      !isEmpty(description) ||
      !isEmpty(CTALabel);

    return (
      <Section
        theme={theme}
        className={cx(styles.wrapper, {
          [styles.fullWidth]: isFullBackground,
        })}
        withSeparatorSpacing={!isFullBackground}
      >
        <ContentWrapper className={styles.contentWrapper}>
          <div className={styles.mediaWrapper}>
            {!!image && (
              <Image url={image} alt={title} className={styles.image}/>
            )}
            {!!videoLink && (
              <video
                className={styles.videoPlayer}
                playsInline
                autoPlay
                muted
                loop
                poster={image}
              >
                <source src={videoLink} type="video/mp4"/>
              </video>
            )}
            {!!image && (
              <div className={styles.blurredImage} style={backgroundImage}/>
            )}
            {(hasContent && !videoLink) && (
              <ContentWrapper
                className={cx(styles.boxWrapper, {
                  [styles.centered]: alignment === AlignmentEnum.CENTER,
                  [styles.rightAligned]: alignment === AlignmentEnum.RIGHT,
                })}
              >
                <div className={styles.boxGridContainer}>
                  <Section
                    theme={boxTheme}
                    className={cx(styles.box, {
                      [styles.textCenter]: alignment === AlignmentEnum.CENTER,
                      [styles.textRight]: alignment === AlignmentEnum.RIGHT,
                    })}
                  >
                    {!!subtitle && (
                      <Title level={3} className={styles.subtitle}>
                        {subtitle}
                      </Title>
                    )}
                    {!!title &&
                      (isFullBackground ? (
                        <Heading level={2}>{title}</Heading>
                      ) : (
                        <Title level={1} className={styles.title}>
                          {title}
                        </Title>
                      ))}
                    {!!description && (
                      <Paragraph
                        level={2}
                        className={styles.description}
                        dangerouslySetInnerHTML={{__html: description}}
                      />
                    )}
                    {!!CTALabel && (
                      <Button
                        className={styles.mainButton}
                        to={CTALink}
                        label={CTALabel}
                        buttonType={CTAType}
                        size={ButtonSizeEnum.LARGE}
                      />
                    )}
                  </Section>
                </div>
              </ContentWrapper>
            )}
            {(hasContent && videoLink) && (
              <div className={styles.videoOverlay}>
                <ContentWrapper style={{zIndex: 3}}>
                  <Container fluid>
                    <Row justify={'center'}>
                      <Col
                        xs={12}
                        md={8}
                        xl={7}
                        className={cx(styles.heroText, {
                          [styles.textLeft]:
                            true,
                          [styles.textRight]:
                            false,
                        })}
                      >
                        {!!subtitle && (
                          <Title className={styles.subtitle} level={3}>
                            {subtitle}
                          </Title>
                        )}
                        {!!title && (
                          <Heading className={styles.title} level={3}>
                            {title}
                          </Heading>
                        )}
                        {!!description && (
                          <Paragraph className={styles.description} level={1}>
                            {description}
                          </Paragraph>
                        )}
                        {!!CTALabel && (
                          <Button
                            to={CTALink}
                            label={CTALabel}
                            buttonType={CTAType}
                            size={ButtonSizeEnum.LARGE}
                          />
                        )}
                      </Col>
                    </Row>
                  </Container>
                </ContentWrapper>
              </div>
            )}
          </div>
        </ContentWrapper>
      </Section>
    );
  }
);

export default PromotedEvent;
