import React from "react";
import styles from "./CustomerInformation.module.scss";
import { UserDef } from "@app/types/api.types";

interface CustomerInformationProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  user: UserDef | null;
}

const CustomerInformation: React.FC<CustomerInformationProps> = ({ user }) => {
  if (user) {
    return (
      <div className={styles.wrapper}>
        <p className={styles.title}>Customer Information</p>
        <p className={styles.name}>{`${user.firstName} ${user.lastName}`}</p>
        <p className={styles.email}>{user.email}</p>
      </div>
    );
  }
  return <></>;
};

export default CustomerInformation;
