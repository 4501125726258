import React from "react";
import styles from "./Unverified.module.scss";
import { authApi } from "@app/features/auth/api/auth.api";
import { useHistory } from "react-router-dom";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";

interface UnverifiedProps {
  email: string;
}

const Unverified: React.FC<UnverifiedProps> = ({ email }) => {
  const history = useHistory();
  const handleClick = async () => {
    await authApi.verifyAccount(email);
    const location = {
      pathname: AuthPathsEnum.CONFIRM_ACCOUNT,
      state: {
        email,
      },
    };
    history.push(location);
  };
  return (
    <div className={styles.wrapper}>
      Sorry for holding you up - we just need to verify your email. Please click{" "}
      <span className={styles.link} onClick={handleClick}>
        here{" "}
      </span>
      and check your inbox for further instructions. You&apos;ll soon be good to
      go.
    </div>
  );
};

export default Unverified;
