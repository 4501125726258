import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineCalendar, AiOutlineUser } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { FiCreditCard } from "react-icons/fi";
import { IoIosSunny } from "react-icons/io";
import { useMedia } from "react-media";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import Modal from "@app/components/atoms/Modal/Modal";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { openBasket } from "@app/features/basket/redux/basket.slice";
import { addToCart } from "@app/features/workshops/api/api.request";
import IntensityLevel from "@app/features/workshops/components/IntensityLevel";
import { WorkshopIntensityEnum } from "@app/features/workshops/constants/workshop-intensity.enum";
import { WorkshopVisibilityEnum } from "@app/features/workshops/constants/workshop-visibility.enum";
import { WorkshopSummaryInterface } from "@app/features/workshops/types/summary.interface";
import { removeUnderline } from "@app/helpers/underline.helpers";
import dateFormat from "dateformat";

import styles from "./WorkshopSummary.module.scss";

interface WorkshopSummaryTypes
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  info: WorkshopSummaryInterface;
}

interface MatchParams {
  id: string;
  slug: string;
}

const WorkshopSummary: React.FC<WorkshopSummaryTypes> = ({ info, ...rest }) => {
  const { t } = useTranslation();
  const match = useRouteMatch<MatchParams>();
  const { id } = match?.params;
  const dispatch = useDispatch();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const isBooked = info.booked;
  const firstDate = info.session_timings && info.session_timings.length > 0 ? new Date(info.session_timings[0].startAt) : ''
  const formattedDate = firstDate ? dateFormat(firstDate, 'dddd dS mmmm') : '';

  // day of week 10th january
  // dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
// Saturday, June 9th, 2007, 5:46:21 PM

  const handleBookWorkshop = () => {
    addToCart(id).then(() => dispatch(openBasket()));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.labels}>
        <div className={styles.label}>
          <AiOutlineCalendar className={styles.icon} />
          <span className={styles.length}>
            { "Beginning on " + formattedDate }
          </span>{" "}
        </div>
        <div className={styles.label}>
          <AiOutlineUser className={styles.icon} />
          <span className={styles.open}>
            {
              WorkshopVisibilityEnum[
                info.workshop_visibility as keyof typeof WorkshopVisibilityEnum
              ]
            }
          </span>
        </div>
        {info.workshop_difficulty && (
          <div className={styles.label}>
            <IntensityLevel level={info.workshop_difficulty as string} />
            <span
              className={styles.intensity}
              onClick={() => setShowModal(true)}
              title="Click to find out more"
            >
              {
                WorkshopIntensityEnum[
                  info.workshop_difficulty as keyof typeof WorkshopIntensityEnum
                ]
              }{" "}
              intensity
            </span>
            <BsQuestionCircle
              className={styles.question}
              onClick={() => setShowModal(true)}
              title="Click to find out more"
            />
          </div>
        )}
        {info.spiritual_depth && (
          <div className={styles.label}>
            <IoIosSunny className={styles.icon} />
            <span
              className={styles.depth}
              // title="Click to find out more"
            >
              Spiritual depth: {info.spiritual_depth}/5
            </span>
            <BsQuestionCircle
              className={styles.question}
              // title="Click to find out more"
            />
          </div>
        )}
        <div className={styles.label}>
          {!matches.tabletLandscape && <FiCreditCard className={styles.icon} />}
          <span className={styles.price}>&#163;{info.price}</span>
        </div>
      </div>
      {isBooked ? (
        <Button
          isDisabled
          fullWidth={!matches.tabletLandscape}
          label={t("default.booked")}
          buttonType={ButtonTypeEnum.SECONDARY}
        />
      ) : (
        <Button
          label={"Book Journey".toUpperCase()}
          className={styles.button}
          fullWidth={!matches.tabletLandscape}
          onClick={handleBookWorkshop}
        />
      )}
      <Modal
        visible={showModal}
        showCloseButton
        onClose={closeModal}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <p className={styles.modalSubtitle}>
            KAYLO LEVEL OF PHYSICALITY SCALE
          </p>
          <p className={styles.modalText}>
            When we discover an instructor or class for the first time, it’s
            sometimes hard to know how physically demanding the class will be,
            and whether our stamina will hold out. To help ensure you have the
            experience you’re expecting, we’ve devised our own Kaylo Level of
            Physicality Scale.
          </p>
          <p className={styles.modalSubtitle}>LOW</p>
          <p className={styles.modalText}>
            You’ll be sitting at complete rest with either no exertion or so
            little that you wouldn’t notice if the class went on all day.
          </p>
          <p className={styles.modalSubtitle}>MEDIUM</p>
          <p className={styles.modalText}>
            Comfortable soft movement for anyone, designed to get the blood
            flowing, help with alignment and increase energy for the rest of the
            day. Feels good and restorative.
          </p>
          <p className={styles.modalSubtitle}>HIGH</p>
          <p className={styles.modalText}>
            Physically challenging, aimed at getting your heartbeat up and
            making you sweat. You may not be able to keep the pace up for the
            full duration of the session.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default WorkshopSummary;
