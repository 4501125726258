import React, { memo, useMemo } from "react";

import cx from "classnames";
import { Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import SwiperCarousel, {
  NavigationTypes,
  SwiperBreakpoints,
  SwiperSlide,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES, ScreenSizes } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";

import { Title } from "@app/components/atoms/Typography/Typography";
import styles from "./TestimonialsCarousel.module.scss";
import TestimonialsCard from "@app/features/workshops/components/TestimonialsCard";
import SwiperTestimonialsCarousel from "@app/components/atoms/SwiperCarousel/SwiperTestimonialsCarousel";
import { WorkshopTestimonialsInterface } from "@app/features/workshops/types/testimonials.interface";

export interface SessionsProps {
  theme?: ThemeEnum;
  subtitle?: string;
  title?: string;
  cards: WorkshopTestimonialsInterface[];
  linkId?: string;
}

const TestimonialsCarousel = memo(
  ({ theme = ThemeEnum.KHAKI, title, subtitle, cards }: SessionsProps) => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const slidesNumber = 2;

    const swiperBreakpoints: SwiperBreakpoints = {
      [ScreenSizes.LARGE]: {
        slidesPerView: slidesNumber,
      },
      [ScreenSizes.SMALL]: {
        slidesPerView: 1,
      },
    };
    const listToRender = (limit = 5) => {
      return cards.slice(0, limit);
    };

    const isSliderEnabled = useMemo(() => cards.length > slidesNumber, [
      cards.length,
    ]);

    const getSession = (card: WorkshopTestimonialsInterface) => {
      return <TestimonialsCard card={card} />;
    };

    return (
      <Section theme={theme} withSeparatorSpacing noBackgroundOverlap>
        <ContentWrapper>
          <Container fluid style={{ padding: 0 }}>
            {!!subtitle && (
              <Title level={3} className={styles.subtitle} isGoldGradient>
                {subtitle}
              </Title>
            )}
            <Title level={2} className={styles.title}>
              {title ?? t("recommendedSessions.defaultTitle")}
            </Title>
          </Container>
          <>
            <SwiperTestimonialsCarousel
              swiperClassname={cx(styles.sessionsContainer, {
                [styles.sessionsSwiper]: isSliderEnabled,
              })}
              swiperBreakpoints={swiperBreakpoints}
              showNavArrows={isSliderEnabled}
              showPagination
            >
              {listToRender(12)?.map(card => (
                <SwiperSlide key={card.id}>{getSession(card)}</SwiperSlide>
              ))}
            </SwiperTestimonialsCarousel>
          </>
        </ContentWrapper>
      </Section>
    );
  }
);

export default TestimonialsCarousel;
