import React, {memo} from "react";

import {Heading, Paragraph, Title} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import {ThemeEnum} from "@app/constants/theme.constants";

import styles from "./FullWidthVideo.module.scss";
import cx from 'classnames';

export interface FullWidthVideoProps {
  title: string;
  description?: string;
  subtitle?: string;
  video: string;
  theme: string;
}

const FullWidthVideo = memo(
  ({title, subtitle, description, video, theme}: FullWidthVideoProps) => {
    return (
      <>
        <Section className={styles.wrapper} theme={ThemeEnum.KHAKI}>
          <div className={styles.content}>
            <video muted autoPlay loop className={styles.video} src={video}/>
            <ContentWrapper className={cx(styles.overlay)}>
              <div>
                <Title level={3}>
                  {subtitle}
                </Title>
                <Heading className={styles.title} level={3}>
                  {title}
                </Heading>
                <Paragraph className={styles.description} level={1}>
                  {description}
                </Paragraph>
              </div>
            </ContentWrapper>
          </div>
        </Section>
        <Section theme={theme as ThemeEnum} className={cx(styles.mobileSection)}>
          <ContentWrapper>
            <Title level={3}>
              {subtitle}
            </Title>
            <Heading className={styles.title} level={3}>
              {title}
            </Heading>
            <Paragraph className={styles.description} level={1}>
              {description}
            </Paragraph>
          </ContentWrapper>
        </Section>
      </>
    );
  }
);

export default FullWidthVideo;
