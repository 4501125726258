import React from "react";
import styles from "./Error.module.scss";
import error from "@app/assets/images/error500.svg";
import { ReactSVG } from "react-svg";
import Button from "@app/components/atoms/Button/Button";
import { useHistory } from "react-router-dom";
import { AppPathEnums } from "@app/routes/routes.constants";

const ErrorComponent = () => {
  const history = useHistory();
  const handleButtonClick = () => {
    history.push(AppPathEnums.USER_HOME);
  };

  return (
    <div className={styles.wrapper}>
      <ReactSVG src={error} className={styles.image} />
      <p className={styles.title}>Whoops, there&apos;s been an error</p>
      <p className={styles.subtitle}>
        Looks like we’re having a few technical issues, but our engineers are
        working on them right now!
      </p>
      <Button
        label="Back to home page"
        className={styles.button}
        fullWidth
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default ErrorComponent;
