import React from "react";

import cx from "classnames";

import styles from "./SubmitButton.module.scss";

interface SubmitButtonProps {
  isDisabled?: boolean;
  label: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isDisabled, label }) => {
  return (
    <button
      type="submit"
      className={cx(styles.submit, {
        [styles.disabled]: isDisabled,
      })}
      disabled={isDisabled}
    >
      {isDisabled ? "Loading..." : label}
    </button>
  );
};

export default SubmitButton;
