import NumberFormat from "react-number-format";
import React from "react";
import styles from "./NumberInput.module.scss";
import { useField } from "formik";

interface NumberFieldHooksProps {
  name: string;
  numberFormat: string;
  placeholder?: string;
}

const NumberField: React.FC<NumberFieldHooksProps> = ({
  name,
  numberFormat,
  placeholder,
}) => {
  const [field] = useField(name);
  return (
    <NumberFormat
      {...field}
      autoComplete="off"
      displayType="input"
      format={numberFormat}
      placeholder={placeholder}
      className={styles.input}
    />
  );
};
export default NumberField;
