import React, { useMemo } from "react";

import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import { IconArrowRight } from "@app/components/atoms/Icon/Icon";
import List from "@app/components/atoms/List/List";
import Loading from "@app/components/atoms/Loading/Loading";
import SessionCard from "@app/components/atoms/SessionCard/SessionCard";
import { Title } from "@app/components/atoms/Typography/Typography";
import { ClassesFilterEnum } from "@app/constants/classes";
import { isLiveSession } from "@app/features/classes/classes";
import {
  getSeekerClasses,
  getSeekerClassesAndAppend,
  UserPathsEnum,
} from "@app/features/users/users";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import PastClasses from "./PastClasses/PastClasses";
import styles from "./SeekerSessions.module.scss";

interface SeekerSessionsProps {
  title?: string;
  smallOnMobile?: boolean;
  hideCTA?: boolean;
  showPagination?: boolean;
  hideIfEmpty?: boolean;
  filter?: ClassesFilterEnum;
  className?: string;
  allowCancel?: boolean;
  isHomePage?: boolean;
  includeCancelled?: boolean;
}

const SeekerSessions = ({
  title,
  smallOnMobile,
  isHomePage,
  hideCTA = false,
  showPagination = false,
  filter = ClassesFilterEnum.UPCOMING,
  className,
  allowCancel = false,
  includeCancelled,
}: SeekerSessionsProps) => {
  const { t } = useTranslation();
  const { classes, loading, loadingMore, classesPagination } = useSelector(
    (state: RootState) => state.classes
  );

  const dispatch = useAppDispatch();

  const handleLoadMoreClasses = () => {
    dispatch(
      getSeekerClassesAndAppend({
        filter,
        page: classesPagination.current_page + 1,
        ...(includeCancelled && { includeCancelled: 1 }),
      })
    );
  };

  const filteredClasses = useMemo(() => {
    return isHomePage ? classes.slice(0, 5) : classes;
  }, [classes, isHomePage]);

  useMount(() => {
    dispatch(
      getSeekerClasses({
        filter,
        ...(isHomePage && { limit: 5 }),
        ...(includeCancelled && { includeCancelled: 1 }),
      })
    );
  });

  return (
    <Loading isLoading={loading}>
      {!!classes.length && (
        <div className={className}>
          {!!title && (
            <Title level={1} className={styles.title}>
              {title}
            </Title>
          )}
          <List
            isLoadingMore={loadingMore}
            pagination={classesPagination}
            onLoadMore={handleLoadMoreClasses}
            showPagination={showPagination}
          >
            {filter === ClassesFilterEnum.UPCOMING ? (
              <Row>
                {filteredClasses.map(session => (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    key={session.classAttendeeId ?? session.id}
                  >
                    <SessionCard
                      session={session}
                      smallOnMobile={
                        isHomePage && isLiveSession(session)
                          ? false
                          : smallOnMobile
                      }
                      allowCancel={allowCancel}
                      isHomePage={isHomePage}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <PastClasses sessions={classes} />
            )}
          </List>
          {!hideCTA && (
            <Button
              to={UserPathsEnum.MY_BOOKINGS}
              label={t("default.seeAll")}
              className={styles.button}
              size={ButtonSizeEnum.TEXT}
              endIcon={<IconArrowRight />}
            />
          )}
        </div>
      )}
    </Loading>
  );
};

export default SeekerSessions;
