import React, { memo } from "react";

import cx from "classnames";
import { Container, Row, Col } from "react-grid-system";
import { useLocation } from "react-router-dom";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import {
  Heading,
  Title,
  Paragraph,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import {
  ThemeEnum,
  BackgroundRatioEnum,
  AlignmentEnum,
} from "@app/constants/theme.constants";

import styles from "./Hero.module.scss";

export interface HeroProps {
  /**
   * Set theme for section, which will control section backgrounds color and nested components
   */
  theme: ThemeEnum;
  /**
   * Set the subTitle of the hero content
   */
  subTitle: string;
  /**
   * Set the main heading of the hero content
   */
  title: string;
  /**
   * Set the description of the hero content below the main heading
   */
  description: string;
  /**
   * Set the background image of the hero component
   */
  heroImage: string;
  /**
   * Set the main call to action text
   */
  CTALabel: string;
  /**
   * Set the main call to action link to a specific page
   */
  CTALink: string;
  /**
   * Set the main call to action Type either "primary", "secondary" or "tertiary". Default value is "primary"
   */
  CTAType: ButtonTypeEnum;
  /**
   * Set the background ratio for the KhakiToWhite and WhiteToKhaki background transition of sections in the page
   */
  backgroundRatio?: BackgroundRatioEnum;
  /**
   * Set the hero content alignment to be "left", "right" or "center". Default value is "center"
   */
  contentAlignment?: AlignmentEnum;
}

const Hero = memo(
  ({
    theme,
    subTitle,
    title,
    description,
    heroImage,
    CTALabel,
    CTALink,
    CTAType,
    backgroundRatio,
    contentAlignment = AlignmentEnum.CENTER,
  }: HeroProps) => {
    const backgroundImage = { backgroundImage: `url(${heroImage})` };

    const setHeroContentAlignment = () => {
      if (contentAlignment === AlignmentEnum.LEFT) return "start";
      if (contentAlignment === AlignmentEnum.RIGHT) return "end";
      return "center";
    };

    const location = useLocation();
    let { heroContent } = styles;
    if (location.pathname === "/") {
      heroContent = styles.heroContent;
    } else {
      heroContent = styles.heroContentSingle;
    }

    return (
      <Section
        className={styles.heroWrapper}
        theme={theme}
        backgroundRatio={backgroundRatio}
      >
        <div className={styles.blurredBackground} style={backgroundImage} />
        <div className={heroContent} style={backgroundImage}>
          <Section className={styles.heroSection} theme={ThemeEnum.KHAKI}>
            <ContentWrapper>
              <Container fluid>
                <Row justify={setHeroContentAlignment()}>
                  <Col
                    xs={12}
                    md={8}
                    xl={7}
                    className={cx(styles.heroText, {
                      [styles.textLeft]:
                        contentAlignment === AlignmentEnum.LEFT,
                      [styles.textRight]:
                        contentAlignment === AlignmentEnum.RIGHT,
                    })}
                  >
                    {!!subTitle && (
                      <Title className={styles.subtitle} level={3}>
                        {subTitle}
                      </Title>
                    )}
                    {!!title && (
                      <Heading className={styles.title} level={3}>
                        {title}
                      </Heading>
                    )}
                    {!!description && (
                      <Paragraph className={styles.description} level={1}>
                        {description}
                      </Paragraph>
                    )}
                    {!!CTALabel && (
                      <Button
                        to={CTALink}
                        label={CTALabel}
                        buttonType={CTAType}
                        size={ButtonSizeEnum.LARGE}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </ContentWrapper>
          </Section>
        </div>
      </Section>
    );
  }
);

export default Hero;
