export enum BasketEndpointsEnum {
  PRODUCT = "/v2/cart/product",
  DELETE_PRODUCT = "/cart/product",
  CART = "/cart",
  ORDER = "/order",
  COUNTRIES = "/countries",
  PAYMENT_METHOD = "/payment-methods",
  CHECKOUT_DISCOUNT = "/cart/discount",
  BULK = "/v2/cart/product/bulk",
}
