import React, { memo } from "react";

import cx from "classnames";
import { Container, Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { Title, Paragraph } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum, AlignmentEnum } from "@app/constants/theme.constants";

import styles from "./ThreeUSP.module.scss";

export interface ThreeUSPItemsProps {
  image: string;
  title: string;
  description: string;
  link: string;
  CTAText: string;
}

export interface ThreeUSPProps {
  /**
   * Set theme for section, which will control section backgrounds color and nested components
   */
  theme: ThemeEnum;
  /**
   * Set the main heading of the component
   */
  heading: string;
  /**
   * Set the component alignment to be "left", "right" or "center". Default value is "left"
   */
  alignment?: AlignmentEnum;
  /**
   * Set the items list of the 3 USPs component
   */
  items: ThreeUSPItemsProps[];
}

// const { pathname } = location;
const ThreeUSP = memo(
  ({
    theme,
    heading,
    items = [],
    alignment = AlignmentEnum.LEFT,
  }: ThreeUSPProps) => {
    const location = useLocation();
    const { t } = useTranslation();
    let i = 0;
    return (
      <Section
        theme={theme}
        className={cx(styles.uspWrapper, {
          [styles.alignCenter]: alignment === AlignmentEnum.CENTER,
          [styles.alignRight]: alignment === AlignmentEnum.RIGHT,
        })}
        withSeparatorSpacing
      >
        <ContentWrapper>
          <Container fluid>
            {!!heading && (
              <Title level={1} className={styles.heading}>
                {heading}
              </Title>
            )}
            <Row>
              {items.map(item => (
                <Col key={item.title ? item.title : i++} xs={12} lg={4} className={styles.uspItem}>
                  {item.link ? (
                    <Link to={item.link} className={styles.hasLink}>
                      <div className={styles.imageWrapper}>
                        <img
                          className={styles.image}
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <Title level={2} className={styles.title}>
                        {item.description}
                      </Title>
                      <Paragraph level={2} className={styles.description}>
                        {item.title}
                      </Paragraph>
                      <Button
                        label={
                          item.CTAText ? item.CTAText : t("default.readMore")
                        }
                        buttonType={ButtonTypeEnum.PRIMARY}
                        className={(styles.button, styles.top_bump)}
                      />
                    </Link>
                  ) : (
                    <Link to={item.link} className={styles.hasLink}>
                      <div className={styles.imageWrapper}>
                        <img
                          className={styles.image}
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <Title level={2} className={styles.title}>
                        {item.description}
                      </Title>
                      <Paragraph level={2} className={styles.description}>
                        {item.title}
                      </Paragraph>
                    </Link>
                  )}
                </Col>
              ))}
            </Row>
          </Container>
        </ContentWrapper>
      </Section>
    );
  }
);

export default ThreeUSP;
