import React, { memo } from "react";
import { Container, Row, Col } from "react-grid-system";
import { ThemeEnum } from "@app/constants/theme.constants";
import Section from "@app/components/layouts/Section/Section";

import style from "./FeaturedIn.module.scss";
import { Heading } from "@app/components/atoms/Typography/Typography";
import { isInteger } from "lodash";

export interface FeaturedInItem {
  link: string;
  image: string;
}

interface FeaturedInProps {
  theme: ThemeEnum;
  articles: FeaturedInItem[];
  title: string|undefined;
  itemsInRowMobile: number|undefined;
  itemsInRowTablet: number|undefined;
  itemsInRowDesktop: number|undefined;
}

function checkRowSize(itemsInRow: number|undefined, defaultInRow: number, gridSize: number = 12): number {
  let itemsFixed = defaultInRow;
  if(itemsInRow && isInteger(itemsInRow) && itemsInRow > 0) {
    itemsFixed = itemsInRow;
  }
  return gridSize / itemsFixed
}

const FeaturedIn = memo(({
   theme, articles, title,
   itemsInRowMobile, itemsInRowTablet, itemsInRowDesktop
}: FeaturedInProps) => {
  let articleCounter: number = 0;

  return (
    <Section theme={theme}>
      <Container fluid className={style.container}>
        <Row>
          <Col xs={12} className={style.title}>
            <Heading className={style.titleText} level={3}>{title?.toUpperCase()}</Heading>
          </Col>
        </Row>
        <Row align={"center"} justify={"center"}>
          {articles.map(article => (
            <Col key={ articleCounter++ }
                 xs={checkRowSize(itemsInRowMobile, 3)}
                 md={checkRowSize(itemsInRowTablet, 4)}
                 lg={checkRowSize(itemsInRowDesktop, 6)}
            >
              <a href={article.link} className={style.link}>
                <img className={style.logo} src={article.image} alt="Click to open a link"/>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
})

export default FeaturedIn;