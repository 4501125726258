import React from "react";

import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useRouteMatch} from "react-router-dom";

import Button, {ButtonTypeEnum} from "@app/components/atoms/Button/Button";
import {openBasket} from "@app/features/basket/redux/basket.slice";
import {addToCart} from "@app/features/workshops/api/api.request";
import {WorkshopSessionsInterface} from "@app/features/workshops/types/sessions.interface";
import {formatDuration} from "@app/helpers/formatDate";

import styles from "./WorkshopSessionsList.module.scss";

interface WorkshopSessionsListTypes {
  sessionsData: WorkshopSessionsInterface[];
  showSessions: boolean;
  price: number;
  booked: boolean;
}

interface MatchParams {
  id: string;
  slug: string;
}

const WorkshopSessionsList: React.FC<WorkshopSessionsListTypes> =
  ({
     sessionsData,
     showSessions,
     price,
     booked,
   }) => {
    const {t} = useTranslation();
    const match = useRouteMatch<MatchParams>();
    const {id} = match?.params;
    const dispatch = useDispatch();
    const getDates = () => {
      return sessionsData.map(session => (
        <p key={session.id} className={styles.time}>
          {formatDuration(session.startAt, session.endAt)}
        </p>
      ));
    };

    const handleButtonClick = () => {
      addToCart(id).then(() => dispatch(openBasket()));
    };

    return (
      <div className={styles.wrapper}>
        {showSessions ? (
          <div className={styles.timings}>
            <div className={styles.titles}>
          <span className={styles.subtitle}>
            {sessionsData.length} Sessions
          </span>
              <span className={styles.time_zone}>GMT time zone</span>
            </div>
            {getDates()}
          </div>) : ''}
        <div className={styles.prices}>
          <div className={styles.priceSection}>
            <span>Price</span>
            <span>&#163;{price}</span>
          </div>
          {booked ? (
            <Button
              isDisabled
              fullWidth
              label={t("default.booked")}
              buttonType={ButtonTypeEnum.SECONDARY}
            />
          ) : (
            <Button
              label={"Book Journey".toUpperCase()}
              className={styles.button}
              fullWidth
              onClick={handleButtonClick}
            />
          )}
        </div>
      </div>
    );
  };

export default WorkshopSessionsList;
