import React from "react";

import { FiTrash } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { LineItem } from "@app/features/basket/Types/backend-basket.types";
import { basketQuantitySelector } from "@app/features/basket/redux/BasketSelectors";
import { removeFromCart } from "@app/features/basket/redux/basket.slice";
import { SearchPathsEnum } from "@app/features/search/constants/search.paths";
import { getRouteToClass } from "@app/helpers/class-route.helpers";
import { formatDate } from "@app/helpers/formatDate";

import styles from "./SummaryItem.module.scss";

interface SummaryItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  basketItem: LineItem;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ basketItem }) => {
  const dispatch = useDispatch();
  const basketLength = useSelector(basketQuantitySelector);
  const history = useHistory();
  const handleTitleClick = () => {
    window.open(getRouteToClass(basketItem), "_blank");
  };
  const handleRemoveItemClick = () => {
    dispatch(removeFromCart(basketItem));
    if (basketLength <= 1) {
      history.push(SearchPathsEnum.SEARCH);
    }
  };
  const getDateInfo = () => {
    if (basketItem.type.includes("workshop")) {
      const children = basketItem.children.map(item => item?.payload?.netzp_event?.from).filter(item => !!(item));
      const childDate = children.sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
      })[0]

      const date = formatDate(
        childDate,
        "EEEE d LLL 'at' h:mmaaa"
      );
      return `First session: ${date}`;
    }
    const date = formatDate(
      basketItem?.payload?.netzp_event?.from,
      "EEEE d LLL 'at' h:mmaaa"
    );
    const duration = `(${basketItem.payload.duration} min)`;

    return `${date} ${duration}`;
  };
  return (
    <div className={styles.card}>
      <div className={styles.title}>
        <p className={styles.name} onClick={handleTitleClick}>
          {basketItem.label}
        </p>
        <p className={styles.date}>{getDateInfo()}</p>
      </div>
      <div className={styles.controls}>
        <p className={styles.price}>&#163;{basketItem.price.totalPrice}</p>
        <FiTrash className={styles.icon} onClick={handleRemoveItemClick} />
      </div>
    </div>
  );
};

export default SummaryItem;
