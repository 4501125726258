import axios, { AxiosResponse } from "axios";
import cookie from "react-cookies";

import { api } from "@app/api/api";
import { ENV } from "@app/constants/config";
import {
  CreateUserDef,
  UpdateUserDef,
  UserDef,
  CreateUserProps,
} from "@app/types/api.types";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";
import {
  LoginDataDef,
  RequestResetPasswordDataDef,
  ResetPasswordDataDef,
} from "../types/auth-login.types";

const login = (data: LoginDataDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.LOGIN, data);
};

const logout = (): Promise<AxiosResponse> => {
  cookie.remove("kaylo-access-token");
  return api.post(AuthEndpointsEnum.LOGOUT);
};

const createSeeker = (
  data: CreateUserDef | CreateUserProps
): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.REGISTER_SEEKER, data);
};

const updateUser = (data: UpdateUserDef): Promise<AxiosResponse> => {
  return api.patch(AuthEndpointsEnum.PROFILE, data);
};

const getUser = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.PROFILE);
};

const resendVerificationLink = async (
  email?: string
): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.RESEND_VERIFICATION, {
    params: {
      email,
    },
  });
};

const verifyAccount = async (email: string) => {
  return axios.get(`${ENV.API_HOST}${AuthEndpointsEnum.RESEND_VERIFICATION}`, {
    params: {
      email,
    },
  });
};

const requestResetPassword = (
  data: RequestResetPasswordDataDef
): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.REQUEST_RESET_PASSWORD, data);
};

const resetPassword = (data: ResetPasswordDataDef): Promise<AxiosResponse> => {
  return api.patch(AuthEndpointsEnum.RESET_PASSWORD, data);
};

const refreshAccessToken = (): Promise<AxiosResponse<UserDef>> => {
  return api.post(AuthEndpointsEnum.REFRESH_TOKEN);
};

const getSocialsRedirect = async (
  socials: "google" | "facebook",
  redirect: "checkout" | "dashboard" = "dashboard"
) => {
  let data;
  if (socials === "google") {
    const response = await axios.get(
      `${ENV.API_HOST}${AuthEndpointsEnum.REDIRECT_GOOGLE}?route=${redirect}`
    );
    data = response.data;
  }
  if (socials === "facebook") {
    const response = await axios.get(
      `${ENV.API_HOST}${AuthEndpointsEnum.REDIRECT_FACEBOOK}?route=${redirect}`
    );
    data = response.data;
  }
  return data;
};

export const authApi = {
  login,
  logout,
  createSeeker,
  updateUser,
  getUser,
  requestResetPassword,
  resetPassword,
  refreshAccessToken,
  getSocialsRedirect,
  resendVerificationLink,
  verifyAccount,
};
