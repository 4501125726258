import { RootState } from "@app/redux/root-reducer";
import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: RootState) => state.auth;

export const userSelector = createSelector(selectSelf, state => state.user);
export const isAuthenticateSelector = createSelector(
  selectSelf,
  state => state.isAuthenticated
);
