import React, { ReactNode } from "react";

import styles from "./BasketLayout.module.scss";
import { closeBasket } from "@app/features/basket/redux/basket.slice";
import { useDispatch } from "react-redux";

interface BasketLayoutProps {
  children: ReactNode;
}

const BasketLayout: React.FC<BasketLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      dispatch(closeBasket());
    }
  };
  return (
    <div className={styles.backdrop} onClick={handleBackdropClick}>
      <div className={styles.wrapper}>{children}</div>;
    </div>
  );
};

export default BasketLayout;
