import React from "react";

import { Formik, Form } from "formik";
import CardItem from "@app/features/payment/components/payment-method/CardItem";

const CardList = () => {
  const TEST_CARDS = [
    {
      id: 1,
      cardNumber: "1234567812345678",
      expiresAt: "07/2024",
      cardProvider: "visa",
    },
    {
      id: 2,
      cardNumber: "098456983852345",
      expiresAt: "09/2024",
      cardProvider: "master",
    },
    {
      id: 3,
      cardNumber: "123463456745345678",
      expiresAt: "07/2024",
      cardProvider: "visa",
    },
  ];

  const initialValues = {
    card: "",
  };
  const handleSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {TEST_CARDS.map(card => (
          <CardItem card={card} key={card.id} />
        ))}
      </Form>
    </Formik>
  );
};

export default CardList;
