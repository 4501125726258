/* eslint-disable @typescript-eslint/camelcase */
import React from "react";

import styles from "./SignInPage.module.scss";

import CreateAccountLayout from "../CreateAccount/components/CreateAccountLayout";

import SignInForm from "@app/features/auth/screens/SignInPage/components/SignInForm";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";

const SignInPage = () => {
  return (
    <div className={styles.wrapper}>
      <CreateAccountLayout>
        <SignInForm
          title="Sign In"
          backArrow
          pathOnSuccess={AuthPathsEnum.HOME}
          pathToRegister={AuthPathsEnum.CREATE_SEEKER}
          redirect="dashboard"
          forgetPasswordLink={AuthPathsEnum.REQUEST_RESET_PASSWORD}
        />
      </CreateAccountLayout>
    </div>
  );
};

export default SignInPage;
