import React, { useEffect } from "react";

import Loading from "@app/components/atoms/Loading/Loading";
import Section from "@app/components/layouts/Section/Section";
import SessionsHome from "@app/components/renderings/Sessions/SessionsHome";
import { ThemeEnum } from "@app/constants/theme.constants";
import { CategoriesEndpointsEnum } from "@app/features/categories/constants/categories.endpoints";
import { CategoriesTypesInterface } from "@app/features/categories/types/categories.types";
import { useRequest } from "@app/hooks/useRequest";

const HomepageCategories = () => {
  const { fetchData, data, loading } = useRequest(
    CategoriesEndpointsEnum.HOME_CATEGORIES
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Section theme={ThemeEnum.WHITE}>
      <Loading isLoading={loading}>
        {data.length > 0 &&
          data.map((item: CategoriesTypesInterface) => (
            <SessionsHome
              theme={ThemeEnum.KHAKI}
              title={item.name}
              subtitle={item.subtitle}
              sessions={item.products}
              key={item.id}
              linkId={item.id}
            />
          ))}
      </Loading>
    </Section>
  );
};

export default HomepageCategories;
