import React, { FormEvent, useState } from "react";

import { Col, Container, Row } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";

import zeroBasketImage from "@app/assets/images/img_zero_basket.svg";
import Button from "@app/components/atoms/Button/Button";
import Modal from "@app/components/atoms/Modal/Modal";
import { ZeroPaymentInterface } from "@app/features/basket/Types/payment.types";
import { basketApi } from "@app/features/basket/api/basket.api";
import PromoCode from "@app/features/basket/components/PromoCode";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

import styles from "./ZeroPayment.module.scss";

const NewCard = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [waiverChecked, setIsWaiverChecked] = useState<boolean>(false);
  const [waiverError, setWaiverError] = useState<boolean>(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!waiverChecked){
      setWaiverError(true);
      return;
    }else{
      setWaiverError(false);
    }

    const data: ZeroPaymentInterface = {
      paymentMethodId: "5ccec534abc84c03b6eb7c2b0466c027",
      // eslint-disable-next-line @typescript-eslint/camelcase
      zero_payment: true,
    };
    try {
      setIsLoading(true);
      await basketApi.placeOrder(data);
      history.push(PaymentPathEnum.CHECKOUT_ORDER_CONFIRMATION);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      history.push(PaymentPathEnum.CHECKOUT_ERROR);
    }
  };

  const handleWaiverChange = (e) => {
    setIsWaiverChecked(!waiverChecked);
    console.log(waiverChecked)
  }

  return (
    <>
      <Modal visible={isLoading} title="Processing..." headerCenter showSpinner>
        <p className={styles.modalText}>
          Please wait don&apos;t refresh your browser. <br />
          We can&apos;t wait for you to get a taste of what we have to offer!
        </p>
      </Modal>

      <Container
        fluid
        style={{
          padding: "0",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Row className={styles.row}>
          <Col>
            <ReactSVG src={zeroBasketImage} className={styles.image} />
            <p className={styles.title}>
              One last step: Please click complete order to be able to enjoy
              your classes
            </p>
            <p className={styles.subtitle}>
              Our expert gift givers are dedicated to helping you find health
              and happiness, wherever you are on life’s journey.
            </p>

            <form onSubmit={onSubmit}>
              <div style={{marginTop: 20}}>
                <input
                  name="checkout-waiver" type="checkbox" id="checkout-waiver"
                       className="plain-checkbox" value={waiverChecked ? 'true' : 'false'} onChange={handleWaiverChange}/>
                <label htmlFor="checkout-waiver">
                  I have read the Kaylo
                  <a target="_blank" href="/acceptable-usage-policy" id="">disclaimer</a>
                  and understand that I am responsible for my own safety during this class
                </label>
                <div className={`waiverError ${waiverError ? 'show': ''}`}>You must accept the Kaylo disclaimer before purchasing</div>
              </div>


              <div className={styles.controls}>
                <Button
                  label="complete order"
                  type="submit"
                  fullWidth
                  loading={isLoading}
                />
              </div>
            </form>
          </Col>
        </Row>
        <Row className={styles.secondRow}>
          <Col>
            <PromoCode />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewCard;
