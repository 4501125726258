import React, { useState } from "react";
import { validateEnum } from "@app/helpers/util.helpers";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import cx from "classnames";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { Document } from "@contentful/rich-text-types";
import RichText from "@app/components/renderings/RichText/RichText";

import styles from "./EmbedVideo.module.scss";
import ReactPlayer from "react-player";

import { getYouTubeVideoAspectRatio } from "./Providers/YouTubeVideo";
import { getVimeoVideoAspectRatio } from "./Providers/VimeoVideo";

enum Providers {
  YOUTUBE = "YouTube",
  VIMEO = "Vimeo"
}

enum VIDEO_SIZES {
  SMALL = "Small",
  ARTICLE_SIZE = "Article Size",
  FULL_WIDTH = "Full Width"
}

interface EmbedVideoProps {
  title: string,
  description: Document
  provider: string,
  link: string
  autostart: boolean,
  show_controls: boolean,
  loop: boolean,
  muted: boolean,
  video_style: string
}


const EmbedVideo = (({title, description, provider, link, autostart, show_controls, loop, muted, video_style}: EmbedVideoProps) => {

  const [loaded, setLoaded] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(56.5);

  function getVideoAspectRatio(provider) {
    let aspectRatioPromise;
    if(provider == Providers.YOUTUBE) {
      aspectRatioPromise = getYouTubeVideoAspectRatio(link)
    } else if (provider == Providers.VIMEO) {
      aspectRatioPromise = getVimeoVideoAspectRatio(link)
    }
    aspectRatioPromise.then((aspectRatio) => {
      setAspectRatio(aspectRatio);
      setLoaded(true);
    }).catch((reason) => {
      setLoaded(true)
    })
  }

  if(validateEnum(Providers, provider)) {
    getVideoAspectRatio(provider)
    if(!loaded) {
      return (
        <div className={cx(styles.spinner)}>
          <LoadingSpinner/>
        </div>
      )
    }
    const padding = aspectRatio + "%";
    let selectedStyle = styles.full_width_video;
    if(video_style == VIDEO_SIZES.SMALL) {
      selectedStyle = styles.small_video;
    } else if(video_style == VIDEO_SIZES.ARTICLE_SIZE) {
      selectedStyle = styles.article_width_video
    }
    return (
      <Section theme={ThemeEnum.KHAKI} withSeparatorSpacing={true} className={styles.center}>
        <div className={cx(styles.container_outer, selectedStyle)}>
          <div className={cx(styles.title)}>{ title }</div>
          <div className={cx(styles.container)} style={{paddingTop: padding}}>
            <ReactPlayer
              url={link}
              className={cx(styles.video)}
              controls={show_controls}
              playing={autostart}
              loop={loop}
              muted={muted}
              width="100%"
              height="100%"
            />
          </div>
          <div>
            <RichText
              theme={ThemeEnum.KHAKI}
              text={description}
              align={'left'}
              wrapContent={false}
              classname={cx(styles.rich_text)}
            />
          </div>
        </div>
      </Section>
    )
  }
  return null;
})

export default EmbedVideo;