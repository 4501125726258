import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import {
  isAuthenticateSelector,
  userSelector,
} from "@app/features/auth/redux/auth.selectors";
import {
  basketItemsLisWithoutPromoSelector,
  basketPromotionLineItem,
  basketQuantitySelector,
  totalAmountSelector,
} from "@app/features/basket/redux/BasketSelectors";
import { requestGetCart } from "@app/features/basket/redux/basket.slice";
import BackButton from "@app/features/payment/components/BackButton";
import CustomerInformation from "@app/features/payment/components/CustomerInformation";
import CustomerSupport from "@app/features/payment/components/CustomerSupport";
import SummaryItem from "@app/features/payment/components/SummaryItem";

import styles from "./Summary.module.scss";

const Summary: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  ...rest
}) => {
  const history = useHistory();
  const totalPrice = useSelector(totalAmountSelector);
  const isAuthenticated = useSelector(isAuthenticateSelector);
  const user = useSelector(userSelector);
  const promoCode = useSelector(basketPromotionLineItem);
  const basketLength = useSelector(basketQuantitySelector);
  const lineItems = useSelector(basketItemsLisWithoutPromoSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetCart());
  }, [dispatch]);

  return (
    <div {...rest}>
      <BackButton
        title="Continue shopping"
        onClick={() => history.push("/search")}
      />
      <p className={styles.title}>
        Kaylo
        <br />
        Life
      </p>

      <p className={styles.orderSummary}>Order summary</p>
      <div className={styles.cardList}>
        {basketLength ? (
          lineItems.map(item => <SummaryItem basketItem={item} key={item.id} />)
        ) : (
          <LoadingSpinner isSmall />
        )}

        {promoCode && (
          <div className={styles.discount}>
            <span className={styles.discountTitle}>Discount</span>
            <span className={styles.discountAmount}>
              {" "}
              -&#163;{Math.abs(Number(promoCode?.price?.unitPrice))}
            </span>
          </div>
        )}
      </div>

      <div className={styles.estimatedTotal}>
        <span className={styles.totalTitle}>Estimated total</span>
        <span className={styles.totalPrice}>&#163;{totalPrice}</span>
      </div>
      <>
        {isAuthenticated && user ? <CustomerInformation user={user} /> : null}
      </>
      <CustomerSupport />
    </div>
  );
};
export default Summary;
