import React from "react";
import styles from "./Header.module.scss";
import { CgClose } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import logo from "@app/assets/images/kaylo-logo.svg";

const Header = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/");
  };

  return (
    <div className={styles.wrapper}>
      <ReactSVG src={logo} />
      <CgClose onClick={() => handleClick()} />
    </div>
  );
};

export default Header;
