import React, { memo } from "react";

import ReactGA from "react-ga";
import { useSelector } from "react-redux";

import FooterContentful from "@app/components/renderings/Footer/FooterContentful";
import Navigation from "@app/components/renderings/Navigation/Navigation";
import { ENV } from "@app/constants/config";
import { getNavigationLinks } from "@app/helpers/navigation.helpers";
import { getSubNavigationLinks } from "@app/helpers/subnavigation.helpers";
import useResetScroll from "@app/hooks/useResetScroll";
import { RootState } from "@app/redux/root-reducer";

import styles from "./DefaultLayout.module.scss";
import Subscription from "@app/features/subscribe/Subscription";

const DefaultLayout = memo(({ children }) => {
  useResetScroll();
  ReactGA.pageview(window.location.pathname);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return (
    <div className={styles.container}>
      <Navigation navItems={getNavigationLinks(isAuthenticated)} />
      <div className={styles.content}>{children}</div>
      <FooterContentful entryID={ENV.CONTENTFUL_FOOTER} />
    </div>
  );
});

export default DefaultLayout;
