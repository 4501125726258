import React, { memo, useState, useEffect, useCallback } from "react";

import cx from "classnames";
import _throttle from "lodash/throttle";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "@app/assets/images/logo.svg";
import { ReactComponent as WhiteLogo } from "@app/assets/images/whiteLogo.svg";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";
import { NavigationItemType } from "@app/types/navigation.types";

import styles from "./Navigation.module.scss";
import NavigationActions from "./components/NavigationActions/NavigationActions";
import NavigationButtons from "./components/NavigationButtons/NavigationButtons";
import NavigationItems from "./components/NavigationItems/NavigationItems";
import NavigationMobile from "./components/NavigationMobile/NavigationMobile";
import MobileMenu from "./components/NavigationMobile/components/MobileMenu/MobileMenu";
import NavigationProfile from "./components/NavigationProfile/NavigationProfile";

export interface NavigationProps {
  navItems: NavigationItemType[];
}

const SCROLL_HEIGHT = 64;

const Navigation = memo(({ navItems }: NavigationProps) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [theme, setTheme] = useState<ThemeEnum>(ThemeEnum.KHAKI);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const location = useLocation();

  const isHome = location.pathname === "/";

  const onScroll = useCallback(() => {
    if (!mobileMenuOpen && isHome) {
      const scrollHeight = document.body.getBoundingClientRect().top * -1;

      if (scrollHeight > SCROLL_HEIGHT) {
        setTheme(ThemeEnum.KHAKI);
      } else {
        setTheme(ThemeEnum.KHAKI);
      }
    }
  }, [isHome, mobileMenuOpen]);

  const toggleMenu = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen);
  }, [mobileMenuOpen]);

  const handleHeaderTheme = useCallback(() => {
    if (isHome && !mobileMenuOpen) {
      setTheme(ThemeEnum.KHAKI);
    } else {
      setTheme(ThemeEnum.KHAKI);
    }
  }, [isHome, mobileMenuOpen]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    handleHeaderTheme();
    if (isHome) {
      const throttledScroll = _throttle(onScroll, 100);
      window.addEventListener("scroll", throttledScroll);
      return () => window.removeEventListener("scroll", throttledScroll);
    }
  }, [handleHeaderTheme, isHome, onScroll]);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  return (
    <>
      {!matches.tabletLandscape && (
        <MobileMenu
          toggleMenu={toggleMenu}
          isVisible={mobileMenuOpen}
          navItems={navItems}
        />
      )}
      <header
        className={cx(styles.header, getTheme(theme), {
          [styles.sticky]: !isHome,
        })}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          {matches.tabletLandscape && <NavigationItems navItems={navItems} />}
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttons}>
              <NavigationActions />
              {matches.tabletLandscape && (
                <>
                  {isAuthenticated ? (
                    <NavigationProfile />
                  ) : (
                    <NavigationButtons />
                  )}
                </>
              )}
              {!matches.tabletLandscape && (
                <NavigationMobile
                  menuOpen={mobileMenuOpen}
                  toggleMenu={toggleMenu}
                />
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
});

export default Navigation;
