import React from "react";
import styles from "./ExperienceList.module.scss";
import { WorkshopAboutInterface } from "@app/features/workshops/types/about.interface";
import { useHistory } from "react-router-dom";
import {hexToRGB} from '@app/helpers/util.helpers';

interface ExperiencesList {
  experiences: WorkshopAboutInterface["experiences"];
}

const ExperiencesList: React.FC<ExperiencesList> = ({ experiences }) => {
  const history = useHistory();
  const handleClick = (id: number) => {
    history.push(`/experience/${id}`);
  };
  const getExperiences = () =>
    experiences.map(experience => (
      <span
        className={styles.experience}
        style={{backgroundColor: hexToRGB(experience.color, 0.5) || 'none'}}
        key={experience.id}
        onClick={() => handleClick(experience.id)}
      >
        {experience.name}
      </span>
    ));

  return <div className={styles.experiences}>{getExperiences()}</div>;
};

export default ExperiencesList;
