import React from "react";

import {WorkshopSessionsInterface} from "@app/features/workshops/types/sessions.interface";
import {formatDate} from "@app/helpers/formatDate";

import styles from "./WorkshopSessionsItem.module.scss";

interface WorkshopSessionsItemTypes {
  session: WorkshopSessionsInterface;
  showSession: boolean
  sessionNumber: number;
}

const WorkshopSessionsItem: React.FC<WorkshopSessionsItemTypes> =
  ({
     session,
     showSession,
     sessionNumber,
   }) => {
    return (
      <div className={styles.container}>
        {session.image && (
          <img src={session.image} alt={session.name} className={styles.image}/>
        )}
        <div className={styles.content}>
          {showSession ? (<div className={styles.header}>
            <span className={styles.count}>Session {sessionNumber + 1}</span>
            <span className={styles.duration}>
              {formatDate(session.startAt, "EEE d LLL - h:mmb")} (GMT)
            </span>
          </div>) : ''}
          <p className={styles.title}>{session.name}</p>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{__html: session.shortDescription}}
          />
        </div>
      </div>
    );
  };

export default WorkshopSessionsItem;
