import React, { memo, useMemo, useState } from "react";

import cx from "classnames";
import { useMedia } from "react-media";
import { Link } from "react-router-dom";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import {
  IconChevronDown,
  IconChevronUp,
} from "@app/components/atoms/Icon/Icon";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { NavigationItemType } from "@app/types/navigation.types";

import SubNavigation from "../SubNavigation/SubNavigation";
import styles from "./NavigationItem.module.scss";

export interface NavigationItemProps extends NavigationItemType {
  subMenu?: NavigationItemType[];
  isActive?: boolean;
  navItemClassName?: string;
}

const NavigationItem = memo(
  ({
    label,
    path,
    subMenu,
    isActive,
    navItemClassName,
  }: NavigationItemProps) => {
    const [expandMenu, setExpandMenu] = useState<boolean>(true);
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const hasSubMenu = subMenu && subMenu.length > 0;

    const desktopIsActive = matches.tabletLandscape && isActive;
    const mobileMenuExpanded = !matches.tabletLandscape && expandMenu;
    const spacingBottom = useMemo(() => isActive && hasSubMenu, [
      hasSubMenu,
      isActive,
    ]);

    return (
      <>
        <li
          className={cx(styles.navItem, navItemClassName, styles.spacingBottom)}
        >
          <Link
            className={cx(styles.navItemLink, { [styles.active]: false })}
            to={path}
          >
            {matches.tabletLandscape ? (
              <Title level={3} className={styles.linkText} isMobile>
                {label}
              </Title>
            ) : (
              <Subtitle level={3} className={styles.linkText}>
                {label}
              </Subtitle>
            )}
          </Link>
          {/* {!matches.tabletLandscape && hasSubMenu && (
            // <CircularButton
            //   onClick={() => setExpandMenu(!expandMenu)}
            //   className={cx(styles.menuDrop, { [styles.activeIcon]: isActive })}
            //   borderless
            // >
            //   {expandMenu ? <IconChevronUp /> : <IconChevronDown />}
            // </CircularButton>
          )} */}
        </li>

        {/* {hasSubMenu && (desktopIsActive || mobileMenuExpanded) && (
          <SubNavigation navItems={subMenu as NavigationItemType[]} />
        )} */}
      </>
    );
  }
);

export default NavigationItem;
