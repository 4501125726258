export function getVimeoVideoIdFromUrl(url: string) {
  const idRegex = new RegExp('^.*vimeo.com\\/([a-zA-Z0-9]*)?[/?]?.*')
  const matches = url.match(idRegex);
  if(matches) {
    return matches[1]
  }
  return null;
}

export function getVimeoVideoInfo(url: string): Promise<object> {
  const id = getVimeoVideoIdFromUrl(url);
  if(id) {
    let api = "https://vimeo.com/api/v2/video/" + id + ".json";
    return fetch(api).then((response) => { return response.json()})
  }
  return new Promise((resolve, reject) => {
    reject()
  })
}

export function getVimeoVideoAspectRatio(url: string) {
  return new Promise((resolve, reject) => {
    getVimeoVideoInfo(url).then((response) => {
      resolve(response[0].height / response[0].width * 100);
    }).catch(() => {
      reject()
    });
  });
}