import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basketApi } from "@app/features/basket/api/basket.api";
import { DiscountTypes } from "@app/features/basket/Types/discount.types";
import { requestGetCart } from "@app/features/basket/redux/basket.slice";

export const sendDiscountCode = createAsyncThunk(
  "discount/sendDiscountCode",
  async (discountCode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await basketApi.sendDiscountCode(discountCode);
      if (response.data.error) {
        return rejectWithValue(response.data.error);
      }

      if (response.status === 200) {
        dispatch(requestGetCart());
      }

      return discountCode;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const removeDiscountCode = createAsyncThunk(
  "discount/removeDiscountCode",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      return await basketApi.removeDiscountCode(code);
    } catch (err) {
      return rejectWithValue(err.response.data.errors[0].detail);
    } finally {
      dispatch(requestGetCart());
    }
  }
);

const initialState: DiscountTypes = {
  discountCode: "",
  loading: false,
  error: "",
};

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    addDiscountCode(state, action) {
      state.discountCode = action.payload;
    },
    clearDiscountCode(state) {
      state.discountCode = "";
      state.loading = false;
      state.error = "";
    },
    clearError(state) {
      state.error = "";
    },
  },
  extraReducers: {
    [sendDiscountCode.pending.toString()]: state => {
      state.loading = true;
      state.error = "";
      state.discountCode = "";
    },
    [sendDiscountCode.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.discountCode = action.payload;
    },
    [sendDiscountCode.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [removeDiscountCode.pending.toString()]: state => {
      state.error = "";
      state.loading = true;
    },
    [removeDiscountCode.fulfilled.toString()]: state => {
      state.loading = false;
      state.discountCode = "";
    },
    [removeDiscountCode.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.discountCode = "";
    },
  },
});

export const {
  addDiscountCode,
  clearDiscountCode,
  clearError,
} = discountSlice.actions;
export default discountSlice.reducer;
