import React, { memo, useState, useRef, useContext } from "react";

import { Col, Row } from "react-grid-system";

import Loading from "@app/components/atoms/Loading/Loading";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DynamicSection from "@app/components/layouts/DynamicSection/DynamicSection";
import Section from "@app/components/layouts/Section/Section";
import RichTextContentful from "@app/components/renderings/RichText/RichTextContentful";
import { StaticPageComponentsDef } from "@app/components/renderings/StaticPageLoader/StaticPageLoader";
import { ContentModel } from "@app/constants/contentful.constants";
import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { validateEnum } from "@app/helpers/util.helpers";

interface ArticlePageComponentsProps {
  contentfulId: string;
}

interface ArticleContentModel {
  pageComponents: StaticPageComponentsDef[];
}


const ArticlePageComponents = (
  ({ contentfulId }: ArticlePageComponentsProps) => {
    const { entry, loading } = useSingleEntry<ArticleContentModel>(
      contentfulId
    );

    return (
      <Loading isLoading={loading}>
        {entry?.pageComponents?.map(section => {
          const contentType = section?.sys?.contentType?.sys?.id;
          const sectionName = validateEnum(ContentModel, contentType)
            ? (contentType as ContentModel)
            : null;

          if (!sectionName) return null;
          const SectionComponent = DynamicSection[sectionName];

          if (!SectionComponent) return null;

          return sectionName === ContentModel.STATIC_RICH_TEXT ? (
            <Section
              theme={ThemeEnum.WHITE}
              withSeparatorSpacing
              key={section.sys.id}
            >
              <ContentWrapper>
                <Row justify="center">
                  <Col xs={12} md={8}>
                    <RichTextContentful
                      entryID={section.sys.id}
                      wrapContent={true}
                    />
                  </Col>
                </Row>
              </ContentWrapper>
            </Section>
          ) : (
            <SectionComponent key={section.sys.id} entryID={section.sys.id} />
          );
        })}
      </Loading>
    );
  }
);

export default ArticlePageComponents;
