export const allowedPaths = [
  "/about",
  "/retreats",
  "/journal",
  "/search",
  "/experiences",
  "/practitioners",
  "/schedule",
  "/dashboard",
  "/",
];
