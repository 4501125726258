import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import { IconSearch, IconBagWithCount } from "@app/components/atoms/Icon/Icon";
import { Title } from "@app/components/atoms/Typography/Typography";
import { basketQuantitySelector } from "@app/features/basket/redux/BasketSelectors";
import { openBasket } from "@app/features/basket/redux/basket.slice";
import { SearchPathsEnum } from "@app/features/search/search";

import styles from "./NavigationActions.module.scss";

const NavigationActions = memo(() => {
  const counter = useSelector(basketQuantitySelector);
  const dispatch = useDispatch();

  return (
    <>
      <CircularButton
        onClick={() => {
          dispatch(openBasket());
        }}
        className={styles.cartButton}
        borderless
      >
        <Title isGold className={styles.counter} level={3} isMobile>
          {counter}
        </Title>
        <IconBagWithCount />
      </CircularButton>
      <CircularButton
        to={SearchPathsEnum.SEARCH}
        className={styles.searchButton}
        borderless
      >
        <IconSearch />
      </CircularButton>
    </>
  );
});

export default NavigationActions;
