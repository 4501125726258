import React, { ReactChild, useState } from "react";
import styles from "./WorkshopTabs.module.scss";
import cx from "classnames";
import { IoIosMoon, IoIosSunny } from "react-icons/io";

interface WorkshopTabsTypes {
  tab1: ReactChild;
  tab2: ReactChild;
}

enum WorkshopTabsEnum {
  ABOUT = "About Journey",
  SESSIONS = "Sessions",
}

const WorkshopTabs: React.FC<WorkshopTabsTypes> = ({ tab1, tab2 }) => {
  const [toggleState, setToggleState] = useState<WorkshopTabsEnum>(
    WorkshopTabsEnum.SESSIONS
  );

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div
      className={cx(styles.container, {
        [styles.expanded]: expanded,
      })}
    >
      <div className={styles.tabBlock}>
        <button
          className={cx(styles.tab, {
            [styles.activeTab]: toggleState === WorkshopTabsEnum.SESSIONS,
          })}
          onClick={() => setToggleState(WorkshopTabsEnum.SESSIONS)}
        >
          {WorkshopTabsEnum.SESSIONS}
          {toggleState === WorkshopTabsEnum.SESSIONS && (
            <IoIosMoon className={styles.icon} />
          )}
        </button>
        <button
          className={cx(styles.tab, {
            [styles.activeTab]: toggleState === WorkshopTabsEnum.ABOUT,
          })}
          onClick={() => setToggleState(WorkshopTabsEnum.ABOUT)}
        >
          {WorkshopTabsEnum.ABOUT}
          {toggleState === WorkshopTabsEnum.ABOUT && (
            <IoIosSunny className={styles.icon} />
          )}
        </button>
      </div>
      <div className={styles.tabContent}>
        <div
          className={cx(styles.content, {
            [styles.activeContent]: toggleState === WorkshopTabsEnum.SESSIONS,
          })}
        >
          {tab1}
        </div>
        <div
          className={cx(styles.content, {
            [styles.activeContent]: toggleState === WorkshopTabsEnum.ABOUT,
          })}
        >
          {tab2}
        </div>
      </div>
      {!expanded && (
        <button
          className={styles.expandButton}
          onClick={() => setExpanded(true)}
        >
          Expand info
        </button>
      )}
      {!expanded && <div className={styles.limiter} />}
    </div>
  );
};

export default WorkshopTabs;
