import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";

import { api } from "@app/api/api";

import { EcommerceEndpointsEnum } from "../constants/ecommerce.endpoints";

const getProduct = (productId: number | string): Promise<AxiosResponse> => {
  const url = generatePath(EcommerceEndpointsEnum.PRODUCT_DETAILS, {
    productId,
  });
  return api.get(url);
};

export const ecommerceApi = {
  getProduct,
};
