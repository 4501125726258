import React, { memo } from "react";

import cx from "classnames";
import { Link } from "react-router-dom";

import Image from "@app/components/atoms/Image/Image";
import Tags, { TagProps } from "@app/components/atoms/Tags/Tags";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import Section from "@app/components/layouts/Section/Section";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getImageUrl } from "@app/helpers/image.helpers";

import styles from "./ExperienceItem.module.scss";

export interface ExperienceItemProps {
  id?: number;
  image?: string | null;
  title?: string;
  description?: string;
  link?: string;
  className?: string;
  tags?: TagProps[];
  isListPage?: boolean;
}

// FIXME: Rename to generic for practitioner as well
const ExperienceItem = memo(
  ({
    image,
    title,
    description,
    link,
    className,
    isListPage,
    tags,
  }: ExperienceItemProps) => {
    const content = (
      <>
        {image && (
          <Image
            url={getImageUrl(image, IMAGE_TYPES.CAROUSEL_ITEM)}
            className={styles.image}
            alt={title}
          />
        )}
        <Section theme={ThemeEnum.KHAKI} className={styles.itemContent}>
          {!!title && <Title level={2}>{title}</Title>}
          {tags && tags?.length > 0 && (
            <div className={styles.tagsWrapper}>
              {tags.slice(0, 3).map(tag => (
                <Tags
                  key={tag.label}
                  className={styles.tag}
                  label={tag.label}
                  color={tag.color}
                  colorHash={tag.colorHash}
                />
              ))}
            </div>
          )}
          {!!description && !tags && (
            <Paragraph level={2} className={styles.description}>
              {description}
            </Paragraph>
          )}
        </Section>
        <div className={styles.gradient} />
        <div className={styles.gradientImg} />
      </>
    );

    return (
      <div
        className={cx(styles.itemWrapper, className, {
          [styles.hasLink]: !!link,
          [styles.listPage]: isListPage,
        })}
      >
        {link ? <Link to={link}>{content}</Link> : content}
      </div>
    );
  }
);

export default ExperienceItem;
