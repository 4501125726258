import React, { memo } from "react";

import cx from "classnames";
import { Container, Row, Col } from "react-grid-system";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { IMAGE_TYPES } from "@app/constants/image";
import {
  ThemeEnum,
  AlignmentSimpleEnum,
  BackgroundRatioEnum,
} from "@app/constants/theme.constants";
import { getImageUrl } from "@app/helpers/image.helpers";

import styles from "./Topic.module.scss";

export interface TopicProps {
  /**
   * Set the component main theme. Values should be either "khaki" or "white" or "imagery". Default value is "khaki"
   */
  theme: ThemeEnum;
  /**
   * Set the main image of the topic component
   */
  image: string;
  /**
   * Set the main heading of the topic content
   */
  title: string;
  /**
   * Set the description paragraph of the topic content
   */
  description: string;
  /**
   * Set the main call to action label text
   */
  CTALabel: string;
  /**
   * Set the main call to action Type either "primary", "secondary" or "tertiary". Default value is "primary"
   */
  CTAType: ButtonTypeEnum;
  /**
   * Set the main call to action link to a specific page
   */
  CTALink: string;
  /**
   * Set the topic content alignment to be "left" or "right". Default value is "left"
   */
  alignment: AlignmentSimpleEnum;
}
const Topic = memo(
  ({
    theme,
    image,
    title,
    description,
    CTALabel,
    CTALink,
    CTAType,
    alignment,
  }: TopicProps) => {
    const backgroundImage = {
      backgroundImage: `url(${getImageUrl(image, IMAGE_TYPES.TOPIC)})`,
    };

    return (
      <Section
        theme={theme}
        backgroundRatio={BackgroundRatioEnum.QUARTER}
        withSeparatorSpacing
      >
        <ContentWrapper>
          <Container fluid>
            <Row
              align="center"
              className={cx({
                [styles.reverseRow]: alignment === AlignmentSimpleEnum.RIGHT,
              })}
            >
              <Col xs={12} md={6}>
                <div className={styles.imageWrapper}>
                  <Image
                    className={styles.topicImage}
                    url={getImageUrl(image, IMAGE_TYPES.TOPIC)}
                    alt={title}
                  />
                  <div
                    className={styles.blurredImage}
                    style={backgroundImage}
                  />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Title level={1} className={styles.title}>
                  {title}
                </Title>
                <Paragraph level={2} className={styles.paragraph}>
                  {description}
                </Paragraph>
                {CTALabel && CTALabel !== "" && (
                  <Button
                    to={CTALink}
                    label={CTALabel}
                    buttonType={CTAType}
                    size={ButtonSizeEnum.LARGE}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Section>
    );
  }
);

export default Topic;
