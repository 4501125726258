import React, { ReactNode, useState, useEffect } from "react";
import styles from "./BackToTop.module.scss";
import { IconChevronUp } from "@app/components/atoms/Icon/Icon";
import Fab from "@material-ui/core/Fab";

let prevScroll = 0;

const BackToTop = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = (scrolled) => {
    if(scrolled > prevScroll) {
      setVisible(false)
    } else if (scrolled < prevScroll) {
      if (scrolled > 300){
        setVisible(true)
      }
      else if (scrolled <= 300){
        setVisible(false)
      }
    }
    prevScroll = scrolled;
  };

  useEffect(() => {
    const onScroll = () => toggleVisible(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={styles.backToTop} onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}}>
      <Fab color='inherit' aria-label={"Back to top"} className={styles.backToTopIcon}>
        <IconChevronUp fontSize='3em'/>
      </Fab>
    </div>
  )
}

export default BackToTop;