import axios, { AxiosResponse } from "axios";

import { ENV } from "@app/constants/config";

type UserSignUpDef = {
  email: string;
  first_name?: string;
  referral?: string;
  tags?: string;
};

/** Setup an API instance */
export const apiEmail = axios.create({
  baseURL: ENV.API_EMAIL_HOST,
  headers: { "Content-Type": "application/json" },
});

const NEWSLETTER_SUBSCRIBE_URL = "newsletter";

export const signUp = (data: UserSignUpDef): Promise<AxiosResponse> => {
  return apiEmail.post(NEWSLETTER_SUBSCRIBE_URL, data);
};
