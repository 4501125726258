import React, { useEffect, useState } from "react";
import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "@app/components/atoms/Button/Button";

import styles from "./PromoCode.module.scss";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import {
  clearError,
  removeDiscountCode,
  sendDiscountCode,
} from "@app/features/basket/redux/discount.slice";
import {
  discountCodeSelector,
  discountErrorSelector,
  discountLoadingSelector,
} from "@app/features/basket/redux/discount.selectors";

import { basketItemsListSelector } from "@app/features/basket/redux/BasketSelectors";

const PromoCode = () => {
  const dispatch = useDispatch();
  const discountCode = useSelector(discountCodeSelector);
  const discountError = useSelector(discountErrorSelector);
  const discountLoading = useSelector(discountLoadingSelector);
  const [lenThree, setLenThree] = useState(false);
  const lineItems = useSelector(basketItemsListSelector);

  const [code, setCode] = useState(discountCode);

  const handleAddCode = async () => {
    dispatch(sendDiscountCode(code));
  };
  const getPromotionId = () => {
    if (lineItems.length) {
      return lineItems.find(item => item.type === "promotion");
    }
    return null;
  };

  useEffect(() => {
    if (code !== discountCode) {
      setCode(discountCode);
    }
  }, [discountCode]);

  useEffect(() => {
    clearError();
  }, [dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const codeString = e.target.value;
    setCode(codeString);
    if (codeString.length > 2) {
      setLenThree(true);
    } else {
      setLenThree(false);
    }
  };
  const handleRemoveCode = () => {
    const item = getPromotionId();
    if (item?.id) {
      dispatch(removeDiscountCode(item.id));
    }
    setLenThree(false);
    setCode("");
  };
  return (
    <div className={styles.promo}>
      <p className={styles.title}>Apply discount code</p>
      <div className={styles.promoForm}>
        <input
          type="text"
          className={cx(styles.input, {
            [styles.filled]: discountCode,
          })}
          placeholder="Type your code here"
          value={code}
          onChange={e => handleChange(e)}
        />

        {!discountCode && (
          <Button
            label="Apply"
            buttonType={ButtonTypeEnum.SECONDARY}
            size={ButtonSizeEnum.LARGE}
            onClick={handleAddCode}
            className={cx(styles.apply, { [styles.isGold]: lenThree })}
            isDisabled={!lenThree}
            loading={discountLoading}
          />
        )}
        {discountCode && (
          <Button
            label="Remove"
            buttonType={ButtonTypeEnum.SECONDARY}
            className={styles.cancel}
            onClick={handleRemoveCode}
            loading={discountLoading}
          />
        )}
      </div>
      {discountError && <span className={styles.error}>{discountError}</span>}
    </div>
  );
};

export default PromoCode;
