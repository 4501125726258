import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import Loading from "@app/components/atoms/Loading/Loading";
import { ThemeEnum } from "@app/constants/theme.constants";
import BasketCard from "@app/features/basket/components/BasketCard";
import PromoCode from "@app/features/basket/components/PromoCode";
import BasketLayout from "@app/features/basket/layouts/BasketLayout";
import {
  closeBasket,
  requestGetCart,
} from "@app/features/basket/redux/basket.slice";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";
import { currencyFormat } from "@app/helpers/currencyFormat";
import { RootState } from "@app/redux/root-reducer";

import styles from "./Basket.module.scss";
import BasketTitle from "./components/BasketTitle";
import {
  basketItemsLisWithoutPromoSelector,
  basketPromotionLineItem,
  basketQuantitySelector,
  isBasketOpenSelector,
  basketLoadingSelector,
  totalAmountSelector,
} from "./redux/BasketSelectors";
import CustomerSupport from "@app/features/payment/components/CustomerSupport";

const Basket = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isBasketOpen = useSelector(isBasketOpenSelector);
  const basketLength = useSelector(basketQuantitySelector);
  const estimatedTotal = useSelector(totalAmountSelector);
  const isLoading = useSelector(basketLoadingSelector);
  const lineItems = useSelector(basketItemsLisWithoutPromoSelector);
  const promoCode = useSelector(basketPromotionLineItem);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const handleCheckoutButton = () => {
    if (basketLength > 0) {
      dispatch(closeBasket());
      if (isAuthenticated) {
        return history.push(PaymentPathEnum.CHECKOUT_NEW_PAYMENT);
      }
      history.push(PaymentPathEnum.CHECKOUT_SIGN_IN);
    }
  };

  useEffect(() => {
    if (isBasketOpen) {
      dispatch(requestGetCart());
    }
  }, [dispatch, isBasketOpen]);
  useEffect(() => {
    dispatch(requestGetCart());
  }, [dispatch]);

  return (
    <>
      {isBasketOpen && (
        <BasketLayout>
          <BasketTitle />
          <div className={styles.content}>
            <div className={styles.basketItems}>
              {basketLength ? (
                lineItems.map(item => <BasketCard card={item} key={item.id} />)
              ) : (
                <p className={styles.empty}>No items in basket</p>
              )}
              <Loading isLoading={isLoading} theme={ThemeEnum.WHITE} isSmall />
            </div>

            <PromoCode />
            {promoCode && (
              <div className={styles.promotion}>
                <span>Discount</span>
                <span>
                  -&#163; {Math.abs(Number(promoCode?.price?.unitPrice))}
                </span>
              </div>
            )}
            <p className={styles.total}>
              <span className={styles.estimatedTitle}>Total</span>
              {estimatedTotal && (
                <span className={styles.estimatedPrice}>
                  {currencyFormat(estimatedTotal)}
                </span>
              )}
            </p>

            <Button
              fullWidth
              label="Checkout"
              type="button"
              size={ButtonSizeEnum.LARGE}
              className={styles.checkout}
              onClick={handleCheckoutButton}
            />
            <CustomerSupport />
          </div>
        </BasketLayout>
      )}
    </>
  );
};

export default Basket;
