import React, { useEffect, useState } from "react";
import i18next from "i18next";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import Loading from "@app/components/atoms/Loading/Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from "swiper/core";
import styles from "./RotatingBanner.module.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/autoplay";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import "./RotatingStyles.scss";
import BannerInner from "@app/components/atoms/RotatingBanner/components/BannerInner";
import cx from "classnames";
import { useRequest } from "@app/hooks/useRequest";
import { BannerEndpointsEnum } from "@app/components/atoms/RotatingBanner/constants/banner.endpoints";
import { BannerTypes } from "@app/components/atoms/RotatingBanner/types/banner.types";
import { AutoplayOptions } from "swiper/types/components/autoplay";

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

const RotatingBanner = (props) => {
  const { error, loading, data, fetchData } = useRequest(
    `${BannerEndpointsEnum.ROTATING_BANNER}?banner_id=${props.entryID}`
  );

  const autoplayOptions: AutoplayOptions = {
    // delay: 8000,
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    function handleResize() {
      if(setWindowWidth) {
        setWindowWidth(window.innerWidth);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  if (error || !data.length) {
    return null;
  }

  return (
    <Section theme={ThemeEnum.WHITE}>
      <Loading isLoading={loading}>
        <ContentWrapper className={styles.wrapper}>
          <Swiper
            navigation
            pagination={{
              clickable: true,
              modifierClass: "pagination-banner",
              bulletClass: "pagination-bullet",
              bulletActiveClass: "pagination-active",
              clickableClass: "pagination-clickable",
              currentClass: "pagination-current",
            }}
            className={styles.banner}
            // autoplay={autoplayOptions}
            speed={1400}
          >
            {data.map((item: BannerTypes, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className={cx(styles.slider, {
                    [styles.left]: item?.alignment === "left",
                    [styles.right]: item?.alignment === "right",
                  })}
                  style={{
                    backgroundImage: windowWidth > 700 ? `url(${item?.image})` : 'none',
                    backgroundColor: item.mobileColor ? item.mobileColor : '#FFFFFF',
                  }}
                >
                  <BannerInner content={item} />
                  <div className={cx(styles.swipeInfo, styles.text)}>{i18next.t("banner.swipeToSeeMore")}</div>
                </SwiperSlide>
              );
            })}
            <div className={cx(styles.swipeInfoMobile, styles.text)}>{i18next.t("banner.swipeToSeeMore")}</div>
          </Swiper>
        </ContentWrapper>
      </Loading>
      <div className={cx(styles.greenLine)}>&nbsp</div>
    </Section>
  );
};

export default RotatingBanner;
