import React from "react";

import cx from "classnames";

import Avatar, {
  AvatarProps,
  AvatarSize,
} from "@app/components/atoms/Avatar/Avatar";
import { IconAudio, IconAudioMuted } from "@app/components/atoms/Icon/Icon";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";

import styles from "./VideoOverlay.module.scss";

export interface VideoOverlayProps extends AvatarProps {
  name?: string;
  showLargeOverlay?: boolean;
  showAudioToggle?: boolean;
  hasAudio?: boolean;
  onToggleAudio?: () => void;
  hasVideo?: boolean;
}

const VideoOverlay = ({
  name,
  showAudioToggle,
  hasAudio,
  showLargeOverlay,
  onToggleAudio,
  hasVideo,
  ...avatarProps
}: VideoOverlayProps) => {
  return (
    <div
      className={cx(styles.overlay, getTheme(ThemeEnum.KHAKI), {
        [styles.showLargeOverlay]: showLargeOverlay,
      })}
    >
      {!hasVideo && (
        <div className={cx(styles.avatar, getTheme(ThemeEnum.WHITE))}>
          <Avatar {...avatarProps} avatarSize={AvatarSize.LARGE} />
        </div>
      )}
      <div className={cx(styles.content, { [styles.gradient]: hasVideo })}>
        {!!name && (
          <Subtitle level={3} className={styles.name}>
            {name}
          </Subtitle>
        )}
        {showAudioToggle && (
          <button
            className={cx(styles.toggleMute, {
              [styles.notClickableButton]: typeof onToggleAudio !== "function",
            })}
            onClick={onToggleAudio}
          >
            {hasAudio ? <IconAudio /> : <IconAudioMuted />}
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoOverlay;
