import React, { memo, useMemo } from "react";

import cx from "classnames";
import { Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import SessionCard from "@app/components/atoms/SessionCard/SessionCard";
import SwiperCarousel, {
  SwiperBreakpoints,
  SwiperSlide,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES, ScreenSizes } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ClassesDef } from "@app/features/classes/classes";

import { SearchEndpointsEnum } from "@app/features/search/constants/search.endpoints";
import { Title } from "../../atoms/Typography/Typography";
import styles from "./SessionsHome.module.scss";
import { useHistory } from "react-router-dom";
import SeeMoreCard from "@app/features/categories/components/SeeMoreCard";

export interface SessionsProps {
  theme?: ThemeEnum;
  subtitle?: string;
  title?: string;
  sessions: ClassesDef[];
  linkId?: string;
}

const Sessions = memo(
  ({
    theme = ThemeEnum.KHAKI,
    title,
    subtitle,
    sessions,
    linkId,
  }: SessionsProps) => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const history = useHistory();
    const slidesNumber = 3;

    const swiperBreakpoints: SwiperBreakpoints = {
      [ScreenSizes.LARGE]: {
        slidesPerView: slidesNumber,
      },
      [ScreenSizes.SMALL]: {
        slidesPerView: 2,
      },
    };
    const listToRender = (limit = 5) => {
      return sessions.slice(0, limit);
    };

    const isSliderEnabled = useMemo(() => sessions.length > slidesNumber, [
      sessions.length,
    ]);

    const handleSectionClick = (linkedId: string | undefined) => {
      if (linkedId) {
        history.push(`${SearchEndpointsEnum.FILTERS}?categories[]=${linkedId}`);
      }
      return null;
    };

    const getSession = (session: ClassesDef) => {
      return (
        <SessionCard
          session={session}
          className={styles.sessionSlide}
          isShort
          smallOnMobile={false}
        />
      );
    };

    return (
      <Section theme={theme} withSeparatorSpacing noBackgroundOverlap>
        <ContentWrapper>
          <Container fluid>
            {!!subtitle && (
              <Title level={3} className={styles.subtitle} isGoldGradient>
                {subtitle}
              </Title>
            )}
            <Title level={2} className={styles.title}>
              {title ?? t("recommendedSessions.defaultTitle")}
            </Title>
          </Container>

          <>
            <SwiperCarousel
              swiperClassname={cx(styles.sessionsContainer, {
                [styles.sessionsSwiper]: isSliderEnabled,
              })}
              swiperBreakpoints={swiperBreakpoints}
              showNavArrows={isSliderEnabled}
              showPagination={isSliderEnabled}
              showMore={
                matches.tabletLandscape ? (
                  <Row justify="end">
                    <Button
                      size={ButtonSizeEnum.TEXT}
                      to={linkId ? "" : SearchEndpointsEnum.FILTERS}
                      label="Show more"
                      onClick={() => handleSectionClick(linkId)}
                    />
                  </Row>
                ) : null
              }
            >
              {listToRender(12)?.map(session => (
                <SwiperSlide key={session.id}>
                  {getSession(session)}
                </SwiperSlide>
              ))}
              <SwiperSlide style={{ height: "100%" }}>
                <SeeMoreCard className={styles.sessionSlide} linkId={linkId} />
              </SwiperSlide>
              {!matches.tabletLandscape && (
                <Row justify="center">
                  <Button
                    size={ButtonSizeEnum.TEXT}
                    to={SearchEndpointsEnum.FILTERS}
                    label="Show more"
                    className={styles.showMore}
                  />
                </Row>
              )}
            </SwiperCarousel>
          </>
        </ContentWrapper>
      </Section>
    );
  }
);

export default Sessions;
