import React from "react";
import CreateAccountLayout from "@app/features/auth/screens/CreateAccount/components/CreateAccountLayout";
import styles from "./ConfirmAccountScreen.module.scss";
import Button from "@app/components/atoms/Button/Button";
import { ReactSVG } from "react-svg";
import kaylologo from "@app/assets/images/kaylologo.svg";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { userSelector } from "@app/features/auth/redux/auth.selectors";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "react-grid-system";
import { authApi } from "@app/features/auth/api/auth.api";

const handleResendEmailButton = async (email: string = "") => {
  await authApi.resendVerificationLink(email);
};

const ConfirmAccountScreen = () => {
  const user = useSelector(userSelector);
  const location = useLocation();
  const history = useHistory();

  const handleBackClick = () => {
    history.push("/");
  };

  return (
    <div className={styles.wrapper}>
      <CreateAccountLayout>
        <Container
          fluid
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className={styles.header}>
            <HiOutlineArrowNarrowLeft
              className={styles.arrow}
              onClick={handleBackClick}
            />
            <ReactSVG className={styles.logo} src={kaylologo} wrapper="span" />
          </div>
          <p className={styles.title}>WELCOME</p>
          <p className={styles.subtitle}>
            Thank you for creating your Kaylo account and joining our community.
          </p>
          <p className={styles.explanation}>
            We&apos;ve sent an email to{" "}
            <strong>
              {user?.email}
              {location?.state?.email}
            </strong>{" "}
            with your verification link.
            <br />
            Please follow the instructions in the email and you&apos;ll be good
            to go.
          </p>

          <p className={styles.check}>
            Didn&apos;t receive it? <br />
            Please check we’ve got the right email address for you, <br /> or
            take a quick look in your spam or promotions folder.
          </p>
          <Button
            className={styles.button}
            label="Resend link"
            onClick={() => handleResendEmailButton(user?.email)}
          />
        </Container>
      </CreateAccountLayout>
    </div>
  );
};

export default ConfirmAccountScreen;
