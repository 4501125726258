import React from "react";

import {Field, ErrorMessage} from "formik";

import styles from "./Checkbox.module.scss";
import TextError from "./TextError";

interface CheckboxInputProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement> {
  name: string;
  label?: string | React.ReactNode;
  labelHtml?: string;
  type: string;
  errors?: string;
}

const Checkbox: React.FC<CheckboxInputProps> = ({
                                                  type,
                                                  name,
                                                  label,
                                                  labelHtml,
                                                  errors,
                                                  ...rest
                                                }) => {
  return (
    <div className={styles.formControl} {...rest}>
      <Field
        type={type}
        id={name}
        name={name}
        placeholder="Type Here"
        className={styles.checkbox}
        {...rest}
      />
      {
        labelHtml ?
          <label htmlFor={name} className={styles.label} dangerouslySetInnerHTML={{__html: labelHtml || ''}}></label> :
          <label htmlFor={name} className={styles.label}>{label}</label>
      }


      <ErrorMessage
        name={name}
        component={TextError}
        className={styles.error}
      />
      {errors && <TextError>{errors}</TextError>}
    </div>
  );
};

export default Checkbox;
