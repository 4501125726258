/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from "react";
import styles from "./ResetPasswordPage.module.scss";
import CreateAccountLayout from "@app/features/auth/screens/CreateAccount/components/CreateAccountLayout";
import FormHeader from "@app/features/auth/screens/ResetPasswordPage/components/FormHeader";
import { Col, Container, Row } from "react-grid-system";
import { Form, Formik, FormikHelpers } from "formik";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import SubmitButton from "@app/features/auth/screens/CreateAccount/components/SubmitButton";
import * as Yup from "yup";
import { passwordRegex } from "@app/features/auth/constants/auth.regex";
import { BsQuestionCircle } from "react-icons/bs";
import { ConfirmationModal } from "@app/components/atoms/Modal/Modal";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/root-reducer";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAppDispatch } from "@app/redux/store";
import qs from "query-string";
import { ResetPasswordDataDef } from "@app/features/auth/types/auth-login.types";
import { resetPassword } from "@app/features/auth/redux/auth.slice";

const ResetPasswordPage = ({ location }: RouteComponentProps) => {
  const { resetPasswordState } = useSelector((state: RootState) => state.auth);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  let invalidToken = false;
  const { token } = qs.parse(location.search);
  // check if token is missing or invalid
  invalidToken = !token || typeof token !== "string";

  const initialValues: ResetPasswordDataDef = {
    token: token as string,
    password: "",
    passwordConfirmation: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, "Password doesn't match requirements"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Password doesn't match")
      .required("Password confirmation is required"),
  });
  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    if (invalidToken) {
      actions.setErrors({
        password: "Invalid token",
        passwordConfirmation: "Invalid token",
      });
      return;
    }
    if (resetPasswordState === "error") {
      actions.setErrors({
        password: "Error",
        passwordConfirmation: "Error",
      });
      return;
    }
    const response = await dispatch(resetPassword(values));
    if (resetPassword.fulfilled.match(response)) {
      setSuccessModalVisible(true);
    }
  };
  const handleModalconfirm = () => {
    setSuccessModalVisible(false);
    history.push(AuthPathsEnum.SIGN_IN);
  };

  return (
    <div className={styles.wrapper}>
      <CreateAccountLayout>
        <FormHeader title="Reset password" backArrow={false} />
        <Container
          fluid
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p className={styles.choose}>Choose a new password</p>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <Row nogutter className={styles.row}>
                <Col>
                  <FormInput
                    isPassword
                    name="password"
                    label="Password"
                    type="password"
                    secondLabel={<BsQuestionCircle className={styles.label} />}
                  />
                </Col>
              </Row>
              <Row nogutter className={styles.row}>
                <Col>
                  <FormInput
                    isPassword
                    name="passwordConfirmation"
                    label="Confirm password"
                    type="password"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubmitButton label="Reset and log in" />
                </Col>
              </Row>
            </Form>
          </Formik>
          <ConfirmationModal
            title="Reset password successful"
            subtitle="Your new password has been set, you can now login with your new password"
            visible={successModalVisible}
            maskClosable={false}
            hideCancel
            onClose={() => setSuccessModalVisible(false)}
            onConfirm={handleModalconfirm}
            headerCenter
          />
        </Container>
      </CreateAccountLayout>
    </div>
  );
};

export default ResetPasswordPage;
