import { RouteItemDef } from "@app/types/route.types";

import { AuthPathsEnum } from "../constants/auth.paths";
import CreateAccount from "../screens/CreateAccount/CreateAccountScreen";
import ConfirmAccountScreen from "../screens/VerifyAccount/ConfirmAccountScreen";
import SignInPage from "@app/features/auth/screens/SignInPage/SignInPage";
import ResetPasswordPage from "@app/features/auth/screens/ResetPasswordPage/ResetPasswordPage";
import RequestResetPasswordPage from "@app/features/auth/screens/ResetPasswordPage/RequestResetPasswordPage";
import ResetPasswordInfo from "@app/features/auth/screens/ResetPasswordPage/ResetPasswordInfo";

const SIGN_IN_SCREEN: RouteItemDef = {
  id: "sign-in",
  path: AuthPathsEnum.SIGN_IN,
  component: SignInPage,
  metaTitle: "signIn.metaTitle",
};

const CREATE_SEEKER_SCREEN: RouteItemDef = {
  id: "create-seeker",
  path: AuthPathsEnum.CREATE_SEEKER,
  component: CreateAccount,
  metaTitle: "createAccount.metaTitle",
};

const REQUEST_RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "request-reset-password",
  path: AuthPathsEnum.REQUEST_RESET_PASSWORD,
  component: RequestResetPasswordPage,
  metaTitle: "requestResetPassword.metaTitle",
};
const REQUEST_RESET_PASSWORD_INFO_SCREEN: RouteItemDef = {
  id: "request-reset-password-info",
  path: AuthPathsEnum.RESET_RESET_PASSWORD_INFO,
  component: ResetPasswordInfo,
  metaTitle: "requestResetPassword.metaTitle",
};

const RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "reset-password",
  path: AuthPathsEnum.RESET_PASSWORD,
  component: ResetPasswordPage,
  metaTitle: "resetPassword.metaTitle",
};

const VERIFY_ACCOUNT_SCREEN: RouteItemDef = {
  id: "verify",
  path: AuthPathsEnum.CONFIRM_ACCOUNT,
  component: ConfirmAccountScreen,
  metaTitle: "Verify Account",
};

const AUTH_ROUTES = [
  SIGN_IN_SCREEN,
  CREATE_SEEKER_SCREEN,
  REQUEST_RESET_PASSWORD_SCREEN,
  REQUEST_RESET_PASSWORD_INFO_SCREEN,
  RESET_PASSWORD_SCREEN,
  VERIFY_ACCOUNT_SCREEN,
];

export default AUTH_ROUTES;
