import React from "react";

import cx from "classnames";

import styles from "./IntensityLevel.module.scss";

interface IntensityLevelTypes
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  level: string;
}

const IntensityLevel: React.FC<IntensityLevelTypes> = ({ level, ...rest }) => {
  return (
    <div className={styles.wrapper} {...rest}>
      <span className={cx(styles.level, styles.low, styles.filled)} />
      <span
        className={cx(styles.level, styles.medium, {
          [styles.filled]: level !== "LOW",
        })}
      />
      <span
        className={cx(styles.level, styles.high, {
          [styles.filled]: level === "HARD",
        })}
      />
    </div>
  );
};

export default IntensityLevel;
