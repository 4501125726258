import React from "react";

import { Container } from "react-grid-system";
import { useHistory, useLocation } from "react-router-dom";

import FormHeader from "@app/features/auth/screens/ResetPasswordPage/components/FormHeader";

import styles from "./ResetPasswordInfoComponent.module.scss";

const ResetPasswordInfoComponent = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Container
      fluid
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <FormHeader
        title="Recover password"
        backArrow
        onArrowClick={() => {
          history.go(-1);
        }}
      />
      <p className={styles.instructions}>
        We have sent instructions to reset your password to the email address
        you have entered <strong>{location?.state?.email}</strong>
        with instructions for resetting your password.
        <br />
        <br />
        If you have not received it within 5 minutes, please check if the email
        address is the same as the one you entered when you created your Kaylo
        account.
        <br />
        <br />
        You can check the email address you used to create your Kaylo account by
        searching for 'Kaylo' in your inbox.
        <br />
        {/* Please follow the steps in the email, and you&apos;ll be back in no time */}
      </p>
    </Container>
  );
};

export default ResetPasswordInfoComponent;
