import React from "react";
import styles from "@app/features/payment/pages/CheckoutRegisterPage.module.scss";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";
import RequestResetPasswordComponent from "@app/features/auth/screens/ResetPasswordPage/components/RequestResetPasswordComponent";

const CheckoutRequestResetPasswordPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left} />
      <div className={styles.right}>
        <RequestResetPasswordComponent
          pathToInfo={PaymentPathEnum.CHECKOUT_RESET_INFO}
        />
      </div>
    </div>
  );
};

export default CheckoutRequestResetPasswordPage;
