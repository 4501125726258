import React from "react";
import {WorkshopSessionsInterface} from "@app/features/workshops/types/sessions.interface";
import styles from "./WorkshopSessionsContent.module.scss";
import WorkshopSessionsItem from "@app/features/workshops/components/WorkshopSessionsItem";

interface WorkshopSessionsContentTypes {
  sessionsData: WorkshopSessionsInterface[];
  showSessions: boolean;
}


const WorkshopSessionsContent: React.FC<WorkshopSessionsContentTypes> =
  ({
     sessionsData,
     showSessions
   }) => {
    return (
      <div className={styles.wrapper}>
        {sessionsData.map((session, idx) => (
          <WorkshopSessionsItem
            session={session}
            showSession={showSessions}
            key={session.id}
            sessionNumber={idx}
          />
        ))}
      </div>
    );
  };

export default WorkshopSessionsContent;
