import React, { memo } from "react";

import { Document } from "@contentful/rich-text-types";

import Loading from "@app/components/atoms/Loading/Loading";
import { useSingleEntry } from "@app/features/contentful/contentful";

import RichText from "./RichText";

import {ThemeEnum} from '@app/constants/theme.constants';

import styles from "./RichText.module.scss";


interface RichTextContentfulProps {
  entryID: string;
  wrapContent?: boolean;
}

interface RichTextDataModel {
  theme: string;
  align: string;
  content: Document;
}

const RichTextContentful = memo(
  ({ entryID, wrapContent }: RichTextContentfulProps) => {
    const { entry, loading } = useSingleEntry<RichTextDataModel>(entryID);

    return (
      <Loading isLoading={loading}>
        {entry && (
          <RichText
            theme={entry.theme as ThemeEnum} 
            text={entry.content}
            wrapContent={wrapContent}
            align={entry.align}
            classname={styles["set-font-size"]}
          />
        )}
      </Loading>
    );
  }
);

export default RichTextContentful;
