import React, { useState } from "react";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { StaticPageComponentsDef } from "@app/components/renderings/StaticPageLoader/StaticPageLoader";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import style from './TimeToRead.module.scss'
import styles from "@app/features/articles/screens/ArticleScreen/ArticleScreen.module.scss";
import { Paragraph } from "@app/components/atoms/Typography/Typography";
import { getWordCount, timeToRead } from "@app/helpers/text.halpers";

interface TimeToReadProps {
  contentfulId: string;
}

interface ArticleContentModel {
  pageComponents: StaticPageComponentsDef[];
}

const TimeToRead = ({contentfulId}: TimeToReadProps) => {
  const { entry, loading } = useSingleEntry<ArticleContentModel>(
    contentfulId
  );

  let words = 0;

  function readPageComponent(component: object) {
    if(component) {
      if(component.hasOwnProperty('content')) {
        component['content'].map(function(item) {
          readPageComponent(item)
        })
      } else if(component.hasOwnProperty('fields')) {
        readPageComponent(component['fields']['content'])
      } else {
        if(component.hasOwnProperty('nodeType') && component['nodeType'] == 'text') {
          words += getWordCount(component['value']);
        }
      }
    }
  }

  if(!loading) {
    entry?.pageComponents.map(function(item) {
      readPageComponent(item)
    });
    const minutes = timeToRead(words);
    return (
      <Paragraph level={2} className={styles.articleMeta}>
        Estimated read time: {minutes} {minutes == 1 ? 'minute' : 'minutes'}
      </Paragraph>
    )
  }

  return (
    <LoadingSpinner isSmall={true} className={style.spinner}/>
  )
}

export default TimeToRead;