import React, { ReactNode } from "react";

import styles from "./CreateAccountLayout.module.scss";

interface CreateAccountLayoutProps {
  children: ReactNode;
}

const CreateAccountLayout: React.FC<CreateAccountLayoutProps> = ({
  children,
}) => {
  return <div className={styles.accountBox}>{children}</div>;
};

export default CreateAccountLayout;
