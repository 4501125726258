import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import styles from "./SubscribeBanner.module.scss";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/root-reducer";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { SubscribeTypesInterface } from "@app/features/subscribe/types/subscribe.types";
import _throttle from "lodash/throttle";

interface SubscribeOpenProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onClose: () => void;
  subscribe: (
    values: SubscribeTypesInterface,
    actions: FormikHelpers<SubscribeTypesInterface>
  ) => void;
}

const SubscribeBanner: React.FC<SubscribeOpenProps> = ({
  onClose,
  subscribe,
}) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    onSubmit: (values, actions) => {
      subscribe(values, actions);
    },
    validationSchema,
  });
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const [isWhite, setIsWhite] = useState(false);
  const SCROLL_HEIGHT = 64;
  const onScroll = useCallback(() => {
    const scrollHeight = document.body.getBoundingClientRect().top * -1;
    if (scrollHeight > SCROLL_HEIGHT) {
      setIsWhite(true);
    } else {
      setIsWhite(false);
    }
  }, []);

  useEffect(() => {
    const throttledScroll = _throttle(onScroll, 100);
    window.addEventListener("scroll", throttledScroll);
    return () => window.removeEventListener("scroll", throttledScroll);
  }, []);

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.noAuth]: !isAuthenticated && !isWhite,
      })}
    >
      <div className={styles.titleWrapper}>
        <p
          className={cx(styles.title, {
            [styles.noAuth]: !isAuthenticated && !isWhite,
          })}
        >
          Join our community
        </p>
        <IoClose
          className={cx(styles.closeMobile, {
            [styles.noAuth]: !isAuthenticated,
          })}
          onClick={onClose}
        />
      </div>
      <form className={styles.controls} onSubmit={formik.handleSubmit}>
        <div className={styles.inputWrapper}>
          <input
            type="text"
            className={cx(styles.input, {
              [styles.noAuth]: !isAuthenticated && !isWhite,
              [styles.error]: formik.touched.name && formik.errors.name,
            })}
            placeholder="first name"
            autoComplete="off"
            name="name"
            {...formik.getFieldProps("name")}
          />
          <span className={styles.errorMessage}>
            {formik.touched.name && formik.errors.name}
          </span>
        </div>
        <div className={styles.inputWrapper}>
          <input
            type="text"
            className={cx(styles.input, {
              [styles.noAuth]: !isAuthenticated && !isWhite,
              [styles.error]: formik.touched.email && formik.errors.email,
            })}
            placeholder="email address"
            name="email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
          />
          <span className={styles.errorMessage}>
            {formik.touched.email && formik.errors.email}
          </span>
        </div>

        <button
          type="submit"
          className={cx(styles.submit, {
            [styles.noAuth]: !isAuthenticated && !isWhite,
          })}
        >
          Subscribe
        </button>
      </form>
      <div className={styles.terms}>
        <span
          className={cx(styles.text, {
            [styles.noAuth]: !isAuthenticated && !isWhite,
          })}
        >
          By subscribing I agree to receive emails from Kaylo <br /> and accept
          the{" "}
          <a
            href="/terms-and-conditions"
            target="_blank"
            className={styles.link}
          >
            Terms & Conditions
          </a>{" "}
          &{" "}
          <a href="/privacy-policy" target="_blank" className={styles.link}>
            Privacy Policy
          </a>
        </span>
        <IoClose
          className={cx(styles.closeDesktop, {
            [styles.noAuth]: !isAuthenticated && !isWhite,
          })}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default SubscribeBanner;
