import React from "react";
import styles from "@app/features/payment/pages/CheckoutRegisterPage.module.scss";
import Summary from "@app/features/payment/components/Summary";

import SignInForm from "@app/features/auth/screens/SignInPage/components/SignInForm";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

const CheckoutSignInPage = () => {
  return (
    <div className={styles.wrapper}>
      <Summary className={styles.left} />
      <div className={styles.right}>
        <SignInForm
          pathOnSuccess={PaymentPathEnum.CHECKOUT_NEW_PAYMENT}
          pathToRegister={PaymentPathEnum.CHECKOUT_REGISTER}
          title="Check out"
          backArrow={false}
          redirect="checkout"
          forgetPasswordLink={PaymentPathEnum.CHECKOUT_REQUEST_PASSWORD}
        />
      </div>
    </div>
  );
};

export default CheckoutSignInPage;
