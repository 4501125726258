/* eslint-disable @typescript-eslint/camelcase */
import React from "react";

import styles from "./CreateAccountScreen.module.scss";

import CreateAccountLayout from "./components/CreateAccountLayout";

import CreateAccountForm from "@app/features/auth/screens/CreateAccount/components/CreateAccountForm";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";

const CreateAccount = () => {
  return (
    <div className={styles.wrapper}>
      <CreateAccountLayout>
        <CreateAccountForm
          pathToSignIn={AuthPathsEnum.SIGN_IN}
          pathOnSuccess={AuthPathsEnum.CONFIRM_ACCOUNT}
          title="Create an account"
          backArrow
          redirect="dashboard"
          verifyEmail
        />
      </CreateAccountLayout>
    </div>
  );
};

export default CreateAccount;
