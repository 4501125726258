export enum PaymentPathEnum {
  CHECKOUT = "/checkout",
  CHECKOUT_SIGN_IN = "/checkout/signin",
  CHECKOUT_REGISTER = "/checkout/register",
  CHECKOUT_PAYMENT = "/checkout/payment",
  CHECKOUT_ADD_CARD = "/checkout/add",
  CHECKOUT_ORDER_CONFIRMATION = "/checkout/confirmation",
  CHECKOUT_NEW_PAYMENT = "/checkout/newpayment",
  CHECKOUT_REQUEST_PASSWORD = "/checkout/request-reset-password",
  CHECKOUT_RESET_INFO = "/checkout/reset-info",
  CHECKOUT_ERROR = "/checkout/error",
}
