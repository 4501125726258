import React, { useEffect } from "react";

import Loading from "@app/components/atoms/Loading/Loading";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import WorkshopAboutContent from "@app/features/workshops/components/WorkshopAboutContent";
import WorkshopSessionsContent from "@app/features/workshops/components/WorkshopSessionsContent";
import WorkshopSessionsList from "@app/features/workshops/components/WorkshopSessionsList";
import WorkshopTabs from "@app/features/workshops/components/WorkshopTabs";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import { WorkshopAboutInterface } from "@app/features/workshops/types/about.interface";
import { WorkshopSessionsInterface } from "@app/features/workshops/types/sessions.interface";
import { useRequest } from "@app/hooks/useRequest";

import styles from "./WorkshopSessions.module.scss";

interface WorkshopSessionsTypes {
  sessionId: string;
  showSessions: boolean;
  aboutData: WorkshopAboutInterface;
  aboutLoading: boolean;
  sessionsData: WorkshopSessionsInterface[];
  price: number;
  booked: boolean;
}

const WorkshopSessions: React.FC<WorkshopSessionsTypes> = ({
  sessionId,
  showSessions,
  aboutData,
  aboutLoading,
  sessionsData,
  price,
  booked,
}) => {
  return (
    <Section theme={ThemeEnum.KHAKI}>
      <Loading isLoading={aboutLoading}>
        <ContentWrapper>
          <div className={styles.wrapper}>
            <WorkshopTabs
              tab1={<WorkshopSessionsContent sessionsData={sessionsData} showSessions={showSessions} />}
              tab2={
                <WorkshopAboutContent
                  aboutData={aboutData}
                  sessionId={sessionId}
                />
              }
            />
            <WorkshopSessionsList
              sessionsData={sessionsData}
              showSessions={showSessions}
              price={price}
              booked={booked}
            />
          </div>
        </ContentWrapper>
      </Loading>
    </Section>
  );
};

export default React.memo(WorkshopSessions);
