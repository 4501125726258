/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from "react";

import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/redux/root-reducer";
import * as Yup from "yup";
import { passwordRegex } from "@app/features/auth/constants/auth.regex";
import { CreateUserProps } from "@app/types/api.types";
import { createSeeker } from "@app/features/auth/redux/auth.slice";
import Header from "@app/features/auth/screens/CreateAccount/components/Header";
import cx from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { Col, Container, Row } from "react-grid-system";
import { BsQuestionCircle } from "react-icons/bs";

import { IconArrowLeft } from "@app/components/atoms/Icon/Icon";
import { ENV } from "@app/constants/config";
import Checkbox from "@app/features/auth/components/FormControls/Checkbox";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import signUpWithSocials from "@app/features/auth/helpers/socials.helpers";
import styles from "@app/features/auth/screens/CreateAccount/CreateAccountScreen.module.scss";
import AlreadyRegistered from "@app/features/auth/screens/CreateAccount/components/AlreadyRegistered";
import Socials from "@app/features/auth/screens/CreateAccount/components/Socials";
import SubmitButton from "@app/features/auth/screens/CreateAccount/components/SubmitButton";
import TermsAndConditions from "@app/features/auth/screens/CreateAccount/components/TermsAndConditions";
import Title from "@app/features/auth/screens/CreateAccount/components/Title";
import { basketItemsLisWithoutPromoSelector } from "@app/features/basket/redux/BasketSelectors";
import {
  bulkAddToCart,
  clearCart,
} from "@app/features/basket/redux/basket.slice";
import { discountCodeSelector } from "@app/features/basket/redux/discount.selectors";
import { sendDiscountCode } from "@app/features/basket/redux/discount.slice";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

interface CreateAccountFormProps {
  pathToSignIn: AuthPathsEnum | PaymentPathEnum;
  pathOnSuccess: AuthPathsEnum | PaymentPathEnum;
  title: string;
  backArrow?: boolean;
  redirect: "checkout" | "dashboard";
  verifyEmail: boolean;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  pathToSignIn,
  pathOnSuccess,
  title = "",
  backArrow = true,
  redirect,
  verifyEmail,
}) => {
  const history = useHistory();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const lineItems = useSelector(basketItemsLisWithoutPromoSelector);
  const discountCode = useSelector(discountCodeSelector);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    email_confirmation: "",
    password: "",
    checkedGdpr: true,
    receive_marketing_news: false,
    checkedTc: false,
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .trim("There seems to be a space in your email, please remove it")
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, "Password doesn't match requirements"),
    email_confirmation: Yup.string()
      .oneOf([Yup.ref("email"), undefined], "Does not match with Email")
      .required("Email confirmation is required"),
    checkedTc: Yup.boolean()
      .required("Terms and conditions must be accepted")
      .oneOf([true], "Terms and conditions must be accepted"),
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (lineItems.length > 0) {
        setIsLoading(true);
        Promise.resolve(
          dispatch(
            bulkAddToCart({
              products: lineItems,
              discountCode,
            })
          )
        ).finally(() => history.push(pathOnSuccess));
      } else {
        history.push(pathOnSuccess);
      }
    }
  }, [
    discountCode,
    dispatch,
    history,
    isAuthenticated,
    lineItems,
    pathOnSuccess,
  ]);

  const onSubmit = (
    formValues: CreateUserProps,
    actions: FormikHelpers<CreateUserProps>
  ) => {
    setIsLoading(prevState => !prevState);
    dispatch(
      createSeeker({ ...formValues, verifyEmail }, errors => {
        if (errors) {
          actions.setErrors({ ...errors });
        }
      })
    );
    setIsLoading(prevState => !prevState);
  };

  return (
    <>
      {backArrow && <Header />}
      <Container
        fluid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          className={cx(styles.header, {
            [styles.paddingTop]: backArrow,
          })}
        >
          {backArrow && (
            <IconArrowLeft
              className={styles.back}
              onClick={() => history.go(-1)}
            />
          )}
          <Title>{title}</Title>
        </div>
        {ENV.DISPLAY_SOCIAL_LOGIN && (
          <Row justify="between" nogutter className={styles.row}>
            <Col xs={12} md={6}>
              <div className={styles.first}>
                <Socials
                  type="google"
                  label="Sign up with Google"
                  onClick={() => {
                    signUpWithSocials("google", redirect);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className={styles.second}>
                <Socials
                  type="facebook"
                  label="Sign up with Facebook"
                  onClick={() => {
                    signUpWithSocials("facebook", redirect);
                  }}
                />
              </div>
            </Col>
          </Row>
        )}

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form>
              <Row nogutter className={styles.row} justify="between">
                <Col xs={12} md={6}>
                  <FormInput
                    type="input"
                    name="firstName"
                    label="First Name"
                    className={styles.first}
                    errors={
                      formik.touched.firstName ? formik.errors.firstName : ""
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    type="input"
                    name="lastName"
                    label="Last Name"
                    className={styles.second}
                    errors={
                      formik.touched.lastName ? formik.errors.lastName : ""
                    }
                  />
                </Col>
              </Row>
              <Row nogutter className={styles.row}>
                <Col>
                  <FormInput
                    type="input"
                    name="email"
                    label="Email"
                    errors={formik.touched.email ? formik.errors.email : ""}
                  />
                </Col>
              </Row>
              <Row nogutter className={styles.row}>
                <Col>
                  <FormInput
                    type="input"
                    name="email_confirmation"
                    label="Confirm Email"
                    onPaste={e => e.preventDefault()}
                    errors={
                      formik.touched.email_confirmation
                        ? formik.errors.email_confirmation
                        : ""
                    }
                  />
                </Col>
              </Row>
              <Row nogutter className={styles.row}>
                <Col>
                  <FormInput
                    type="password"
                    name="password"
                    label="Password"
                    isPassword
                    secondLabel={<BsQuestionCircle className={styles.label} />}
                    errors={
                      formik.touched.password ? formik.errors.password : ""
                    }
                  />
                </Col>
              </Row>

              <Row className={styles.row}>
                <Col>
                  <Checkbox
                    label="Sign up for newsletter"
                    name="receive_marketing_news"
                    type="checkbox"
                  />
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col>
                  <Checkbox
                    label={<TermsAndConditions />}
                    name="checkedTc"
                    type="checkbox"
                    errors={
                      formik.touched.checkedTc ? formik.errors.checkedTc : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubmitButton label="Create account" isDisabled={isLoading} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

        <AlreadyRegistered
          text="Already registered?"
          linkText="Sign In"
          link={pathToSignIn}
        />
      </Container>
    </>
  );
};

export default CreateAccountForm;
