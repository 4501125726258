import React, { memo, useState } from "react";

import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import Accordian, {
  AccordianItem,
} from "@app/components/atoms/Accordian/Accordian";
import Image from "@app/components/atoms/Image/Image";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ModalityDef } from "@app/features/experiences/experiences";
import { getImageUrl } from "@app/helpers/image.helpers";

import styles from "./ExperienceModalities.module.scss";

export interface ExperienceModalitiesProps {
  modalities: ModalityDef[];
  title: string;
}

const ExperienceModalities = memo(
  ({ modalities, title }: ExperienceModalitiesProps) => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const [showMore, setShowMore] = useState<boolean>(false);
    const [activeModality, setActiveModality] = useState<ModalityDef>(
      modalities[0]
    );

    const renderContent = (modality: ModalityDef) => {
      return (
        <div>
          {(modality.description || modality.shortDescription) && (
            <>
              <Image
                url={getImageUrl(modality.image, IMAGE_TYPES.MODALITY)}
                className={styles.image}
              />
              <Title level={2} className={styles.tagLine}>
                {modality.quote}
              </Title>
              <Paragraph level={2}>{modality.description}</Paragraph>
            </>
          )}
        </div>
      );
    };

    const accordionMapper = (): AccordianItem[] => {
      return modalities.map(modality => ({
        id: modality.id,
        title: (
          <Title level={3} className={styles.tabTitle}>
            {modality.name}
          </Title>
        ),
        content: renderContent(modality),
      }));
    };

    const handleTabClick = (modality: ModalityDef) => {
      setActiveModality(modality);
      setShowMore(false);
    };

    return (
      <Section theme={ThemeEnum.KHAKI}>
        <ContentWrapper className={styles.container}>
          <Title level={1} className={styles.title}>
            {title}
          </Title>
          {matches.tabletLandscape ? (
            <div className={styles.desktopContainer}>
              <Row>
                <Col lg={4} xl={3}>
                  {modalities.map(modality => (
                    <Title
                      level={3}
                      className={styles.desktopTabTitle}
                      isGold={modality.id === activeModality.id}
                      onClick={() => handleTabClick(modality)}
                      key={modality.id}
                    >
                      {modality.name}
                    </Title>
                  ))}
                </Col>
                <Col lg={8} xl={9}>
                  {renderContent(activeModality)}
                </Col>
              </Row>
            </div>
          ) : (
            <Accordian
              items={accordionMapper()}
              defaultActiveKey={modalities[0].id}
            />
          )}
        </ContentWrapper>
      </Section>
    );
  }
);

export default ExperienceModalities;
