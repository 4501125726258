/* eslint-disable @typescript-eslint/camelcase */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import omit from "lodash/omit";

import { FiltersDef } from "@app/components/atoms/SearchFilters/SearchFilters";
import { ArticleDef } from "@app/features/articles/articles";
import { ClassesDef } from "@app/features/classes/classes";
import { ExperienceDef } from "@app/features/experiences/experiences";
import { PractitionerDef } from "@app/features/practitioners/practitioners";
import { PaginationDef } from "@app/types/pagination.types";

import { searchApi } from "../api/search.api";
import { SearchFiltersDef } from "../types/search.types";

interface PractitionersState {
  results: Record<
    string,
    PractitionerDef[] | ClassesDef[] | ExperienceDef[] | ArticleDef[]
  >;
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
  filters: FiltersDef | null;
  pagination: PaginationDef;
}

const initialState: PractitionersState = {
  results: {},
  loading: false,
  loadingMore: false,
  error: false,
  filters: null,
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 12,
    total: 1,
  },
};

export const searchContent = createAsyncThunk(
  "search/searchContent",
  async (filter: SearchFiltersDef, { rejectWithValue }) => {
    try {
      const response = await searchApi.searchContent(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const searchAndAppend = createAsyncThunk(
  "search/searchAndAppend",
  async (filter: SearchFiltersDef, { rejectWithValue }) => {
    try {
      const response = await searchApi.searchContent(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const getSearchFilters = createAsyncThunk(
  "search/getSearchFilters",
  async (_, { rejectWithValue }) => {
    try {
      const response = await searchApi.getSearchFilters();
      return response.data.filterOptions;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(searchContent.fulfilled, (state, action) => {
      state.loading = false;
      state.results[JSON.stringify(action.meta.arg)] = action.payload;
      state.pagination = action.payload.meta;
      state.error = false;
    });
    builder.addCase(searchContent.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(searchAndAppend.pending, state => {
      state.loadingMore = true;
      state.error = false;
    });
    builder.addCase(searchAndAppend.fulfilled, (state, action) => {
      const filters = JSON.stringify(omit(action.meta.arg, "page"));
      state.results[filters] = [
        ...state.results[filters],
        ...action.payload.data,
      ];
      state.loadingMore = false;
      state.pagination = action.payload.meta;
    });
    builder.addCase(getSearchFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.filters = action.payload;
      state.error = false;
    });
    builder.addCase(getSearchFilters.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getSearchFilters.rejected, state => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default searchSlice.reducer;
