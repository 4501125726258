import {api} from "@app/api/api";
import {BasketEndpointsEnum} from "@app/features/basket/constants/basket.endpoints";

import {
  PaymentInterface,
  ZeroPaymentInterface,
} from "@app/features/basket/Types/payment.types";
import {LineItem} from "@app/features/basket/Types/backend-basket.types";
import {ClassesDef} from "@app/features/classes/classes";
import {BulkInterface} from "@app/features/basket/Types/basket.types";

const getCart = () => {
  return api.get(BasketEndpointsEnum.CART);
};

const addProductToCart = (product: ClassesDef | LineItem) => {
  return api.post(BasketEndpointsEnum.PRODUCT, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    product_id: product.id,
    // eslint-disable-next-line @typescript-eslint/camelcase,@typescript-eslint/ban-ts-ignore
    // @ts-ignore
    slot_id: product.slots[0].id,
  });
};

const bulkAddProducts = async (options: BulkInterface) => {
  const {products, discountCode} = options;
  const filteredProducts = products.filter(item => item.type === 'product');
  const workshops = products.filter(item => item.type === 'kaylo-workshop');

  const classes = filteredProducts.map(product => product?.payload?.netzp_event.id);
  const workshopIds = workshops.map(product => product?.payload?.kayloWorkshopId);

  return api.post(BasketEndpointsEnum.BULK, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    slot_id: classes,

    workshop_id: workshopIds,
    // eslint-disable-next-line @typescript-eslint/camelcase
    ...(!!discountCode && {discount_code: discountCode}),
  });
};

const removeProductFromCart = (id: string) => {
  return api.delete(BasketEndpointsEnum.DELETE_PRODUCT, {
    params: {
      id: [id],
    },
  });
};

const clearCart = () => {
  return api.delete(BasketEndpointsEnum.CART);
};

const placeOrder = (data: PaymentInterface | ZeroPaymentInterface) => {
  return api.post(BasketEndpointsEnum.ORDER, data);
};

const getCountries = () => {
  return api.get(BasketEndpointsEnum.COUNTRIES);
};
const getPaymentMethods = () => {
  return api.get(BasketEndpointsEnum.PAYMENT_METHOD);
};

const sendDiscountCode = (discountCode: string) => {
  return api.post(BasketEndpointsEnum.CHECKOUT_DISCOUNT, {
    discountCode,
  });
};
const removeDiscountCode = (discountCodeId: string) => {
  return api.delete(BasketEndpointsEnum.CHECKOUT_DISCOUNT, {
    params: {
      discountCodeId,
    },
  });
};

export const basketApi = {
  getCart,
  addProductToCart,
  clearCart,
  removeProductFromCart,
  placeOrder,
  getCountries,
  getPaymentMethods,
  sendDiscountCode,
  removeDiscountCode,
  bulkAddProducts,
};
