import React, { useEffect } from "react";

import { useRouteMatch } from "react-router-dom";

import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import SessionsByFilter from "@app/components/renderings/SessionsByFilter/SessionsByFilter";
import HeroBanner from "@app/features/workshops/components/HeroBanner";
import WorkshopSessions from "@app/features/workshops/components/WorkshopSessions";
import WorkshopTestimonials from "@app/features/workshops/components/WorkshopTestimonials";
import { WorkshopEndpoints } from "@app/features/workshops/constants/workshop.endpoints";
import { getExperiences } from "@app/features/workshops/helpers/experiences.helpers";
import { WorkshopAboutInterface } from "@app/features/workshops/types/about.interface";
import { WorkshopSessionsInterface } from "@app/features/workshops/types/sessions.interface";
import { WorkshopSummaryInterface } from "@app/features/workshops/types/summary.interface";
import { useRequest } from "@app/hooks/useRequest";

interface MatchParams {
  id: string;
  slug: string;
}

const WorkshopDetailsScreen = () => {
  const match = useRouteMatch<MatchParams>();
  const { id } = match?.params;

  const {
    data: heroResponse,
    loading: heroLoading,
    fetchData: fetchHeroData,
    error: heroError,
  } = useRequest(`${WorkshopEndpoints.SUMMARY}/${id}`);
  const {
    data: aboutRequest,
    loading: aboutLoading,
    fetchData: fetchAboutData,
  } = useRequest(`${WorkshopEndpoints.SUMMARY}/${id}/about`);
  const { data: sessions, fetchData: fetchDataSessions } = useRequest(
    `${WorkshopEndpoints.SUMMARY}/${id}/sessions`
  );

  const heroData: WorkshopSummaryInterface = heroResponse as never;
  const aboutData: WorkshopAboutInterface = aboutRequest as never;
  const sessionsData: WorkshopSessionsInterface[] = sessions as never;

  useEffect(() => {
    fetchHeroData();
    fetchAboutData();
    fetchDataSessions();
  }, [fetchAboutData, fetchHeroData, fetchDataSessions]);

  if (heroError) return null;

  return (
    <DefaultLayout>
      <HeroBanner heroData={heroData} loading={heroLoading} />
      <WorkshopSessions
        sessionId={id}
        showSessions={heroData.showSessions}
        aboutData={aboutData}
        aboutLoading={aboutLoading}
        sessionsData={sessionsData}
        price={heroData.price}
        booked={heroData.booked}
      />
      <WorkshopTestimonials sessionId={id} />
      {aboutData?.experiences?.length > 0 && (
        <SessionsByFilter
          title="Similar experiences to explore"
          filter={{ experiences: getExperiences(aboutData.experiences) }}
          subTitle=""
        />
      )}
    </DefaultLayout>
  );
};

export default WorkshopDetailsScreen;
