import React from "react";
import styles from "./TestimonialsCard.module.scss";
import { ImQuotesLeft } from "react-icons/im";
import { WorkshopTestimonialsInterface } from "@app/features/workshops/types/testimonials.interface";

interface TestimonialsCardTypes
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  card: WorkshopTestimonialsInterface;
}

const TestimonialsCard: React.FC<TestimonialsCardTypes> = ({
  card,
  ...rest
}) => {
  return (
    <div {...rest} className={styles.container}>
      <div className={styles.top}>
        <div className={styles.left}>
          <ImQuotesLeft className={styles.icon} />
        </div>
      </div>
      <div className={styles.review}> {card.content} </div>
      <div className={styles.bottom}>
        <img src={card.image} alt={card.image} className={styles.image} />
        <span className={styles.name}>{card.fullName}</span>
      </div>
    </div>
  );
};

export default TestimonialsCard;
