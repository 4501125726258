import { authApi } from "@app/features/auth/api/auth.api";

const signUpWithSocials = async (
  social: "google" | "facebook",
  pathToRedirect: "checkout" | "dashboard"
) => {
  const response = await authApi.getSocialsRedirect(social, pathToRedirect);
  if (response) {
    window.location.href = response;
  }
};
export default signUpWithSocials;
