import React from "react";
import styles from "@app/features/payment/pages/CheckoutRegisterPage.module.scss";
import Summary from "@app/features/payment/components/Summary";
import CustomerInformation from "@app/features/payment/components/CustomerInformation";
import { useSelector } from "react-redux";
import { userSelector } from "@app/features/auth/redux/auth.selectors";
import CheckoutTitle from "@app/features/payment/components/CheckoutTitle";
import PaymentMethod from "@app/features/payment/components/payment-method/PaymentMethod";
import PromoCode from "@app/features/basket/components/PromoCode";
import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";

const CheckoutPaymentPage = () => {
  const user = useSelector(userSelector);
  const handlePlaceOrder = () => {
    console.log("place order");
  };
  return (
    <div className={styles.wrapper}>
      <Summary className={styles.left} />
      <div className={styles.right}>
        <CheckoutTitle title="Check out" />
        <CustomerInformation user={user} />
        <PaymentMethod />
        <PromoCode />
        <Button
          fullWidth
          label="Place order"
          type="button"
          size={ButtonSizeEnum.LARGE}
          className={styles.checkout}
          onClick={handlePlaceOrder}
        />
      </div>
    </div>
  );
};

export default CheckoutPaymentPage;
