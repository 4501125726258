import React from "react";

import { Form, Formik, FormikHelpers } from "formik";
import { Col, Container, Row } from "react-grid-system";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { authApi } from "@app/features/auth/api/auth.api";
import FormInput from "@app/features/auth/components/FormControls/FormInput";
import { AuthPathsEnum } from "@app/features/auth/constants/auth.paths";
import SubmitButton from "@app/features/auth/screens/CreateAccount/components/SubmitButton";
import FormHeader from "@app/features/auth/screens/ResetPasswordPage/components/FormHeader";
import { RequestResetPasswordDataDef } from "@app/features/auth/types/auth-login.types";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

import styles from "./RequestResetPasswordComponent.module.scss";

interface RequestResetPasswordComponentProps {
  pathToInfo: AuthPathsEnum | PaymentPathEnum;
}

const RequestResetPasswordComponent: React.FC<RequestResetPasswordComponentProps> = ({
  pathToInfo,
}) => {
  const history = useHistory();
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const handleFormSubmit = async (
    formValues: RequestResetPasswordDataDef,
    actions: FormikHelpers<RequestResetPasswordDataDef>
  ) => {
    const location = {
      pathname: pathToInfo,
      state: {
        ...formValues,
      },
    };
    try {
      await authApi.requestResetPassword(formValues);
      history.push(location);
    } catch (e) {
      actions.setErrors({
        email: "Something went wrong, try again later",
      });
    }
  };
  return (
    <Container
      fluid
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <FormHeader
        title="Recover password"
        backArrow
        onArrowClick={() => {
          history.go(-1);
        }}
      />
      <p className={styles.subtitle}>
        Enter the email address associated with your Kaylo account,
        <br />
        and we&apos;ll email you a link to reset your password.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Row nogutter className={styles.row}>
            <Col>
              <FormInput
                name="email"
                label="Confirm email"
                type="input"
                placeholder="Type here"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SubmitButton label="Reset my password" />
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};

export default RequestResetPasswordComponent;
