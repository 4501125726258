import React from "react";

import { Link, useHistory } from "react-router-dom";

import { AuthPathsEnum } from "../../../constants/auth.paths";
import styles from "./AlreadyRegistered.module.scss";
import { PaymentPathEnum } from "@app/features/payment/constants/payment.endpoints";

interface AlreadyRegistered
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  text: string;
  linkText: string;
  link: AuthPathsEnum | PaymentPathEnum;
}

const AlreadyRegistered: React.FC<AlreadyRegistered> = ({
  text,
  linkText,
  link,
}) => {
  return (
    <p className={styles.paragraph}>
      {text}{" "}
      <Link to={link} className={styles.link}>
        {linkText}
      </Link>
    </p>
  );
};

export default AlreadyRegistered;
