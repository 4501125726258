import React, { useEffect } from "react";

import { Col, Container, Row } from "react-grid-system";
import { useMedia } from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import logo from "@app/assets/images/kaylo-logo.png";
import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { userSelector } from "@app/features/auth/redux/auth.selectors";
import {
  basketItemsListSelector,
  basketItemsLisWithoutPromoSelector,
} from "@app/features/basket/redux/BasketSelectors";
import {
  clearBasket,
  clearCart,
} from "@app/features/basket/redux/basket.slice";
import { clearDiscountCode } from "@app/features/basket/redux/discount.slice";
import BackButton from "@app/features/payment/components/BackButton";
import CheckoutConfirmationItem from "@app/features/payment/components/CheckoutConfirmationItem";
import { SearchPathsEnum } from "@app/features/search/constants/search.paths";

import styles from "./CheckoutOrderConfirmation.module.scss";

const onClickHandler = (source: string) => {
  window.open("https://pre-prod-api.kaylo.net/api/calendar/{source}", "_new");
};
const CheckoutOrderConfirmation = () => {
  const user = useSelector(userSelector);
  const basketItems = useSelector(basketItemsListSelector);
  const lineItems = useSelector(basketItemsLisWithoutPromoSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const { mobile, mobileLandscape } = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });
  const handleClassesClick = () => {
    history.push(SearchPathsEnum.MY_BOOKINGS);
  };

  const onArrowClick = () => {
    history.push("/");
  };
  useEffect(() => {
    dispatch(clearDiscountCode());
    dispatch(clearCart());
    return function () {
      dispatch(clearBasket());
    };
  }, [dispatch]);

  return (
    <div className={styles.wrapperGreen}>
      <div className={styles.left}>
        <BackButton title="Explore world of Kaylo" onClick={onArrowClick} />
        {(mobile || mobileLandscape) && (
          <img src={logo} alt="logo" className={styles.logo} />
        )}
      </div>
      <div className={styles.right}>
        <p className={styles.title}>Order Confirmation</p>
        <p className={styles.subtitle}>Thank you for booking your place</p>
        {user && (
          <p className={styles.confirmation}>
            An email confirmation has been sent to {user.email}
          </p>
        )}
        <Container
          fluid
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Row>
            <Col>
              <div className={styles.list}>
                {basketItems.length > 0 ? (
                  lineItems.map(item => (
                    <CheckoutConfirmationItem card={item} />
                  ))
                ) : (
                  <p className={styles.empty}>No items</p>
                )}
              </div>
            </Col>
          </Row>
          <Row justify="between">
            {/* <Col md={6} sm={12}>
              <button
                className={styles.calendar}
                onClick={event => onClickHandler("asdsa")}
              >
                Add to calendar
              </button>
            </Col> */}
            <Col md={6} sm={12}>
              <Button
                fullWidth
                label="View my classes"
                className={styles.button}
                size={ButtonSizeEnum.LARGE}
                onClick={handleClassesClick}
              />
            </Col>
          </Row>
        </Container>
        <p className={styles.cancel}>
          You can cancel the session 24 hours in advance, or 72 hours for
          workshops and private sessions, in My bookings.
        </p>
        <p className={styles.questions}>
          If you have questions, please contact support on{" "}
          <a href="tel:support@kaylolife.com" className={styles.link}>
            +44 330 808 6792
          </a>{" "}
          <br /> or email{" "}
          <a href="mailto:+443308086792" className={styles.link}>
            support@kaylolife.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default CheckoutOrderConfirmation;

function iCalLink(item: any) {
  throw new Error("Function not implemented.");
}
