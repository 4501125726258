import { useCallback, useState } from "react";

export const useMessageHook = (time = 4000) => {
  const [isMessageDisplayed, setIsMessageIsDisplayed] = useState(false);

  const displayMessage = useCallback(
    async (state?: boolean) => {
      if (state === false) {
        return setIsMessageIsDisplayed(false);
      }
      setIsMessageIsDisplayed(true);
      setTimeout(() => {
        setIsMessageIsDisplayed(false);
      }, time);
    },
    [time]
  );

  return { isMessageDisplayed, displayMessage };
};
